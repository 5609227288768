import { configureStore } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk'
import { persistReducer } from 'redux-persist'
import reducer from './reducer';

const persistConfig = {
    key: 'root',
    storage
};

const persistedReducer = persistReducer(persistConfig, reducer);

export default configureStore({
     reducer:persistedReducer,
    //  middleware: [thunk],
     middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
     devTools: process.env.NODE_ENV !== 'production',
})

// import { configureStore, combineReducers } from '@reduxjs/toolkit';
// import storage from 'redux-persist/lib/storage';
// import thunk from 'redux-thunk';
// import { persistReducer } from 'redux-persist';
// import reducer from './reducer'; 
// import excelReducer from '../Redux/Reducer'

// console.log(storage,"storagestorage");


// const persistConfig = {
//     key: 'root',
//     storage,
// };

// const rootReducer = combineReducers({
//     root: reducer,        
//     excelReducer,  
// });

// const persistedReducer = persistReducer(persistConfig, rootReducer);

// export default configureStore({
//     reducer: persistedReducer,
//     middleware: [thunk],
//     devTools: process.env.NODE_ENV !== 'production',
// });