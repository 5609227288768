import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Fab } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

const useStyles = makeStyles(() => ({
  fab: {
    position: 'fixed',
    // bottom: '105px',
    bottom: '20px',
    right: '3%',
    zIndex: '999',
    cursor: 'pointer',
    // background: "#EFF7FB"
  },
}));
export default function ScrollToTop() {
  const classes = useStyles();

  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.pageYOffset > 500) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
  }, []);

  return (
    <>
      {isVisible && (
        <Fab size="medium" aria-label="back-to-top" className={classes.fab} 
        onClick={scrollToTop}>
          <ArrowUpwardIcon color="primary" size="medium" />
        </Fab>
      )}
    </>
  );
}
