
import React, { useState, useEffect } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { TextField, Button, Chip, Box, Paper, Typography, InputAdornment,Select, Grid, FormControl, IconButton } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { LoadingButton } from '@mui/lab';
import PropTypes from 'prop-types';
import InputLabel from '@mui/material/InputLabel';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import Autocomplete from '@mui/material/Autocomplete';
// import Select from 'react-select';
import { Form, Formik } from 'formik';
import CloseIcon from '@mui/icons-material/Close';
import * as Yup from 'yup';
import DOMPurify from 'dompurify';
import SnackBar from '../../../components/snackbar/snackbar';
import adminServices from '../../../services/adminServices';
import DialogModal from '../../../components/modal/DialogModal';

const SubModuleAssessmentUpdate = ({ open,onClose,submoduleId,alldetails,comingfrom }) => {   
  console.log(comingfrom,'comingfrom111',submoduleId,alldetails,);
  
  const [loading, setLoading] = useState(false);
  const [questionList, setQuestionList] = useState([]);
  const [hashtag, setHashtag] = useState([]);
  const [category, setCategory] = useState([]);
  const [tags, setTags] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedQuestions, setSelectedQuestions] = useState([]);  
  const [isSearched, setIsSearched] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarTitle, setSnackbarTitle] = useState('');
  const [level, setLevel] = useState(alldetails&&alldetails?.level);
  const [nodata, setNodata] = useState(true);
  const [time, setTime] = useState({
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
console.log(selectedQuestions,'selectedQuestionsselectedQuestions');


  React.useMemo(() => {
    const totalSeconds = alldetails.time_in_mins;
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    setTime({ hours, minutes, seconds });
    setLevel(alldetails.level);
    setSelectedQuestions(alldetails.questionsDetails?alldetails.questionsDetails:[]);
  }, [alldetails])



  useEffect(async () => {
    await getLookUpDetails();
  }, []);

  useEffect(() => {
    setLoading(false)
  }, [open]);


  const handleAddTag = () => {
    if (searchTerm && !tags.includes(searchTerm)) {
      setTags([...tags, searchTerm]);
      getQuestionList([...tags, searchTerm]);
      setIsSearched(true);
    }
    setSearchTerm("");
  };
 
  // const handleRemoveTag = (index) => {
  //   const newTags = tags.filter((_, i) => i !== index);
  //   setTags(newTags);
  //   // filterQuestions(newTags);
  //   // getQuestionList(newTags);
  //   setQuestionList([])
  //   setIsSearched(false);
  // };


  const handleRemoveTag = (index) => {
    const newTags = tags.filter((_, i) => i !== index);
    setTags(newTags);
    // filterQuestions(newTags);
    // getQuestionList(newTags);
    console.log(searchTerm,"searchTermsearchTerm");
    console.log(newTags,"newTags");
    
    if(newTags?.length > 0){
      getQuestionList([...newTags]);
    }
    else{
      setQuestionList([])
    }

    setIsSearched(false);
  };
 
 

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };
 
  const handleAddQuestion = (question) => {
    console.log('inside add',question);
    
    if (!selectedQuestions?.some(q => q.id === question.id)) {
      setSelectedQuestions([...selectedQuestions, question]);
    }
  };
 
  const handleRemoveQuestion = (question) => {
    setSelectedQuestions(selectedQuestions?.filter(q => q.id !== question.id));
  };
 

  const getQuestionList = async (data) => {
    setNodata(false)
    const result = await adminServices.getQuestionData(data);
    console.log(result,"result");
    if (result.ok) {
      if(result.data?.length === 0){
        setNodata(true)
      }
      else{
        setQuestionList(result.data)
        setNodata(false)
      }

    }
  };


  const getLookUpDetails = async () => {
    const key = ['hashtag', 'course_category'];
    const result = await adminServices.getLookUpData(key);
    if (result.ok) {
      setHashtag(result.data.hashtag);
      setCategory(result.data.course_category);
    }
  };



  const handleUpDateAssessMent = async() => {
      setLoading(true);
    const filteredquestions = selectedQuestions.map((data) => data.id)
    const time1 = time
    const totalSeconds = (time1.hours * 3600) + (time1.minutes * 60) + time1.seconds;
    const details = {
      filteredquestions,
      level,
      totalSeconds,
    }
      
      try {
        const response = await adminServices.UpdateCourseAssessMentBasedonId(submoduleId,comingfrom,details);
     console.log(response,"responseresponse");
     if(response.ok){
      setSnackbarTitle('Assessment Updated successfully');
      setOpenSnackbar(true);
      CloseFunction()
     }
     
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    };


const CloseFunction = () => {
  setQuestionList([])
  setSelectedQuestions([])
  setTags([])
  onClose()
  setIsSearched(false);
  setTime({
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  setLevel('Easy')
}


  // Handle change for each select dropdown
  const handleChange = (event) => {
    const { name, value } = event.target;
    setTime((prevTime) => ({
      ...prevTime,
      [name]: value,
    }));
  };

  // Arrays for hours, minutes, and seconds
  const hoursArray = Array.from({ length: 24 }, (_, i) => i); // 0 to 23 hours
  const minutesArray = Array.from({ length: 60 }, (_, i) => i); // 0 to 59 minutes
  const secondsArray = Array.from({ length: 60 }, (_, i) => i); // 0 to 59 seconds

  return (
    <>
    <DialogModal open={open} handleClose={CloseFunction} title="Assessment Update Form">
    <Grid style={{ marginBottom: 1 }} container spacing={2}>
          {/* Hours */}
          <Grid item xs={2.5}>
            <FormControl fullWidth>
              <InputLabel>Hours</InputLabel>
              <Select
                name="hours"
                value={time.hours}
                onChange={handleChange}
                label="Hours"
              >
                {hoursArray.map((hour) => (
                  <MenuItem key={hour} value={hour}>
                    {hour}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* Minutes */}
          <Grid item xs={2.5}>
            <FormControl fullWidth>
              <InputLabel>Minutes</InputLabel>
              <Select
                name="minutes"
                value={time.minutes}
                onChange={handleChange}
                label="Minutes"
              >
                {minutesArray.map((minute) => (
                  <MenuItem key={minute} value={minute}>
                    {minute}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* Seconds */}
          <Grid item xs={2.5}>
            <FormControl fullWidth>
              <InputLabel>Seconds</InputLabel>
              <Select
                name="seconds"
                value={time.seconds}
                onChange={handleChange}
                label="Seconds"
              >
                {secondsArray.map((second) => (
                  <MenuItem key={second} value={second}>
                    {second}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={4}>
            <FormControl fullWidth>
              <InputLabel>Level</InputLabel>
              <Select
                name="level"
                value={level}
                onChange={(e) => setLevel(e.target.value)}
                label="Level"
              >
                <MenuItem value={'Easy'}>Easy</MenuItem>
                <MenuItem value={'Medium'}>Medium</MenuItem>
                <MenuItem value={'Complex'}>Complex</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
{selectedQuestions && selectedQuestions?.length > 0 && (
  <>
      <Typography
      variant="body2"
      sx={{ flexGrow: 1, fontSize: '0.875rem', textDecoration: 'underline' }}
    >
      Selected Questions
    </Typography>
  <Paper
    sx={{
      mb: 2,
      p: 1,
      bgcolor: "#e3faf4",
      // bgcolor: "#cfe0ff",
      width: '100%',
      maxHeight: selectedQuestions.length > 4 ? '150px' : 'auto',
      overflowY: 'auto',
      '&::-webkit-scrollbar': {
        width: '8px',
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: '#f1f1f1',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#c1c1c1',
        borderRadius: '10px',
      },
      '&::-webkit-scrollbar-thumb:hover': {
        backgroundColor: '#a1a1a1',
      },
      scrollbarWidth: 'thin',
      scrollbarColor: '#c1c1c1 #f1f1f1',
    }}
  >

    {selectedQuestions.map((question) => (     
      <Box
        key={question.id}
        sx={{ display: "flex", alignItems: "center", mb: 1 }}
      >
        {      console.log(selectedQuestions,'selectedQuestionsq11')
        } 
          <Typography
                      id='questionviewadd1'
                      variant="body2"
                      sx={{
                        flexGrow: 1,
                        fontSize: '0.75rem',
                      }}
                      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(question.question_text?question.question_text:question.questionText) }}
                    />
        <Button
          variant="outlined"
          color="error"
          onClick={() => handleRemoveQuestion(question)}
          sx={{
            fontSize: '0.75rem',
            minWidth: '24px',
            minHeight: '24px',
            padding: '2px',
            borderRadius: '12px',
            '& .MuiButton-label': { fontSize: '1rem' },
          }}
        >
          x
        </Button>
      </Box>
    ))}
  </Paper>
  </>
)}
 
 
 
      <Paper sx={{ mb: 2, p: 1, bgcolor: "#f7f7f7", width: '100%' }}>
        <Box sx={{ display: "flex", mb: 1 }}>
          <TextField
            variant="outlined"
            fullWidth
            placeholder="Search Questions..."
            value={searchTerm}
            onChange={handleSearchChange}
            sx={{
              bgcolor: "#f0f0f0",
              borderRadius: 1,
              height: 36,
              '& .MuiInputBase-input': {
                fontSize: 14,
                padding: "8px 12px",
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleAddTag}
                    sx={{ p: 0, color: "#1976d2" }}
                  >
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5, mb: 1 }}>
          {tags.map((tag, index) => (
            <Chip
              key={index}
              label={tag}
              onDelete={() => handleRemoveTag(index)}
              color="primary"
              size="small"
              sx={{ fontSize: 10 }}
            />
          ))}
        </Box>
 
        {isSearched && questionList?.length === 0 ? (
          <Typography variant="body2" sx={{ textAlign: 'center', color: 'text.secondary' }}>
             {nodata === false ?"loading...":"No records found"}
             </Typography>
        ) : (
 
          <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            maxHeight: questionList.length > 4 ? '140px' : 'auto',
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
              width: '8px',
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: '#f1f1f1',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#c1c1c1',
              borderRadius: '10px',
            },
            '&::-webkit-scrollbar-thumb:hover': {
              backgroundColor: '#a1a1a1',
            },
            scrollbarWidth: 'thin',
            scrollbarColor: '#c1c1c1 #f1f1f1',
          }}
        >
         {questionList?.length > 0 && questionList.map((question) => {
  const isSelected = selectedQuestions?.some((selected) => selected.question_text === question.question_text);
  return (
    <Box
      key={question.id}
      sx={{ display: "flex", alignItems: "center", mb: 1 }}
    >
      {/* <Typography
       id='questionview'
        variant="body2"
        sx={{
          flexGrow: 1,
          fontSize: '0.75rem',
          bgcolor: isSelected ? "#cfe0ff" : "transparent", 
          padding: '4px', 
          borderRadius: '4px', 
        }}
      >
        
        {question.question_text}
      </Typography> */}
      <Typography
  id='questionview'
  variant="body2"
  sx={{
    flexGrow: 1,
    fontSize: '0.75rem',
    bgcolor: isSelected ? "#cfe0ff" : "transparent",
    padding: '4px',
    borderRadius: '4px',
  }}
  dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(question.question_text) }}
/>
      <Button
       id='questionadd'
        variant="outlined"
        color="primary"
        onClick={() => handleAddQuestion(question)}
        sx={{
          fontSize: '0.75rem',
          minWidth: '24px',
          minHeight: '24px',
          padding: '2px',
          borderRadius: '12px',
          '& .MuiButton-label': { fontSize: '1rem' },
        }}
      >
        +
      </Button>
    </Box>
  );
})}

        </Box>
       
 
        )}
      </Paper>
 
 
<Box sx={{ display: "flex", justifyContent: "flex-start", mt: 2 ,marginLeft:1.4}}>
  {selectedQuestions &&


  <LoadingButton
                  type="submit"
                  onClick={handleUpDateAssessMent}
                  variant="contained"
                  color="primary"
                  fullWidth
                  loading={loading}
                >
                  Update
                </LoadingButton>
  } 
</Box>


    </DialogModal>
    <SnackBar open={openSnackbar} snackbarTitle={snackbarTitle} close={() => setOpenSnackbar(false)} />

</>
  );
}
export default SubModuleAssessmentUpdate;