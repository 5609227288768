import React, { useState, useEffect } from "react";
import {
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
    Radio,
    Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useLocation, useNavigate } from 'react-router-dom';
import SnackBar from '../../../components/snackbar/snackbar';
import DeleteAlert from '../../../components/modal/DeleteModal';
import adminServices from '../../../services/adminServices';
import Page from '../../../components/Page';
import PageHeader from '../../../components/PageHeader';
import CreateHearAboutUsModal from './SettingCreatePage'

const DataTable = ({ title, description, tableType, details, onGetAllDetails }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [entries, setEntries] = useState([]);
    const [snackbarTitle, setSnackbarTitle] = useState('');
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [deleteId, setDeleteId] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [loading, setLoading] = useState(false);
    const [currentEntry, setCurrentEntry] = useState(null); 
    const [editModuleOpen, setEditModuleOpen] = useState(false);
    const [moduleopen, setModuleopen] = useState(false);
    useEffect(() => {
        setEntries(details)
    }, [details])


    const handleEditOpen = (entry) => {
        setCurrentEntry(entry); 
        setEditModuleOpen(true); 
    };

    

    const confirmDelete = async () => {       
        const result = await adminServices.deleteDropDownValue(tableType,deleteId);
        if (result.ok) {
            setSnackbarTitle("Deleted successfully!");
            setOpenSnackbar(true);
            if (onGetAllDetails) {
                onGetAllDetails(); 
            }        }
        setDeleteOpen(false);
    };

    const handleCreatOpen = () => {
        setModuleopen(true);
    };

    const handleCreatClose = () => {
        setModuleopen(false);
        if (onGetAllDetails) {
            onGetAllDetails();
        }
    };
    const handleEditClose = () =>{
       setEditModuleOpen(false) 
       if (onGetAllDetails) {
        onGetAllDetails(); 
    }
    }

    const renderTableRows = () => {
        return entries?.map((entry) => (
            <TableRow key={entry.id}>
                {tableType === 'offer_announcement' && (
                    <>
                        <TableCell>{entry.offer_id}</TableCell>
                        <TableCell>{entry.region}</TableCell>
                        <TableCell>
                            <Radio checked={entry.is_active} color={entry.is_active ? 'success' : 'error'} />
                        </TableCell>
                        <TableCell>{entry.coupon_code}</TableCell>
                        <TableCell>{entry.description}</TableCell>
                        <TableCell>{entry.payment_link}</TableCell>
                        <TableCell>{entry.validity_date}</TableCell>
                        <TableCell>
                           <Button onClick={() =>handleEditOpen(entry)}>
                                <EditIcon />
                            </Button>
                            <Button onClick={() => { setDeleteId(entry.id); setDeleteOpen(true); }}>  <DeleteIcon /></Button>
                        </TableCell>
                    </>
                )}
                {tableType === 'hear_about_us' && (
                    <>
                        <TableCell>{entry.id}</TableCell>
                        <TableCell>{entry.value}</TableCell>
                        <TableCell>
                           <Button onClick={() =>handleEditOpen(entry)}>
                                <EditIcon />
                            </Button>
                            <Button onClick={() => { setDeleteId(entry.id); setDeleteOpen(true); }}>  <DeleteIcon /></Button>
                        </TableCell>
                    </>
                )}
                {tableType === 'user_greeting' && (
                    <>
                        <TableCell>{entry.id}</TableCell>
                        <TableCell>{entry.value}</TableCell>
                        <TableCell>{entry.scenario}</TableCell>
                        <TableCell>
                           <Button onClick={() =>handleEditOpen(entry)}>
                                <EditIcon />
                            </Button>
                            <Button onClick={() => { setDeleteId(entry.id); setDeleteOpen(true); }}>  <DeleteIcon /></Button>
                        </TableCell>
                    </>
                )}
                {tableType === 'career_goal' && (
                    <>
                        <TableCell>{entry.id}</TableCell>
                        <TableCell>{entry.value}</TableCell>
                        <TableCell>
                           <Button onClick={() =>handleEditOpen(entry)}>
                                <EditIcon />
                            </Button>
                            <Button onClick={() => { setDeleteId(entry.id); setDeleteOpen(true); }}>  <DeleteIcon /></Button>
                        </TableCell>
                    </>
                )}
                {tableType === 'job' && (
                    <>
                        <TableCell>{entry.id}</TableCell>
                        <TableCell>{entry.value}</TableCell>
                        <TableCell>
                           <Button onClick={() =>handleEditOpen(entry)}>
                                <EditIcon />
                            </Button>
                            <Button onClick={() => { setDeleteId(entry.id); setDeleteOpen(true); }}>  <DeleteIcon /></Button>
                        </TableCell>
                    </>
                )}
                {tableType === 'education' && (
                    <>
                        <TableCell>{entry.id}</TableCell>
                        <TableCell>{entry.value}</TableCell>
                        <TableCell>
                           <Button onClick={() =>handleEditOpen(entry)}>
                                <EditIcon />
                            </Button>
                            <Button onClick={() => { setDeleteId(entry.id); setDeleteOpen(true); }}>  <DeleteIcon /></Button>
                        </TableCell>
                    </>
                )}
                {tableType === 'work_experience' && (
                    <>
                        <TableCell>{entry.id}</TableCell>
                        <TableCell>{entry.value}</TableCell>
                        <TableCell>
                           <Button onClick={() =>handleEditOpen(entry)}>
                                <EditIcon />
                            </Button>
                            <Button onClick={() => { setDeleteId(entry.id); setDeleteOpen(true); }}>  <DeleteIcon /></Button>
                        </TableCell>
                    </>
                )}
                {tableType === 'current_role' && (
                    <>
                        <TableCell>{entry.id}</TableCell>
                        <TableCell>{entry.value}</TableCell>
                        <TableCell>
                           <Button onClick={() =>handleEditOpen(entry)}>
                                <EditIcon />
                            </Button>
                            <Button onClick={() => { setDeleteId(entry.id); setDeleteOpen(true); }}>  <DeleteIcon /></Button>
                        </TableCell>
                    </>
                )}
                {tableType === 'currency' && (
                    <>
                        <TableCell>{entry.id}</TableCell>
                        <TableCell>{entry.code}</TableCell>
                        <TableCell>
                            <Radio checked={entry.isActive} color={entry.isActive ? 'success' : 'error'} />
                        </TableCell>
                        <TableCell>{entry.description}</TableCell>
                        <TableCell>
                           <Button onClick={() =>handleEditOpen(entry)}>
                                <EditIcon />
                            </Button>
                            <Button onClick={() => { setDeleteId(entry.id); setDeleteOpen(true); }}>  <DeleteIcon /></Button>
                        </TableCell>
                    </>
                )}
                 {tableType === 'static_banner_message' && (
                    <>
                        <TableCell>{entry.id}</TableCell>
                        <TableCell>{entry.value}</TableCell>
                        <TableCell>
                           <Button onClick={() =>handleEditOpen(entry)}>
                                <EditIcon />
                            </Button>
                            <Button onClick={() => { setDeleteId(entry.id); setDeleteOpen(true); }}>  <DeleteIcon /></Button>
                        </TableCell>
                    </>
                )}
                 {tableType === 'offer_page' && (
                    <>
                        <TableCell>{entry.id}</TableCell>
                        <TableCell>{entry.title}</TableCell>
                        <TableCell>{entry.time?entry.time:'-'}</TableCell>
                        <TableCell>{entry.course?entry.selectedCourse:'-'}</TableCell>
                        <TableCell>{entry.description}</TableCell>
                        <TableCell>
                           <Button onClick={() =>handleEditOpen(entry)}>
                                <EditIcon />
                            </Button>
                            <Button onClick={() => { setDeleteId(entry.id); setDeleteOpen(true); }}>  <DeleteIcon /></Button>
                        </TableCell>
                    </>
                )}
            </TableRow>
        ));
    };
    return (
        <div>
            <Button style={{ marginLeft: '15px', height: '40px' }}
                    id="createQuestionButton"
                    variant="contained"
                    color="primary"
                    onClick={handleCreatOpen}
                >
                    Create {title}
                </Button>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {tableType === 'offer_announcement' && (
                                    <>
                                        <TableCell>ID</TableCell>
                                        {/* <TableCell>Job Title</TableCell> */}
                                        <TableCell>Region</TableCell>
                                        {/* <TableCell>Offer ID</TableCell> */}
                                        <TableCell>Is Active</TableCell>
                                        <TableCell>Coupon Code</TableCell>
                                        <TableCell>Description</TableCell>
                                        <TableCell>Payment Link</TableCell>
                                        <TableCell>Validity Date</TableCell>
                                        <TableCell>Actions</TableCell>
                                    </>
                                )}
                                {tableType === 'hear_about_us' && (
                                    <>
                                        <TableCell>ID</TableCell>
                                        <TableCell>Value</TableCell>
                                        <TableCell>Actions</TableCell>
                                    </>
                                )}
                                {tableType === 'user_greeting' && (
                                    <>
                                        <TableCell>ID</TableCell>
                                        <TableCell>Value</TableCell>
                                        <TableCell>Scenario</TableCell>
                                        <TableCell>Actions</TableCell>
                                    </>
                                )}
                                {tableType === 'career_goal' && (
                                    <>
                                        <TableCell>ID</TableCell>
                                        <TableCell>Goal</TableCell>
                                        <TableCell>Actions</TableCell>
                                    </>
                                )}
                                {tableType === 'job' && (
                                    <>
                                        <TableCell>ID</TableCell>
                                        <TableCell>Job Title</TableCell>
                                        <TableCell>Actions</TableCell>
                                    </>
                                )}
                                {tableType === 'education' && (
                                    <>
                                        <TableCell>ID</TableCell>
                                        <TableCell>Value</TableCell>
                                        <TableCell>Actions</TableCell>
                                    </>
                                )}
                                {tableType === 'work_experience' && (
                                    <>
                                        <TableCell>ID</TableCell>
                                        <TableCell>Value</TableCell>
                                        <TableCell>Actions</TableCell>
                                    </>
                                )}
                                {tableType === 'current_role' && (
                                    <>
                                        <TableCell>ID</TableCell>
                                        <TableCell>Value</TableCell>
                                        <TableCell>Actions</TableCell>
                                    </>
                                )}
                                {tableType === 'currency' && (
                                    <>
                                        <TableCell>ID</TableCell>
                                        <TableCell>Code</TableCell>
                                        <TableCell>Is Active</TableCell>
                                        <TableCell>Description</TableCell>
                                        <TableCell>Actions</TableCell>
                                    </>
                                )}
                                 {tableType === 'static_banner_message' && (
                                    <>
                                        <TableCell>ID</TableCell>
                                        <TableCell>Value</TableCell>
                                        <TableCell>Actions</TableCell>
                                    </>
                                )}
                                {tableType === 'offer_page' && (
                                    <>
                                        <TableCell>ID</TableCell>
                                        <TableCell>Title</TableCell>
                                        <TableCell>Duration</TableCell>
                                        <TableCell>course</TableCell>
                                        <TableCell>Description</TableCell>
                                        <TableCell>Actions</TableCell>
                                    </>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {renderTableRows()}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={entries.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={(event, newPage) => setPage(newPage)}
                    onRowsPerPageChange={(event) => {
                        setRowsPerPage(parseInt(event.target.value, 10));
                        setPage(0);
                    }}
                />
                {moduleopen && (
                    <CreateHearAboutUsModal
                        open={moduleopen}
                        onClose={handleCreatClose}
                        refresh={tableType}
                    />
                 
                )}
                {editModuleOpen &&
                    <CreateHearAboutUsModal
                        open={editModuleOpen}
                        onClose={handleEditClose}
                        refresh={tableType}
                        initialData={currentEntry}
                        {...(true && { isEdit: true })}
                    />
                }

                <SnackBar open={openSnackbar} snackbarTitle={snackbarTitle} close={() => setOpenSnackbar(false)} />
                <DeleteAlert
                    open={deleteOpen}
                    title="Are you sure you want to delete this entry?"
                    confirm={confirmDelete}
                    close={() => setDeleteOpen(false)}
                />

        </div>
    );
};



export default DataTable;
