import React, { useState } from 'react';
import { Box, Typography, Button, Card, CardContent, Dialog, DialogContent } from '@mui/material';
import DiamondImage from '../../../assets/Images/Diamond.png';
import BronzeImage from '../../../assets/Images/Bronze.jpg'
import SilverImage from '../../../assets/Images/silver.png'
import GoldImage from '../../../assets/Images/gold.png'
 
const CourseCompletionCard = ({achivementModel, progress,handlCloseBack }) => {
  console.log(achivementModel,"achivementModel");
  
  const [open, setOpen] = useState(true);

  const [viewImage, setViewImage] = useState('');
  const [viewProgress, setViewProgress] = useState('');
  
  

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    handlCloseBack(progress)
    // setOpen(false);
  };

  const handleRemove = () => {
    setOpen(false);
  };

  const [milestonesReached, setMilestonesReached] = React.useState({
    25: false,
    50: false,
    75: false,
    100: false,
  });
  
  // React.useMemo(() => {
  //   let imageSrc;
  //   let shouldOpen = false;
  
  //   if (progress >= 100 && !milestonesReached[100]) {
  //     imageSrc = DiamondImage;
  //     shouldOpen = true;
  //     setMilestonesReached(prev => ({ ...prev, 100: true }));
  //   } else if (progress >= 75 && !milestonesReached[75]) {
  //     imageSrc = GoldImage;
  //     shouldOpen = true;
  //     setMilestonesReached(prev => ({ ...prev, 75: true }));
  //   } else if (progress >= 50 && !milestonesReached[50]) {
  //     imageSrc = SilverImage;
  //     shouldOpen = true;
  //     setMilestonesReached(prev => ({ ...prev, 50: true }));
  //   } else if (progress >= 25 && !milestonesReached[25]) {
  //     imageSrc = BronzeImage;
  //     shouldOpen = true;
  //     setMilestonesReached(prev => ({ ...prev, 25: true }));
  //   }
  
  //   if (shouldOpen) {
  //     setOpen(true);
  //     setViewImage(imageSrc);
  //   }
  // }, [progress, milestonesReached]);
  

  React.useMemo(() => {
    let imageSrc;
    let viewprogress;
    
    if (progress >= 25 && progress < 50) {
      imageSrc = BronzeImage;
      viewprogress = 25
     
    } else if (progress >= 50 && progress < 75) {
      imageSrc = SilverImage;
      viewprogress = 50
    } else if (progress >= 75 &&  progress < 100) {
      imageSrc = GoldImage;
      viewprogress = 75
    } else if (progress === 100) {
      imageSrc = DiamondImage;
      viewprogress = 100
    } 

    setViewImage(imageSrc)
    setViewProgress(viewprogress)
  }, [progress])
 

 
  const cardHeight = 416; 
  const dialogHeight = cardHeight + 200;
 
  const styles = {
    card: {
      width: '300px',
      borderRadius: '16px 16px 0 0',
      backgroundColor: '#fff',
      textAlign: 'center',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      position: 'relative',
      marginTop: '60px',
      overflow: 'visible',
      height: `${cardHeight}px`,
      border: '4px solid #002B50',
    },
    medalContainer: {
      position: 'absolute',
      top: '-50px',
      left: '50%',
      transform: 'translateX(-50%)',
      width: '130px',
      height: '130px',
      borderRadius: '50%',
      backgroundColor: '#fff',
      border: '4px solid #002B50',
      boxShadow: '0 6px 15px rgba(1, 0, 1, 3.50)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    medalImage: {
      width: '200px',
      height: '120px',
      marginRight: '10px',
       marginTop:'-5px'
    },
    topText: {
      marginTop: '80px',
      color: '#FF7F00',
      fontWeight: 'bold',
      fontSize: '20px',
    },
    percentageText: {
      marginTop: '5px',
      fontWeight: 'bold',
      fontSize: '32px',
      color: '#000',
    },
    subText: {
      fontWeight: 'bold',
      color: '#000',
      fontSize: '18px',
      marginBottom: '20px',
    },
    bottomSection: {
      background: 'linear-gradient(to bottom, #000099, #0000ff)',
      padding: '20px 0',
      color: 'white',
      fontSize: '20px',
      fontWeight: 'bold',
    },
    rewardButton: {
      backgroundColor: '#fff',
      color: '#000',
      borderRadius: '15px',
      padding: '15px 25px',
      marginTop: '33px',
      fontWeight: 'bold',
      textTransform: 'none',
    },
  };
 
  return (
    <Dialog
      open={achivementModel}
      onClose={handleClose}
      sx={{
        '& .MuiDialog-paper': {
          backgroundColor: 'transparent',
          border: 'none',
          boxShadow: 'none',
          margin: 0,
          height: `${dialogHeight}px`, 
          overflow: 'visible',
        }
      }}
    >
      <DialogContent>
        <Card style={styles.card}>
        <Box style={styles.medalContainer}>
              <img
                src={viewImage}
                alt="Medal"
                style={styles.medalImage}
              />
            </Box>
 
          <CardContent>
            <Typography style={styles.topText}>YOU'VE ACHIEVED</Typography>
            <Typography style={styles.percentageText}>{viewProgress}%</Typography>
            <Typography style={styles.subText}>Course Completion</Typography>
          </CardContent>
 
          <Box style={styles.bottomSection}>
            <Typography>Congratulations!</Typography>
            <Button onClick={handleClose} style={styles.rewardButton}>Collect Reward</Button>
          </Box>
        </Card>
      </DialogContent>
    </Dialog>
  );
};
 
export default CourseCompletionCard;

// import React, { useState } from 'react';
// import { Box, Typography, Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
// import BronzeImage from '../../../assets/Images/Bronze.jpg'
// import SilverImage from '../../../assets/Images/silver.png'
// import GoldImage from '../../../assets/Images/gold.png'
// import DiamondImage from '../../../assets/Images/Diamond.png';


// const CourseCompletionCard = ({ achivementModel, progress }) => {
//   const [open, setOpen] = useState(achivementModel);
  // const [viewImage, setViewImage] = useState('');
  

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  // // React.useMemo(() => {
  // //   let imageSrc;
  // //   if (progress === 25) {
  // //     imageSrc = BronzeImage;
  // //   } else if (progress === 50) {
  // //     imageSrc = SilverImage;
  // //   } else if (progress === 75) {
  // //     imageSrc = GoldImage;
  // //   } else if (progress === 100) {
  // //     imageSrc = DiamondImage;
  // //   } 

  // //   setViewImage(imageSrc)
  // // }, [progress])

//   const styles = {
//     card: {
//       width: '350px',
//       borderRadius: '16px',
//       backgroundColor: '#fff',
//       textAlign: 'center',
//       boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
//       position: 'relative',
//       // paddingBottom: '20px',
//       marginTop: '18px',
//       overflow: 'visible',
//       minHeight: '360px',
//       maxHeight: '360px'
//     },
//     medalContainer: {
//       position: 'absolute',
//       top: '-40px',
//       left: '50%',
//       transform: 'translateX(-50%)',
//       width: '70px',
//       height: '70px',
//       borderRadius: '50%',
//       backgroundColor: '#fff',
//       border: '5px solid #fff',
//       boxShadow: '0 4px 10px rgba(0, 0, 0, 0.15)',
//       display: 'flex',
//       justifyContent: 'center',
//       alignItems: 'center',
//     },
//     medalImage: {
//       width: '70px',
//       height: '70px',
//     },
//     topText: {
//       marginTop: '50px',
//       color: '#FF7F00',
//       fontWeight: 'bold',
//       fontSize: '20px',
//     },
//     percentageText: {
//       marginTop: '10px',
//       fontWeight: 'bold',
//       fontSize: '32px',
//       color: '#000',
//     },
//     subText: {
//       color: '#000',
//       fontSize: '18px',
//       marginBottom: '20px',
//     },
//     bottomSection: {
//       background: 'linear-gradient(to bottom, #0A4DFF, #0030C0)',
//       padding: '20px 0',
//       color: 'white',
//       fontSize: '20px',
//       fontWeight: 'bold',
//       borderRadius: '0 0 16px 16px',
//     },
//     rewardButton: {
//       backgroundColor: '#fff',
//       color: '#000',
//       borderRadius: '25px',
//       padding: '10px 20px',
//       marginTop: '20px',
//       fontWeight: 'bold',
//       textTransform: 'none',
//     },
//     dialogCard: {
//       padding: '20px',
//       textAlign: 'center',
//       backgroundColor: '#f5f5f5',
//       borderRadius: '12px',
//       boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
//     },

//   };

//   return (
//     <>

//       <Dialog open={open} onClose={handleClose}>
//         <DialogContent>

//           <Card style={styles.card}>
            // <Box style={styles.medalContainer}>
            //   <img
            //     src={viewImage}
            //     alt="Medal"
            //     style={styles.medalImage}
            //   />
            // </Box>

//             <CardContent>
//               <Typography style={styles.topText}>YOU'VE ACHIEVED</Typography>
//               <Typography style={styles.percentageText}>{progress}%</Typography>
//               {/* <Typography style={styles.percentageText}>25%</Typography> */}
//               <Typography style={styles.subText}>Course Completion</Typography>
//             </CardContent>

//             <Box style={styles.bottomSection}>
//               <Typography>Congratulations!</Typography>
//               <Button style={styles.rewardButton}>Collect Reward</Button>
//             </Box>


//           </Card>
//         </DialogContent>

//       </Dialog>
//     </>
//   );
// };

// export default CourseCompletionCard;