import React, { useEffect, useRef } from 'react';
import { Grid, Typography, TextField } from '@mui/material';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { useLocation } from 'react-router-dom';

import adminServices from '../../../services/adminServices';
import SnackBar from '../../../components/snackbar/snackbar';
import PhoneNumber from '../../../components/PhoneNumber/Index';

export default function AddClientForm(props) {    
  const location = useLocation();
  const lottoLoad = useRef();
  const [formValues, setFormValues] = React.useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  });
  const [loading, setLoading] = React.useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarTitle, setSnackbarTitle] = React.useState('');
  const [selectedId, setSelectedId] = React.useState('');

  useEffect(() => {
    if (props.mode === 'edit' && props.data) {
      setFormValues({
        firstName: props.data.first_name || '',
        lastName: props.data.last_name || '',
        email: props.data.email || '',
        phone: props.data.phone || '',
      });
      setSelectedId(props.data.id);
    } else {
      setFormValues({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
      });
    }
  }, [props.mode, props.data]);
  
  const handleAddClientFormValues = async (values) => {
    setLoading(true);
    const requestData = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      contactPhone: values.phone || '',
    }; 
    try {        
      const response = props.mode === 'create'
        ? await adminServices.postContentWriterList(requestData)
        : await adminServices.putContentWriterList(requestData, selectedId);

      if (response.ok) {
        setOpenSnackbar(true);
        setSnackbarTitle(response.data.message);
        props.getClientList();
        props.toggle();
      } else if (response.status === 400) {
        setOpenSnackbar(true);
        setSnackbarTitle(response.data.error);
      }
    } catch (error) {
      console.log(error);
      setOpenSnackbar(true);
      setSnackbarTitle('An error occurred. Please try again.');
    }
    setLoading(false);
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .trim()
      .min(3, 'First name must be at least 3 characters')
      .required('First name is required'),
    lastName: Yup.string()
      .trim()
      .min(3, 'Last name must be at least 3 characters')
      .required('Last name is required'),
    email: Yup.string()
      .trim()
      .required('Email is required')
      .email('Email must be a valid email'),
  });

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={formValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          handleAddClientFormValues(values);
        }}
      >
        {({ values, errors, touched, handleBlur, handleChange, setFieldValue }) => (
          <Form>
            <Grid container spacing={2} sx={{ p: 1 }}>
              <Grid item xs="12" sm="6">
                <Typography variant="subtitle1" gutterBottom>
                  First Name*
                </Typography>
                <TextField
                  fullWidth
                  name="firstName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.firstName}
                  variant="outlined"
                  error={Boolean(touched.firstName && errors.firstName)}
                  helperText={touched.firstName && errors.firstName}
                />
              </Grid>
              <Grid item xs="12" sm="6">
                <Typography variant="subtitle1" gutterBottom>
                  Last Name*
                </Typography>
                <TextField
                  fullWidth
                  name="lastName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.lastName}
                  variant="outlined"
                  error={Boolean(touched.lastName && errors.lastName)}
                  helperText={touched.lastName && errors.lastName}
                />
              </Grid>
              <Grid item xs="12" sm="6">
                <Typography variant="subtitle1" gutterBottom>
                  Phone
                </Typography>
                <PhoneNumber
                  fullWidth
                  country={'us'}
                  specialLabel={''}
                  onChange={(value) => setFieldValue('phone', value)}
                />
              </Grid>
              <Grid item xs="12" sm="6">
                <Typography variant="subtitle1" gutterBottom>
                  Email*
                </Typography>
                <TextField
                  fullWidth
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                  variant="outlined"
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                />
              </Grid>
              <Grid item xs="12">
                <LoadingButton fullWidth size="medium" type="submit" variant="contained" loading={loading}>
                  {props.mode === 'create' ? 'Create' : 'Update'}
                </LoadingButton>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>

      <SnackBar open={openSnackbar} snackbarTitle={snackbarTitle} close={() => setOpenSnackbar(false)} />
    </>
  );
}
