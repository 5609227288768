import React, { useState, useEffect } from 'react';
import {
  Grid,
  Paper,
  Typography,
  IconButton
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Page from '../../../components/Page';
import PageHeader from '../../../components/PageHeader';
import adminServices from '../../../services/adminServices';
import DataTable from './ConfigView';

const Config = () => {
  const classes = useStyles();
  const isGoBack = true;
  const navigate = useNavigate();
  const [categoryDetails, setCategoryDetails] = useState([]);
  const [genderDetails, setGenderDetails] = useState([]);
  const [roleDetails, setRoleDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingCardId, setLoadingCardId] = useState(null);
  const [workDetails, setWorkDetails] = useState([]);
  const [educationDetails, setEducationDetails] = useState([]);
  const [jobDetails, setJobDetails] = useState([]);
  const [aboutDetails, setAboutDetails] = useState([]);
  const [carrierDetails, setCarrierDetails] = useState([]);
  const [offerAnnouncement, SetOfferAnnouncement] = useState([]);
  const [userGreeting, setUserGreeting] = useState([]);
  const [userOffers, setUserOffers] = useState([]);
  const [selectedData, setSelectedData] = useState(null);
  const [offer, setOffer] = useState([]);
  console.log(offer,'offerrrrrrrr');
  
  const courseType = [
    { id: 1, title: 'Hear About Us', tableType: 'hear_about_us' },
    { id: 2, title: 'Current Role', tableType: 'current_role' },
    { id: 3, title: 'Work Experience', tableType: 'work_experience' },
    { id: 4, title: 'Education', tableType: 'education' },
    { id: 5, title: 'Job', tableType: 'job' },
    { id: 6, title: 'Career Goal', tableType: 'career_goal' },
    { id: 7, title: 'Offer Announcement', tableType: 'offer_announcement' },
    { id: 8, title: 'User Greetings', tableType: 'user_greeting' },
    { id: 9, title: 'Offer Banner', tableType: 'static_banner_message' },
    { id: 10, title: 'Offer', tableType: 'offer_page' },

  ];

  useEffect(() => {
    getDetails();
  }, []);

  const getDetails = async () => {
    setIsLoading(true);
    const res = await adminServices.getBasicDetails();
    if (res.ok) {
      console.log(res,'responces1234');
      
      setCategoryDetails(res.data.courseHashTags);
      res.data?.settings?.forEach(item => {
        switch (item.config_name) {
          case 'gender':
            setGenderDetails(item.data);
            break;
          case 'current_role':
            setRoleDetails(item.data);
            break;
          case 'work_experience':
            setWorkDetails(item.data);
            break;
          case 'education':
            setEducationDetails(item.data);
            break;
          case 'job':
            setJobDetails(item.data);
            break;
          case 'hear_about_us':
            setAboutDetails(item.data);
            break;
          case 'career_goal':
            setCarrierDetails(item.data);
            break;
          case 'offer_announcement':
            SetOfferAnnouncement(item.data);
            break;
          case 'user_greeting':
            setUserGreeting(item.data);
            break;
          case 'static_banner_message':
            setUserOffers(item.data);
            break;
            case 'offer_page':
              setOffer(item.data);
              break;
          default:
            break;
        }
      });
      setIsLoading(false);
    }
    setIsLoading(false);
  };


  const chooseOpenModel = (data) => {
    setLoadingCardId(data.id);
    setIsLoading(true); 

    const detailsMap = {
      gender: genderDetails,
      current_role: roleDetails,
      work_experience: workDetails,
      education: educationDetails,
      job: jobDetails,
      hear_about_us: aboutDetails,
      career_goal: carrierDetails,
      offer_announcement: offerAnnouncement,
      user_greeting: userGreeting,
      static_banner_message: userOffers,
      offer_page: offer
    };
    setSelectedData({
      title: data.title,
      description: data.description,
      tableType: data.tableType,
      details: detailsMap[data.tableType] || [],
    });
console.log('name changed');

    setLoadingCardId(null);
    setIsLoading(false);
  };
  const handleGoBack = () => {
    setSelectedData(null);
  };
  return (
    <Page title="Create-courses">
    {!selectedData?<div>
        <PageHeader pageTitle="Setting" goBack={isGoBack} />
        <Grid container spacing={2} sx={{ marginTop: 2 }}>
          {courseType.map((response) => (
            <Grid item sm={4} md={4} lg={3} key={response.id}>
              <Paper
                elevation={2}
                className={classes.paper}
                onClick={() => chooseOpenModel(response)}
              >
                {loadingCardId === response.id ? (
                  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' }}>
                      <Typography variant="subtitle2" gutterBottom>
                        {response.title}
                      </Typography>
                      <ContentCopyIcon sx={{ color: '#d2cacadb', fontSize: '20px' }} />
                    </Box>
                    <Typography variant="body1">{response.description}</Typography>
                  </>
                )}
              </Paper>
            </Grid>
          ))}
        </Grid>
      </div>:  (
        <div>
        <IconButton size="small" onClick={handleGoBack} sx={{ marginLeft: '-8px !important' }}>
          <ArrowBackIcon color="inherit" />
        </IconButton>
        <Typography variant="h6">{selectedData.title}</Typography>
        <DataTable
          title={selectedData.title}
          description={selectedData.description}
          tableType={selectedData.tableType}
          details={selectedData.details}
          onGetAllDetails={getDetails} 
        />
      </div>
        )}
    </Page>
  );
};


const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '8px 14px',
    position: 'relative',
    background: '#F9F9F9',
    boxShadow: 'none',
    border: '1px solid #e5e1e1',
    minHeight: '116px',
    cursor: 'pointer',
    '&:hover': {
      background: theme.palette.primary.main,
      color: '#fff',
    },
  },
}));

export default Config;
