import React from 'react';
import { Box, MenuItem, Typography } from '@mui/material';

const ListPage = ( {searchResults,searchComplete,handleStartSimulation,setOpenVideoModal,setVideoTitle,isQuery} ) => {

  return (
    <>
        {searchComplete && (
                <Box sx={{
                    maxHeight: '150px',
                    overflowY: searchResults.length > 1 ? 'auto' : 'hidden',
                    scrollbarWidth: 'thin',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflowX: 'hidden'
                }}>
                    {searchResults.map((searchRes) => {
                        return (
                            <>
                                {"topic" in searchRes && (
                                    <MenuItem key={searchRes.sid} onClick={() => handleStartSimulation(searchRes.sid,searchRes.mod)}>
                                        {`Topic "${searchRes.topic}" in ${searchRes.sub}`}
                                    </MenuItem>
                                )}
                                {"video" in searchRes && searchRes.video && (
                                    <MenuItem
                                        key={searchRes.id}
                                        onClick={() => {
                                            setOpenVideoModal(true);
                                            console.log('searchRes---',searchRes)
                                            const arrLength = searchRes.video.split('/');
                                            setVideoTitle({
                                                title: searchRes.sub,
                                                // video: arrLength[arrLength.length - 1],
                                                video: searchRes.video,
                                            });
                                        }}
                                    >
                                        {`Video for ${searchRes.sub}`}
                                    </MenuItem>
                                )}
                            </>
                        );
                    })}
                    {searchResults.length === 0 && isQuery.length > 0 && (
                        <Typography>
                            No results found
                        </Typography>
                    )}  
                </Box>
             )
        }
    </>
  )
}

export default ListPage