import React, { useState, useEffect } from 'react';
import { Typography, Button, Tooltip, Table, TableBody, TableCell, TableContainer, TableHead, IconButton, TableRow, Paper, TablePagination } from '@mui/material';
import { useTranslation } from 'react-i18next';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link as RouterLink } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import moment from 'moment';
import Modal from '../../../components/modal/Index';
import BasicModal from '../../../components/modal/BasicModel';
import DeleteAlert from '../../../components/modal/DeleteModal';
import SnackBar from '../../../components/snackbar/snackbar';
import PageHeader from '../../../components/PageHeader';
import Page from '../../../components/Page';
import adminServices from '../../../services/adminServices';
import AddContentWriterForm from './AddContentWriterForm';

export default function Client(props) {
  const { t } = useTranslation('user'); 
  const [clientList, setClientList] = useState('');
  
  const [modalOpen, setModalOpen] = useState(false);  
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleteClientId, setDeleteClientId] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarTitle, setSnackbarTitle] = useState('');
  const [mode, setMode] = useState('');
  const [clientId, setClientId] = useState(null);  
  const [editData ,setEditData] = useState('')
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const getClientLists = async () => {
    setLoading(true);
    try {
      const response = await adminServices.getContentWriterList();
      if (response.ok) {
        setClientList(response.data);
      } 
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getClientLists();
  }, []);

  const handleEditButton = (data) => {
    setMode('edit');
    setClientId(data.id);
    setEditData(data)
    setModalOpen(true);
  };

  const handleOpenDeleteModal = (clientId) => {
    setDeleteAlert(true);
    setClientId(clientId);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const snakbarHandle = (message) => {
    setOpenSnackbar(true);
    setSnackbarTitle(message);
  };

  const handleDelete = async () => {
    try {
      const response = await adminServices.deleteContentWriterList(clientId);
      if (response.ok) {
        getClientLists();
        setSnackbarTitle(response.data.message);
      } else {
        setSnackbarTitle(response.data.error);
      }
      setDeleteAlert(false);
      setOpenSnackbar(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Page title={t('ContentWriter')}>
      <PageHeader pageTitle={t('Content Writer')} />
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '1rem' }}>
        <div />
        <Button variant="contained" onClick={() => { setMode('create'); setModalOpen(true); }}>
          <AddIcon fontSize="small" />
          {t('add Content Writer')}
        </Button>
      </div>
      <TableContainer component={Paper} style={{ marginTop: '1rem' }}>
        <Table>
        <TableHead>
  <TableRow>
    <TableCell align="left">{t('First Name')}</TableCell>
    <TableCell align="left">{t('Last Name')}</TableCell>
    <TableCell align="left">{t('Email')}</TableCell>
    <TableCell align="left">{t('Phone Number')}</TableCell>
    <TableCell align="center">{t('Created On')}</TableCell>
    <TableCell align="center">{t('Action')}</TableCell>
  </TableRow>
</TableHead>
<TableBody>
  {loading && (
    <TableRow>
      <TableCell colSpan={6} style={{ textAlign: 'center' }}>
        Loading...
      </TableCell>
    </TableRow>
  )}
  
  {!loading && clientList && clientList.length > 0 ? (
    clientList.map((client) => (
        
      <TableRow key={client?.id}>
        <TableCell align="left">{client?.first_name}</TableCell>
        <TableCell align="left">{client?.last_name}</TableCell>
        <TableCell align="left">
          <Tooltip title={client?.email}>
            <Typography>{client?.email}</Typography>
          </Tooltip>
        </TableCell>
         <TableCell align="left">
          <Tooltip title={client?.phone}>
            <Typography>{client?.phone ? client?.phone : '-'}</Typography>
          </Tooltip>
        </TableCell>
        <TableCell align="center">
          <Typography>{moment(client?.create_datetime).format('MM-DD-YYYY')}</Typography>
        </TableCell>
        <TableCell align="center">
          <IconButton onClick={() => handleEditButton(client)}>
            <EditIcon fontSize="small" />
          </IconButton>
          <IconButton onClick={() => handleOpenDeleteModal(client.id)}>
            <DeleteIcon fontSize="small" />
          </IconButton>
        </TableCell>
      </TableRow>
    ))
  ) : (
    !loading && (
      <TableRow>
        <TableCell colSpan={6} style={{ textAlign: 'center' }}>
          Sorry, there is no matching data to display.
        </TableCell>
      </TableRow>
    )
  )}
</TableBody>



        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        component="div"
        count={clientList.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Modal
        title={mode === 'create' ? 'Add Clients' : 'Edit Clients'}
        open={modalOpen}
        toggle={handleModalClose}
        children={
          <AddContentWriterForm
            mode={mode}
            clientId={clientId}
            getClientList={getClientLists}
            data={editData}
            toggle={handleModalClose}
            snackBarControl={snakbarHandle}
          />
        }
      />
      <DeleteAlert
        open={deleteAlert}
        title={t('Are you sure you want delete this User')}
        confirm={handleDelete}
        close={() => setDeleteAlert(false)}
      />
      <SnackBar open={openSnackbar} snackbarTitle={snackbarTitle} close={() => setOpenSnackbar(false)} />
    </Page>
  );
}
