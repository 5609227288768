export const GetExcelDetailPending = 'GetExcelDetailPending'
export const GetExcelDetailSuccess = 'GetExcelDetailSuccess'
export const GetExcelDetailFailure = 'GetExcelDetailFailure'

export const GetWordDetailsPending = 'GetWordDetailsPending'
export const GetWordDetailsSuccess = 'GetWordDetailsSuccess'
export const GetWordDetailsFailure = 'GetWordDetailsFailure'

export const GetAccDetailsPending = 'GetAccDetailsPending'
export const GetAccDetailsSuccess = 'GetAccDetailsSuccess'
export const GetAccDetailsFailure = 'GetAccDetailsFailure'

export const GetCodeDetailsPending = 'GetCodeDetailsPending'
export const GetCodeDetailsSuccess = 'GetCodeDetailsSuccess'
export const GetCodeDetailsFailure = 'GetCodeDetailsFailure'

export const GetRecomendedPending = 'GetRecomendedPending'
export const GetRecomendedSuccess = 'GetRecomendedSuccess'
export const GetRecomendedFailure = 'GetRecomendedFailure'

export const GetCertificatePending = 'GetCertificatePending'
export const GetCertificateSuccess = 'GetCertificateSuccess'
export const GetCertificateFailure = 'GetCertificateFailure'

export const GetFreeCoursePending = 'GetFreeCoursePending'
export const GetFreeCourseSuccess = 'GetFreeCourseSuccess'
export const GetFreeCourseFailure = 'GetFreeCourseFailure'

export const GetAllCoursePending = 'GetAllCoursePending'
export const GetAllCourseSuccess = 'GetAllCourseSuccess'
export const GetAllCourseFailure = 'GetAllCourseFailure'



export const GetProgressPending = 'GetProgressPending'
export const GetProgressSuccess = 'GetProgressSuccess'
export const GetProgressFailure = 'GetProgressFailure'

export const GetCoursePending = 'GetCoursePending'
export const GetCourseSuccess = 'GetCourseSuccess'
export const GetCourseFailure = 'GetCourseFailure'


export const GetSearchCoursePending = 'GetSearchCoursePending'
export const GetSearchCourseSuccess = 'GetSearchCourseSuccess'
export const GetSearchCourseFailure = 'GetSearchCourseFailure'


export const GetCategorySuccess = 'GetCategorySuccess'
export const GetCategoryPending = 'GetCategoryPending'
export const GetCategoryFailure = 'GetCategoryFailure'

export const GetERGSuccess = 'GetERGSuccess'
export const GetERGPending = 'GetERGPending'
export const GetERGFailure = 'GetERGFailure'

export const GetPowerPointSuccess = 'GetPowerPointSuccess'
export const GetPowerPointPending = 'GetPowerPointPending'
export const GetPowerPointFailure = 'GetPowerPointFailure'


export const GetWordSucces = 'GetWordSucces'
export const GetWordPending = 'GetWordPending'
export const GetWordFailure = 'GetWordFailure'