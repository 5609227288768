// 1
import React, { useEffect, useMemo, useState } from 'react';
import { Box, Grid, Typography, Container, Button, IconButton, CardMedia } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import GridOnIcon from '@mui/icons-material/GridOn';
import ViewListIcon from '@mui/icons-material/ViewList';
import { makeStyles } from '@mui/styles';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Card from '@mui/material/Card';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import PersonIcon from '@mui/icons-material/Person';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import SwiperCore, { Navigation, Pagination, Mousewheel, Keyboard } from 'swiper';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import Page from '../../../components/Page';
import UserCard from '../../../components/cards/Freecard';
import courseApi from '../../../services/users/courseApi';
import CardSkeleton from '../../../components/Skeleton/cardSkeleton';
import Background from '../../../assets/logo/myCourseBackground.svg';


SwiperCore.use([Navigation, Pagination, Mousewheel, Keyboard]);
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function FreeCourseList(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const [courseList, setCourseList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [courseGroups, setCourseGroup] = useState([]);


  const userRole = useSelector((state) => state.userInfo && state.userInfo.role);

  React.useMemo(()=>{
    if(props.courseGroups?.length > 0){
      const newArray = [props.courseGroups];
      setCourseGroup(newArray)
    }
    else{
      const newArray1 = [];
      setCourseGroup(newArray1)
    }
  },[props])

  const style = {
    marginTop: '14px',
  };

  useEffect(() => {
    const script = document.getElementById('zsiqscript');
    if (script) {
      script.parentNode.removeChild(script);
    }
  }, []);


  useEffect(() => {
    getCourserList();
  }, []);


  const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
    ({ theme }) => ({
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[2],
        fontSize: 11,
      },
    })
  );


  const getCourserList = async () => {
    const response = await courseApi.getCourseList();
    if (response.ok) {
      setCourseList(response.data);
      setLoading(false);

      // const individualCourses = response.data.filter((item) => item.isBundle === false);
      // const bundleCourses = response.data.filter((item) => item.isBundle);
      // const grouped = Array.from(
      //   bundleCourses
      //     .reduce((m, o) => m.set(o.subscriptionPlanId, (m.get(o.subscriptionPlanId) || []).concat(o)), new Map())
      //     .values()
      // );

      // if (userRole === 'USER_DTC' && response.data?.length === 0) {
      //   navigate('/auth/subscribe');
      // }
    }
  };

  const handleCardClickItem = (data) => {
    if (userRole === 'USER_DTC') {
      navigate('/auth/course-details', { state: data });
    } else {
      navigate('/app/course-details', { state: data });
    }
  };

  function LinearProgressWithLabel({ value }) {
    return (
      <Box>
        <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '16px !important' }} mb={0.5}>
          <Box sx={{ width: '100%', mr: 1 }}>
            <LinearProgress variant="determinate" value={value} />
          </Box>
          <Box sx={{ width: 'max-content' }}>
            <Typography variant="body2" color="gray" sx={{ fontSize: '0.7rem' }}>
              {value}%
            </Typography>
          </Box>
        </Box>
        <Typography variant="body2" color={'primary'} sx={{ fontSize: '0.7rem', marginTop: '-8px' }}>
          Completed
        </Typography>
      </Box>
    );
  }

  const ButtonGrp = () => (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
      <IconButton>
        <SearchIcon fontSize="medium" style={{ color: '#BCBCBC' }} />
      </IconButton>
      <IconButton>
        <FilterAltIcon fontSize="medium" style={{ color: '#BCBCBC' }} />
      </IconButton>
      <IconButton>
        <GridOnIcon fontSize="medium" style={{ color: '#00B673' }} />
      </IconButton>
      <IconButton>
        <ViewListIcon fontSize="medium" style={{ color: '#BCBCBC' }} />
      </IconButton>
    </div>
  );

  return (

    <Grid>
      <Page title="Course-list" style={style}>
        <Container maxWidth={false} sx={{ padding: '0 !important' }}>

          
          <Grid container spacing={2}>

            {loading ? (
              <>
                {[1, 2, 3, 4].map((res) => (
                  <Grid item xs={12} sm={4} md={4} lg={3} key={res}>
                    <CardSkeleton />
                  </Grid>
                ))}
              </>
            ) : (
              <>
                {userRole === 'AUTH_USER' && courseList.map((item) => (
                  <>
                    {item.type === 'simulation' && (
                      <Grid item xs={12} sm={4} md={4} lg={3} xl={3} key={item.id}>
                        <UserCard
                        
                          handleCardClick={() => handleCardClickItem(item)}
                          image={item.thumbImage}
                          planStatus={item.planStatus}
                          title={item.title}
                          trial={item.isTrial}
                          subscribed={item.isSubscribed}
                          category={item?.category_description ? item?.category_description:item?.category.description}
                          logo={item?.category_image ? item?.category_image:item?.category?.categoryImgUrl}
                          enrollDate={item.validFrom}
                          progress={<LinearProgressWithLabel value={item.completed} />}
                          data={item}
                        />
                      </Grid>
                    )}
                  </>
                ))}

                {courseGroups && courseGroups?.length > 0 && courseGroups[0]?.length > 0  ? courseGroups.map((res, indx) => {
                  const view = [...res].sort((a, b) => {
                    if (a.isSubscribed && !b.isSubscribed) {
                      return -1;
                    }
                    if (!a.isSubscribed && b.isSubscribed) {
                      return 1;
                    }
                    return 0;
                  });

                 
                  return (
            
                    <>
               {view?.length > 0 &&
                    <Grid item xs={12} sm={12} mb={res.length > 0 ? 4 : 0} key={indx}>

                      <>
                        <Grid style={{ backgroundColor: '#F8F9FA',maxHeight: '460px',minHeight:'460px', padding: '16px 0 0 0'  }}>
                          
                         
                          <Swiper
                            spaceBetween={10}
                            slidesPerView={4}
                            breakpoints={{
                              1000: {
                                slidesPerView: 4,
                              },
                              600: {
                                slidesPerView: 2,
                              },
                              0: {
                                slidesPerView: 1,
                              },
                            }}
                            navigation={view?.length > 4}
                            pagination={{
                              el: view.length > 4 ? `.${classes.swiperPagination}` : "",
                              clickable: view.length > 4,
                              bulletClass: 'custom-bullet',
                              bulletActiveClass: 'custom-bullet-active'
                            }}
                            keyboard
                            className="mySwiper" 
                          >
                            {view.map((item, index) => (
                              <SwiperSlide key={index} >
                                <UserCard
                                  type={item.type}
                                  handleCardClick={() => handleCardClickItem(item)}
                                  image={item.thumb_img_url}
                                  planStatus={item.planStatus}
                                  title={item.title}
                                  trial={item.is_trial ? item.is_trial:item.isTrial}
                                  subscribed={item.isSubscribed}
                                  category={item?.category_description ? item?.category_description:item?.category.description}
                                  logo={item?.category_image ? item?.category_image:item?.category?.categoryImgUrl}
                                  enrollDate={item.validFrom}
                                  progress={<LinearProgressWithLabel value={item.completed} />}
                                  data={item}
                                />
                              </SwiperSlide>
                            ))}
                          </Swiper>

                          <div className={classes.swiperPagination} />
                        </Grid>


                      </>


                    </Grid>}
                    </>
                  )
                }):""}
              </>
            )}
          </Grid>
        </Container>
      </Page>
    </Grid>

  );

}

const useStyles = makeStyles((theme) => ({
  coursediv: {
    height: '10rem',
    backgroundImage: `url(${Background})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    marginTop: '4rem',
  },
  title: {
    padding: '4rem 0rem 0rem 0rem',
  },
  scrollContainer: {
    overflowX: 'auto',
    whiteSpace: 'nowrap',
    '-webkit-overflow-scrolling': 'touch',
  },
  swiperPagination: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '10px',
  },
  '@global': {
    '.custom-bullet': {
      width: '8px',
      height: '8px',
      background: '#000',
      margin: '0 5px',
      borderRadius: '50%',
      opacity: 0.5,
      cursor: 'pointer',
    },
    '.custom-bullet-active': {
      opacity: 1,
      background: '#789df5',
    },
    '.swiper-button-next, .swiper-button-prev': {
      width: '25px',
      height: '25px',
      marginTop: '-8px',
      // color:'#054df7',
      // backgroundColor: '#789df5',
      backgroundColor: '#000000',

      borderRadius: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      margin: '0px',
      '&::after': {
        fontSize: '14px',
        color: '#fff',
      },
    },

    // '.mySwiper .swiper-button-next':{
    //    display: 'none'
    // },
     '.makeStyles-swiperPagination-68':{
      marginTop: '22px'
    },
  //   '.mySwiper .swiper-button-prev':{
  //     display: 'none'
  //  },
   '.makeStyles-swiperPagination-140':{
      marginTop: '22px !important'
   },
    '.MuiGrid-root.css-vj1n65-MuiGrid-root':{
      position: 'relative'
    },
    '.swiper-button-next': {
      right: '0px',
    },
    '.swiper-button-prev': {
      left:  '0px',
    },
    // '.': {
    //   left: '0px',
    // },
    '.swiper-container-pointer-events': {
      zIndex: '999',
      overflow: 'hidden',     
      maxWidth: '95%',
      margin: 'auto'
  }


   
  },

  trial: {
    color: 'white',
    padding: '0px 6px 0px 6px ',
    background: 'grey',
  },
  card: {
    border: '0.5px solid #DFDFDF',
    boxShadow: '0px 3px 6px  #0000001A',
    borderRadius: '6px',
    backgroundImage: `url(${Background})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'bottom right',
    cursor: 'pointer',
    marginTop: '16px',
    marginLeft: '16px',
    marginRight: '16px',
    width: '100%',
    height:'60%'
  },
  date: {
    marginTop: '15px',
  },
  button: {
    backgroundColor: '#F8F5F4',
    padding: '4px 6px',
    borderRadius: '6px',
    width: 'max-content',
  },
}));

