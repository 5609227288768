import client from '../apiClient';

const getTopics = (subModuleId,free) => client.get(`/user/submodule/topics?courseSubModuleId=${subModuleId}&Is_free=${free}`);

const getScreens = (topicId, actionType) =>
  client.get(`/user/topicScreen?submoduleTopicId=${topicId}
  &&actionType=${actionType}`);

const postResult =(payLoad) => client.post('/user/result', JSON.stringify(payLoad));
const postSQLResult = (process.env.REACT_APP_ENV === 'dev') 
  ? (payLoad) => client.post('/user/executeSQL', JSON.stringify(payLoad))
  : (payLoad) => client.post('/user/result', JSON.stringify(payLoad));

const pythonPostResult = (payLoad) => client.post(`/user/executePython`, JSON.stringify(payLoad));

export default {
  getTopics,
  getScreens,
  postResult,
  pythonPostResult,
  postSQLResult
};
