import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Joyride from 'react-joyride';
import { BroadcastChannel } from 'broadcast-channel';
import swal from 'sweetalert';
import Cookies from 'js-cookie';

// routes
import Router from './routes';

// components
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/chart/BaseOptionChart';
import NetworkStatusDetector from './components/NetworkStatus';
import { SubscribeModal } from './components/modal/SubscribeAlertModal';
import Snackbar from './components/snackbar/snackbar';
import { closeSubscriptionModal, openSnackbar, closeSnackbar, guideHandler, logoutSuccess } from './store/reducer';
import trialAndStripeSubscriptionService from './services/trialAndStripeSubscriptionService';
import { SearchProvider } from './layouts/website/SearchContext'

// ----------------------------------------------------------------------
import { Steps } from './website/pages/Steps';
import theme from './theme/palette';
import helper from './utils/helper';

export default function App() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation(); 
  const [indexValue, setIndexValue] = useState(null);
  const token = Cookies.get('token');

  const handleJoyrideCallback = (data) => {
    const { status, type, action, index, reset } = data;
    if (status === 'finished') {
      dispatch(guideHandler(false));
    } else if (action === 'close') {
      setIndexValue(0);
      dispatch(guideHandler(false));
    }
  };

  const navigate = useNavigate();
  const logoutChannel = new BroadcastChannel('logout');
  const { openSubscriptionModal, planId, snackbarOpen, snackbarTitle, isGuiding, isLogin } = useSelector(
    (state) => state
  );
  const [subscriptionModalLoading, setSubscriptionModalLoading] = useState(false);

  const logoutAllTabs = () => {
    logoutChannel.onmessage = () => {
      logoutChannel.postMessage('Logout');
      dispatch(logoutSuccess());
      navigate('/login');
      logoutChannel.close();
    };
  };

  const handleTokenExpire = () => {
    swal({
      text: 'Session has been expired please re-login. ',
      buttons: {
        confirm: {
          text: 'Okay',
          value: true,
          visible: true,
          className: '',
          closeModal: true,
        },
      },
      closeOnClickOutside: false,
    }).then((res) => {
      logoutChannel.postMessage('Logout');
      dispatch(logoutSuccess());
      navigate('/login');
    });
  };

  useEffect(() => {
    logoutAllTabs();
    if (isLogin && !token) {
      handleTokenExpire();
    }
  }, []);

  const handleSubscribeModalClose = () => {
    dispatch(closeSubscriptionModal());
  };

  const handleSnackbarClose = () => {
    dispatch(closeSnackbar());
  };

  const handleSubmit = async (data) => {
    setSubscriptionModalLoading(true);

    let impactClickId = '';
    try {
      // impactClickId = await helper.getClickId();
    } catch (error) {
      console.error(error);
      impactClickId = '';
    }

    const payload = {
      planId,
      frequency:1,
      currencyType: data.currency.code,
      impactClickId,
    };

    try {
      const res = await trialAndStripeSubscriptionService.createStripeSubscription(JSON.stringify(payload));
      if (res.status === 303) {
        dispatch(closeSubscriptionModal());
        setSubscriptionModalLoading(false);
        window.location = res.data.paymentUrl;
      } else {
        if (res?.data?.message) {
          dispatch(openSnackbar(res.data.message));
        } else {
          dispatch(openSnackbar('Failed to Subscribe, Please try again.'));
        }
        setSubscriptionModalLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    dispatch(guideHandler(false));
  }, [location]);

  return (
    <>
      <ScrollToTop />
      <BaseOptionChartStyle />
      <NetworkStatusDetector />
      <SearchProvider>
      <Router />
      </SearchProvider>
      <Snackbar open={snackbarOpen} snackbarTitle={snackbarTitle} close={handleSnackbarClose} />
      <SubscribeModal
        open={openSubscriptionModal}
        close={handleSubscribeModalClose}
        loading={subscriptionModalLoading}
        submit={handleSubmit}
      />

      <div className="app">
        <Joyride
          tourActive
          disableOverlayClose
          stepIndex={indexValue}
          // disableOverlay
          // tooltipComponent={CustomeTooltip}
          callback={handleJoyrideCallback}
          steps={Steps(location?.pathname)}
          run={isGuiding}
          continuous
          // showSkipButton
          // spotlightClicks
          // showProgress
          scrollToFirstStep
          // hideCloseButton
          scrollOffset={80}
          styles={{
            buttonClose: {
              color: 'white',
              height: 12,
              padding: 15,
              position: 'absolute',
              right: 0,
              top: 0,
              width: 12,
            },
            buttonBack: {
              backgroundColor: 'white',
              color: 'black',
              borderRadius: '6px',
              fontSize: '14px',
            },

            buttonNext: {
              backgroundColor: 'white',
              color: 'black',
              borderRadius: '6px',
              fontSize: '14px',
            },

            options: {
              backgroundColor: theme.primary.main,
              zIndex: 1000,
              arrowColor: theme.primary.main,
            },
          }}
        />
      </div>
    </>
  );
}
