import React from 'react';
import { Box, CssBaseline, List, ListItemButton, ListItemText, ListItemIcon, Toolbar, Typography } from '@mui/material';
import WorkIcon from '@mui/icons-material/Work';  
import ManageSearchOutlinedIcon from '@mui/icons-material/ManageSearchOutlined';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import PersonSearchOutlinedIcon from '@mui/icons-material/PersonSearchOutlined';
 
export default function Sidebar({ onDetailChange, activeStep }) {
  const items = [
    { label: 'Personal', icon: <PersonOutlinedIcon /> },
    { label: 'Profession', icon: <SchoolOutlinedIcon /> },
    { label: 'Other Details', icon: <ManageSearchOutlinedIcon /> }
  ];
 
  const sidebarContent = (
    <div>
      <Toolbar/>
      <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <ListItemIcon sx={{ minWidth: '40px', marginRight: '18px' }}>
      <PersonSearchOutlinedIcon sx={{ color: '#e32f29' ,marginLeft: '18px',marginTop: '-38px'}}  />
      </ListItemIcon>
      <Typography
        variant="h6"  
        sx={{ textAlign: 'center', color: '#e32f29', fontWeight: 'bold', marginTop: '-90px', marginBottom: '-40px', display: 'inline' }}
      >
        About Yourself
      </Typography>
      </Box>
      <List>
        {items.map((item, index) => (
          <ListItemButton
            key={item.label}
            selected={index === activeStep}
            sx={{ marginBottom: '20px' }}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.label} />
          </ListItemButton>
        ))}
      </List>
    </div>
  );
 
  return (
    <Box sx={{ display: 'flex', ml: '100px' }}>
      <CssBaseline />
      <Box
        component="aside"
        sx={{
          width: { sm: 240 },
          flexShrink: { sm: 0 },
          display: { xs: 'none', sm: 'block' },
          bgcolor: 'background.paper',
          padding: 2,
          height: '300px',
          border: '1px solid #ddd',
          borderRadius: 1,
          mt: '182px',
          boxShadow: 3,
        }}
      >
        {sidebarContent}
      </Box>
    </Box>
  );
}