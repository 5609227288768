
import React, { useState, useEffect } from 'react';
import { Box, IconButton, Grid, Typography, ToggleButtonGroup, ToggleButton, Switch, Card, CardContent, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { styled, useTheme, createTheme, ThemeProvider } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { useSelector, useDispatch } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import clsx from 'clsx';
import swal from 'sweetalert';
import Checkbox from '@mui/material/Checkbox';

import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-python';
import 'ace-builds/src-noconflict/theme-monokai';
import 'ace-builds/src-noconflict/ext-language_tools';
import 'ace-builds/src-noconflict/snippets/python';
import adminServices from '../../services/adminServices';
import codeValidation from '../../utils/codeValidation';
import LottieLoading from '../../components/LottieLoading';
import LottieAnimation from '../../components/LottieAnimation';
import lottieData from '../../assets/lottie/skillsetIqThinking.json';
import Page from '../../components/Page';
import InfoIcon from '../../assets/customIcons/infoScreenIcon.svg';
import palette from '../../theme/palette';
import simulationApi from '../../services/simulation';
import helper from '../../utils/helper';
import greenBgImage from '../../assets/iqImages/greenBgSvg.svg';
import Iconify from '../../components/Iconify';
import iqTest from '../../services/iqTest';
import IqSkillBg from '../../assets/iqImages/iqSkillsetBg.png';
import Rocket from '../../assets/iqImages/rocket.gif';
import IqStart from '../../assets/iqImages/iqStart.png';
import customeKeys from './customeKeys';
import { setKeyIntelligence } from '../../store/reducer';



const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    marginTop: 10,
    marginBottom: 10,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.mode === 'light' ? theme.palette.primary.main : '#308fe8',
    },
}));

const GeneralAssessment = () => {
    const classes = useStyles();
    const theme = useTheme();
    const navigate = useNavigate();
    const location = useLocation();

    const lottieThinking = true;
    const [activeStep, setActiveStep] = useState(0);
    const [screens, setScreens] = useState([]);
    const [activeKeyIndex, setActiveKeyIndex] = useState(0);
    const [searchParams] = useSearchParams();
    const [iqData, setIqData] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isWrongKeyPressed, setIsWrongKeyPressed] = useState(false);
    const [isRightKeyPressed, setIsRightKeyPressed] = useState(false);
    const [isFinished, setIsFinished] = useState(false);
    const [answerCount, setAnswerCount] = useState(0);
    const [wrongCount, setWrongCount] = useState(0);
    const [started, setStarted] = useState(false);
    const [iqTestId, setIqTestId] = useState(null);
    const [userInputKeys, setUserInputKeys] = useState([]);
    const [selectedOption, setSelectedOption] = useState([]);
    const [answerMessage, setAnswerMessage] = useState(null);
    const [showSubmitButton, setShowSubmitButton] = useState(true);
    const [showNextButton, setShowNextButton] = useState(false);
    const [correctAnswerForCurrentQuestion, setCorrectAnswerForCurrentQuestion] = useState(null);

    const [timeLeft, setTimeLeft] = useState(120); // 2 minutes timer
    const [answeredQuestions, setAnsweredQuestions] = useState({});
    const [timerRunning, setTimerRunning] = useState(true);

    const [selectedKeyBoard, setSelectedKeyBoard] = useState('windows');
    const queryParam = searchParams.get('courseId') || searchParams.get('subModuleId');
    const userRole = useSelector((state) => state.userInfo && state.userInfo.role);
    const dispatch = useDispatch();
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const [isNewAssessment, setIsNewAssessment] = useState(false)
    const [questions, setQuestions] = useState('')
    const [questionModule, setQuestionModule] = useState(true);
    const [questioLiest, setQuestioLiest] = useState([]);

    // this is for both keybased and codebased
    const handleNext = () => {
        setActiveKeyIndex(0);
        setIsLoading(true);
        if (activeStep === screens.length - 1) {
            setIsLoading(false);
            setIsFinished(true)

        } else {

            setActiveStep((prevActiveStep) => {
                const updatedActiveStep = prevActiveStep + 1;
                return updatedActiveStep;
            });

            setIsLoading(false);
        }
    };
    const getAssessment = async () => {
        setIsLoading(true);
        try {
            const getResult = await adminServices.getAllGeneralAssessment1();
            const data = getResult.data;
            handleQuestionOpen(data)
            // setScreens(data[9].questionsDetails);
            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
            console.error('Error fetching assessments:', error);
        }
    };
    useEffect(() => {
        getAssessment();
    }, []);

    const handleQuestionOpen = (data) => {
        setQuestioLiest(data)
        setQuestionModule(true)
    }

    const handleQuestionClick = (data, index) => {
        setIqData(data.name) 
        setQuestionModule(false)
        setScreens(data?.questionsDetails);
    }
    const handleBackButtonClick = () => {
        setWrongCount(0)
        setAnswerCount(0)
        setIsFinished(false)
        setActiveStep(0)
        setScreens([])
        setShowSubmitButton(true);
        setShowNextButton(false);
        setAnswerMessage(null)
        setSelectedOption([]);
        setIsSubmitted(false);
        setIsHovered(false)
        setQuestionModule(true)
        // setScreens(null);
    }

    const ActionScreen = () => {

        const screenInfo = screens.length > 0 ? screens[activeStep < screens.length ? activeStep : 0] : null;
        console.log("screenInfo....>", screenInfo)

        let keys = null;

        const keyType = screenInfo?.keys?.mac?.keyCode.length === "0" ? "INDIVIDUAL_HOT_KEYS" : "COMBINED_HOT_KEYS";

        // setting up keyBoard here WINDOWS || mac
        const keyBoard = selectedKeyBoard.toLowerCase();

        // if screens exist extracting keyObj into keys
        if (screenInfo) {
            keys = screenInfo.keys;
            // console.log("screenInfo.keys")
        }

        // adding and removing keyboard event listener
        useEffect(() => {
            window.addEventListener('keydown', handleKeyDown);
            window.addEventListener('keyup', handleKeyUp);

            // removing EventListener when comp unmount
            return () => {
                window.removeEventListener('keydown', handleKeyDown);
                window.removeEventListener('keyup', handleKeyUp);
            };
        }, []);

        // remove event listener if skilltest is completes
        useEffect(() => {
            if (isFinished || isWrongKeyPressed || isRightKeyPressed) {
                window.removeEventListener('keydown', handleKeyDown);
                window.removeEventListener('keyup', handleKeyUp);
            }
            else {
                window.addEventListener('keydown', handleKeyDown);
                window.addEventListener('keyup', handleKeyUp);
            }
        }, [isFinished, isWrongKeyPressed, isRightKeyPressed]);

        // resetting to focus on first key is keyup not completing
        const handleKeyUp = (event) => {
            event.preventDefault();
            if (keyType === 'COMBINED_HOT_KEYS') {
                // Add validation checks for keys[0] and keyBoard
                if (keys && keys[0] && keys[0][keyBoard] && keys[0][keyBoard].keyCode) {
                    const keyArray = keys[0][keyBoard].keyCode;

                    // Check if the key pressed is correct
                    if (event.keyCode.toString() !== keyArray[activeKeyIndex]) {
                        if (isWrongKeyPressed) {
                            setActiveKeyIndex(activeKeyIndex);
                        } else {
                            setActiveKeyIndex(0);
                        }
                    }
                } else {
                    console.error('keyArray is undefined. Ensure keys and keyBoard are correctly initialized.');
                }
            }
        };

        const multipleKeyCode = customeKeys.multipleKeyCode;
        const codeAndNameDifferent = customeKeys.codeAndNameDifferent;

        const handleKeyDown = (event) => {
            event.preventDefault();

            if (!started) {
                startIQTest(event);
                return false;
            }

            setIsWrongKeyPressed(false);

            if (event.repeat) {

                return false;
            }

            const selectedKey = multipleKeyCode.filter((item) => item.key === event.key.toString());
            const isMultipleKey = selectedKey.length > 0 && selectedKey[0].keyCode.includes(event.keyCode);
            const sameCodeForMultipleKeys = customeKeys.sameCodeForMultipleKeys;
            const ignoreNameCheckKeys = customeKeys.ignoreNameCheckKeys;
            let checkOnlyName = false;
            let checkOnlyCode = true;
            let checkCodeAndName = false;
            let checkCodeorName = false;
            let nameAndKeyValidated = false;

            if (sameCodeForMultipleKeys?.includes(event.keyCode.toString())) {
                checkCodeAndName = true;
                checkOnlyName = false;
                checkOnlyCode = false;
            }

            if (isMultipleKey) {
                checkOnlyName = true;
                checkOnlyCode = false;
                checkCodeAndName = false;
            }

            if (keyType === "INDIVIDUAL_HOT_KEYS") {
                const isKeyHasDifferentName = ignoreNameCheckKeys.filter(
                    (item) => item.key === event.key.toString()
                );
                const differentKeyName =
                    isKeyHasDifferentName.length > 0 &&
                    isKeyHasDifferentName[0].keyCode?.includes(keys[activeKeyIndex][keyBoard].keyCode[0]);

                if (differentKeyName && isMultipleKey) {
                    nameAndKeyValidated = true;
                }

                const isKeyName =
                    event.key.toString().toLowerCase() === keys[keyBoard].keyName[0].toLowerCase();
                const isKeyCode =
                    event.keyCode.toString() === keys[keyBoard].keyCode[0].toString();
                if (
                    (checkOnlyCode && !checkOnlyName && !checkCodeAndName && isKeyCode) ||
                    (checkCodeAndName && !checkOnlyName && !checkOnlyCode && isKeyCode && isKeyName) ||
                    (checkOnlyName && !checkOnlyCode && !checkCodeAndName && isKeyName) ||
                    nameAndKeyValidated
                ) {
                    setAnswerCount((prevCount) => prevCount + 1);
                    // Correct key is pressed, now check if it's the last key
                    if (activeKeyIndex === keys.length - 1) {
                        setAnswerCount((prevCount) => prevCount + 1);
                        // Last key of the current step, move to the next question
                        updateUserInputKey(isMultipleKey ? keys[activeKeyIndex][keyBoard].keyCode[0] : event.keyCode.toString());

                        // Move to the next step or screen
                        if (activeStep === screens.length - 1) {
                            setAnswerCount((prevCount) => prevCount + 1);
                            postResult(screenInfo);
                            setIsRightKeyPressed(true);
                            toggleClassName();  // Mark as right key pressed
                            // Handle the case when this is the last screen (end of test)
                        } else {
                            setAnswerCount((prevCount) => prevCount + 1);
                            postResult(screenInfo);
                            setActiveKeyIndex(0);  // Reset key index for the next question
                            setIsRightKeyPressed(true);
                            toggleClassName();
                            // Proceed to the next screen/question
                        }
                    } else {
                        // Not the last key, increment the active key index to move to the next key
                        updateUserInputKey(isMultipleKey ? keys[activeKeyIndex][keyBoard].keyCode[0] : event.keyCode.toString());
                        setActiveKeyIndex(activeKeyIndex + 1);
                        setIsRightKeyPressed(true);
                        toggleClassName();
                    }
                } else {
                    // Wrong key is pressed
                    updateUserInputKey(event.keyCode.toString());
                    postResult(screenInfo);
                    setIsWrongKeyPressed(true);
                    toggleClassName();
                    setWrongCount((prevCount) => prevCount + 1);
                }
            }

            if (keyType === 'COMBINED_HOT_KEYS' && !screens[activeStep]?.question_type==="MCQ") {
                const keyArray = keys[keyBoard]?.keyCode;
                const keyArrayName = keys[keyBoard]?.keyName;
                // custome condition to zoom in and out for XL sheet
                let isPlusOrMinus = false;
                if (event.ctrlKey && event.altKey && event.keyCode === 187) {
                    isPlusOrMinus = true;
                } else if (event.altKey && event.metaKey && event.keyCode === 189) {
                    isPlusOrMinus = true;
                } else if (event.altKey && event.metaKey && event.keyCode === 187) {
                    isPlusOrMinus = true;
                } else {
                    isPlusOrMinus = false;
                }

                const isKeyHasDifferantName = ignoreNameCheckKeys.filter((item) => item.key === event.key.toString());

                const differentKeyName =
                    isKeyHasDifferantName.length > 0 && isKeyHasDifferantName[0].keyCode?.includes(keyArray[activeKeyIndex]);

                if (differentKeyName && isMultipleKey) {
                    nameAndKeyValidated = true;
                }

                // ignore name check in some of the case if alt key is pressed getting keyName as Dead insted actual key Name
                if (event.altKey || event.key.toString().toLowerCase() === 'dead' || event.shiftKey) {
                    checkOnlyCode = true;
                    checkOnlyName = false;
                    checkCodeAndName = false;
                }
                if (event.altKey && isMultipleKey) {
                    checkCodeorName = true;
                }

                const isKeyName = event.key.toString().toLowerCase() === keyArrayName[activeKeyIndex].toLowerCase();
                const isKeyCode = event.keyCode.toString() === keyArray[activeKeyIndex];

                if (
                    (checkOnlyCode &&
                        checkOnlyName === false &&
                        checkCodeAndName === false &&
                        (isKeyCode || codeAndNameDifferent[keyArray[activeKeyIndex]] === event.keyCode)) ||
                    (checkCodeAndName && checkOnlyName === false && checkOnlyCode === false && isKeyCode && isKeyName) ||
                    (checkOnlyName && checkOnlyCode === false && checkCodeAndName === false && isKeyName) ||
                    (checkCodeorName && (isKeyCode || isKeyName)) ||
                    nameAndKeyValidated
                ) {

                    updateUserInputKey(isMultipleKey ? keyArray[activeKeyIndex] : event.keyCode.toString());
                    if (activeKeyIndex === keyArray.length - 1) {
                        // console.log('last key press from Combined Screen');
                        setAnswerCount((prevCount) => prevCount + 1);
                        postResult(screenInfo);

                        // this statement is to check for last screen and moving to next topic
                        if (activeStep === screens.length - 1) {
                            setIsRightKeyPressed(true);
                            toggleClassName();

                        } else {
                            // console.log('can got to next screen from Combined Screen');
                            setIsRightKeyPressed(true);
                            toggleClassName();
                            setActiveKeyIndex(0);
                        }
                    } else {
                        // console.log(`Match Key Index ${keyArray[activeKeyIndex]} and key`);
                        setActiveKeyIndex(activeKeyIndex + 1);
                    }
                } else if (event.shiftKey && event.keyCode === 16) {
                    // console.log('Shift key pressed');
                    updateUserInputKey(event.keyCode.toString());
                    // Log the shift key press
                    postResult(screenInfo);
                    // Post the result (assuming you want to log this interaction)
                    setIsWrongKeyPressed(true);
                    toggleClassName();
                    setWrongCount((prevCount) => prevCount + 1);
                    return false;
                } else {
                    updateUserInputKey(event.keyCode.toString());
                    postResult(screenInfo);
                    setIsWrongKeyPressed(true);
                    toggleClassName();
                    setWrongCount((prevCount) => prevCount + 1);
                }
            }
        };

        const updateUserInputKey = (userKeyCode) => {
            const updatingKeys = userInputKeys;
            updatingKeys[activeKeyIndex] = userKeyCode;

            setUserInputKeys(updatingKeys);
        };

        const toggleClassName = () => {

            setTimeout(() => {
                if (keyType === 'COMBINED_HOT_KEYS') {
                    setActiveKeyIndex(0);
                }
                if (activeStep === screens.length - 1) {
                    const payload = {
                        screenId: screens[activeStep]?.id,
                        iqTestId,
                        actionType: 'CLICK',
                        mcqData: screenInfo?.mcqData,
                        correctAnswer: screenInfo?.mcqData?.correctAnswer,
                        answer: selectedOption,
                        courseType: location.state.type,
                        isNewAssessment: true
                    };

                    if (searchParams.get('courseId')) {
                        iqTest.postCourseAnswer(payload)
                            .then((res) => {
                                console.log('res ok', res)
                            })
                            .catch((error) => {
                                console.log('error on posting answers');
                            });
                    } else if (screens?.length === activeStep + 1) {
                        iqTest.postSubModuleAnswer(payload)
                            .then((res) => {
                                console.log('res ok', res)
                            })
                            .catch((error) => {
                                console.log('error on posting answers');
                            });
                    }
                    setIsFinished(true);
                }
                setIsWrongKeyPressed(false);
                setIsRightKeyPressed(false);

                handleNext();
            }, 2000);
        };


        const postResult = (screen) => {
            if (userRole === 'SUPER_ADMIN' || userRole === 'CONTENT_WRITER') {
                return false;
            }
            let payload
            const userKeyCode = [...userInputKeys];
            const individualMultiKeys = screen.keyType === 'INDIVIDUAL_HOT_KEYS' && screen.keyObj.length > 0;
            setUserInputKeys([]);

            if (screen.keyObj) {
                const selectedKeyId = screen.isMutipleChoice ? screen.keyObj[0].id : null;

                if (screen.mcqData && Object.keys(screen.mcqData).length > 0) {

                    payload = {
                        screenId: screen.id,
                        iqTestId,
                        actionType: screens[activeStep]?.keyType,
                        keyboard: keyBoard.toUpperCase(),
                        keyObj: [{
                            id: selectedKeyId,
                            keyCode: [selectedOption],
                        }],
                        mcqOptions: {
                            selectedOption,
                        },
                        correctAnswer: screenInfo?.mcqData?.correctAnswer,
                        courseType: location.state.type
                    };
                } else {
                    // If not an MCQ question, proceed with key-based answers
                    payload = {
                        screenId: screen.id,
                        iqTestId,
                        actionType: screens[activeStep]?.keyType,
                        keyboard: keyBoard.toUpperCase(),
                        keyObj: screen.keyObj.map((keyItems, index) => {
                            return {
                                id: keyItems.id,
                                keyCode: individualMultiKeys ? [userKeyCode[index]] || [userKeyCode[0]] : userKeyCode,
                            };
                        }),
                        courseType: location.state.type
                    };
                }

            }
            else {
                payload = {
                    screenId: screen.id,
                    iqTestId,
                    actionType: 'CLICK',
                    mcqData: screenInfo?.mcqData,
                    correctAnswer: screenInfo?.mcqData?.correctAnswer,
                    answer: selectedOption,
                    courseType: location.state?.type,
                    isNewAssessment: true
                };
            }
            if (searchParams.get('courseId')) {
                iqTest.postCourseAnswer(payload)
                    .then((res) => {
                        console.log('res ok', res)
                    })
                    .catch((error) => {
                        console.log('error on posting answers');
                    });
            } else if (screens?.length === activeStep + 1) {
                iqTest.postSubModuleAnswer(payload)
                    .then((res) => {
                        console.log('res ok', res)
                    })
                    .catch((error) => {
                        console.log('error on posting answers');
                    });
            }
        };




        const handleRadioChange = (selectedOption) => {
            setSelectedOption(selectedOption);
        };

        const handleMCQSubmit = () => {
            if (!selectedOption || selectedOption.length === 0) {
                window.alert("Please choose an option");
                return;
            }
            const correctAnswer = screenInfo?.options?.correctAnswer;
            const correctAnswerIndices = correctAnswer
                .map((item, index) => (item ? index : -1))
                .filter(index => index !== -1);

            const correctAnswerLabel = correctAnswerIndices
                .map(index => String.fromCharCode(65 + index));
            const isMultipleCorrect = correctAnswerIndices.length > 1;
            const selectedOptionIndices = Array.isArray(selectedOption)
                ? selectedOption.map(option => screenInfo.options.mcqOptions.indexOf(option))
                : [screenInfo.options.mcqOptions.indexOf(selectedOption)];
            const isCorrectLength = selectedOptionIndices.length === correctAnswerIndices.length;
            const allCorrectSelected = selectedOptionIndices.every(index =>
                correctAnswerIndices?.includes(index)
            );

            const isCorrectAnswer = isCorrectLength && allCorrectSelected;
            if (isCorrectAnswer) {
                setAnswerMessage('Correct answer!');
                setAnswerCount((prevCount) => prevCount + 1);
            } else {
                setAnswerMessage(`Wrong answer! The correct answer is: ${correctAnswerLabel}`);
                setWrongCount((prevCount) => prevCount + 1);
            }

            setShowSubmitButton(false);
            setShowNextButton(true);
            setCorrectAnswerForCurrentQuestion(null);
            setIsSubmitted(true);
            setIsHovered(false);
        };

        const handleMCQNext = (screen) => {
            if (activeStep === screens.length - 1) {
                postResult(screen);
                setIsFinished(true)
            } else {
                handleNext();
                postResult(screen);
                setShowSubmitButton(true);
                setShowNextButton(false);
                setAnswerMessage(null)
                setSelectedOption([]);
                setIsSubmitted(false);
                setIsHovered(false)
            }

        }


        const startIQTest = (eventDump) => {
            const startKeys = ['32'];


            if (userRole === 'SUPER_ADMIN' || userRole === 'CONTENT_WRITER') {
                if (eventDump.keyCode.toString() === startKeys[activeKeyIndex]) {
                    setStarted(true);
                    return false;
                }
            }

            if (eventDump.keyCode.toString() === startKeys[activeKeyIndex]) {
                setStarted(true);
                setIsLoading(true);
                let parsedId;
                try {
                    parsedId = parseInt(queryParam, 10);
                } catch (error) {
                    console.log("message", error)
                }
                const startPayload = {
                    requiredType: searchParams.get('courseId') ? 'COURSE' : 'SUBMODULE',
                    id: parsedId,
                };

                iqTest
                    .startIQ(startPayload)
                    .then((res) => {
                        console.log(res);
                        if (res.ok) {
                            setIqTestId(res.data.id);
                            console.log("message for startiq res.data", res.data);
                        }
                        setIsLoading(false);
                    })
                    .catch((error) => console.log('error on start IQ API'));
            }
        };
        let height;
        if (screenInfo?.options !== null) {
            height = "280px";
        } else if (screenInfo?.question_type === "IQ") {
            height = "0px";
        } else {
            height = "200px";
        }
        const generateRadioMCQLabels = (num) => {
            return Array.from({ length: num }, (_, i) => String.fromCharCode(65 + i));
        };
        const labels = generateRadioMCQLabels(screenInfo?.options?.mcqOptions?.length || 0); // Generate labels based on the number of options

        const getOptionColor = (mcqOption, index) => {
            if (typeof mcqOption !== 'string') {
                return {
                    backgroundColor: 'white',
                    borderColor: 'grey',
                    textColor: 'black',
                };
            }
            const delectedCleanUp = typeof selectedOption === 'string' ? selectedOption.replace(/<\/?[^>]+(>|$)/g, "").trim() : "";
            const cleanOption = mcqOption.replace(/<\/?[^>]+(>|$)/g, "").trim();
            const correctAnswer = screenInfo?.options?.correctAnswer || [];
            const isCorrect = correctAnswer[index];
            const isSelected = selectedOption?.includes(mcqOption);
            if (!isSubmitted) {
                return {
                    backgroundColor: isSelected ? '#4CAF50' : 'white',
                    borderColor: 'grey',
                    textColor: 'black',
                };
            }
            if (isCorrect) {
                return {
                    borderColor: '#00B818',
                    textColor: '#00B818',
                };
            }
            if (isSelected && !isCorrect) {
                return {
                    borderColor: '#EE3138',
                    textColor: '#EE3138',
                };
            }
            return {
                backgroundColor: 'white',
                borderColor: 'grey',
                textColor: 'black',
            };
        };
        const optionColor = getOptionColor(screenInfo?.mcqData?.mcqOption);
        const radioButtonStyles = (optionColor) => ({
            appearance: 'none',
            width: '23px',
            height: '23px',
            borderRadius: '50%',
            border: `1.5px solid ${optionColor.borderColor}`,
            position: 'relative',
            display: 'inline-block',
            alignItems: 'center',
            backgroundColor: optionColor.backgroundColor,
            color: optionColor.textColor,
        });


        const handleAnswer = (questionId) => {
            setAnsweredQuestions({
                ...answeredQuestions,
                [questionId]: true,
            });
        };
        const handleMultipleSelections = (option, index) => {
            handleAnswer(index);
            if (selectedOption?.includes(option)) {
                setSelectedOption(selectedOption.filter(selected => selected !== option));
            } else {
                setSelectedOption([...selectedOption, option]);
            }

        };
        const handleSingleSelection = (option) => {
            setSelectedOption(option);
        };

        const isTimeUp = timeLeft === 0;

        const blinkStyle = {
            animation: timeLeft < 30 ? 'blink 1s step-start infinite' : 'none',
            color: timeLeft < 30 ? 'red' : 'green',
            marginTop: '20px',
            textAlign: 'center',
        };

        return (
            <Box
                className={classes.gridContainer}
                justifyContent="center"
                alignItems="flex-start"
                sx={{
                    backgroundImage: `url(${greenBgImage})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'auto',
                }}
            >
                <Box className={classes.boxContainer}>
                    <Grid container alignItems="center" className={classes.header}>
                        <Grid item>
                            <IconButton
                                size="small"
                                onClick={() => { handleBackButtonClick() }}
                                sx={{ marginLeft: '-8px !important' }}
                            >
                                <ArrowBackIcon color="#fff" fontSize="small" />
                            </IconButton>
                        </Grid>
                        <Grid item>
                            <Typography variant="subtitle1" display={'flex'} alignItems="center">
                                {iqData}
                            </Typography>
                        </Grid>
                    </Grid>
                    {!started ? (
                        <Box>
                            <Grid xs={12} sm={10} md={8} lg={12} xl={12}>
                                <Box className={classes.iqStartModel}>
                                    <img src={Rocket} alt="loading" width={60} style={{ margin: 'auto' }} />
                                    <Typography variant="h6" gutterBottom>
                                        {iqData}
                                    </Typography>
                                    <Typography variant="body1" className={classes.boxDesc}>
                                        Know how much you've learned with skillset IQ. Take your own time. You can always retake it to improve
                                        your score.
                                    </Typography>
                                </Box>
                                <img src={IqStart} alt="iqStart" className={classes.iqStart} />
                                <Box mt={4}>
                                    <Typography variant="h5" align="center" color={'primary'} gutterBottom>
                                        Let's begin!
                                    </Typography>
                                    <Typography className={classes.btnKey}>Space</Typography>
                                    <Typography align="center">click on spacebar on your keyboard to start</Typography>
                                </Box>
                            </Grid>
                        </Box>
                    ) : (
                        <Box className={classes.iqModel}>


                            {!isFinished && (
                                <Box>
                                    <Box
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'flex-start',
                                            whiteSpace: 'pre-wrap',
                                            // marginBottom: '20px',
                                        }}
                                    >
                                        {screenInfo?.is_active ? (
                                            <strong style={{ fontSize: '18px', textAlign: 'left', marginLeft: '20px', fontFamily: 'Poppins', fontWeight: '600' }}>
                                                <span dangerouslySetInnerHTML={{ __html: screenInfo?.question_text }} />
                                            </strong>
                                        ) : (
                                            <strong style={{ fontSize: '18px', textAlign: 'left', marginLeft: '20px', fontFamily: 'Poppins' }}>
                                                <span dangerouslySetInnerHTML={{ __html: screenInfo?.iqQuestion }} />
                                            </strong>
                                        )}
                                    </Box>
                                    <Grid item style={{ marginTop: '20px', height, overflow: 'auto' }}>
                                        {screenInfo?.options?.mcqOptions?.map((mcqOption, index) => {
                                            const optionColor = getOptionColor(mcqOption, index);
                                            const isDisabled = !timerRunning || answeredQuestions[index];
                                            const correctAnswer = screenInfo?.options?.correctAnswer || [];
                                            const isMultipleCorrect = correctAnswer.filter(answer => answer).length > 1;
                                            return (
                                                <div key={index} style={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}>
                                                    <div style={{ position: 'relative', display: 'inline-block', marginLeft: '20px', marginBottom: isMultipleCorrect ? "8px" : "0px" }}>
                                                        {isMultipleCorrect ? (
                                                            // Checkbox for multiple correct answers
                                                            <Checkbox
                                                                type="checkbox"
                                                                id={`mcqOption_${index}`}
                                                                name="mcqOption"
                                                                value={mcqOption}
                                                                disabled={isSubmitted || (!showSubmitButton && isDisabled)}
                                                                checked={Array.isArray(selectedOption) && selectedOption.includes(mcqOption)}
                                                                onChange={() => handleMultipleSelections(mcqOption, index)}
                                                            />
                                                        ) : (
                                                            // Radio button for single correct answer
                                                            <input
                                                                type="radio"
                                                                id={`mcqOption_${index}`}
                                                                name="mcqOption"
                                                                value={mcqOption}
                                                                disabled={isSubmitted || (!showSubmitButton && isDisabled)}
                                                                // checked={selectedOption === mcqOption}
                                                                onChange={() => handleSingleSelection(mcqOption)}
                                                                style={{
                                                                    ...radioButtonStyles(optionColor),
                                                                    cursor: 'pointer',
                                                                    opacity: isDisabled ? 0.5 : 1,
                                                                }}
                                                            />
                                                        )}
                                                        <span
                                                            className={isMultipleCorrect ? classes.checkBocLabelStyle : classes.radioLabelStyle}
                                                            style={{
                                                                color: optionColor.textColor,
                                                                cursor: isDisabled ? 'not-allowed' : 'pointer',
                                                            }}
                                                        >
                                                            {labels[index]}
                                                        </span>
                                                    </div>

                                                    <label
                                                        htmlFor={`mcqOption_${index}`}
                                                        style={{
                                                            marginLeft: '20px',
                                                            fontFamily: 'Poppins',
                                                            fontWeight: '400',
                                                            fontSize: '16px',
                                                            textAlign: 'left',
                                                            marginBottom: '8px',
                                                            flex: 1,
                                                            whiteSpace: 'pre-line',
                                                            color: optionColor.textColor,
                                                        }}>
                                                        {mcqOption.split('\n').map((line, i) => (
                                                            <React.Fragment key={i}>
                                                                {/* {line} */}
                                                                <span dangerouslySetInnerHTML={{ __html: line }} />
                                                                {/* <br /> */}
                                                            </React.Fragment>
                                                        ))}
                                                    </label>
                                                </div>
                                            )
                                        })}
                                    </Grid>
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "center", alignItems: 'center' }}>
                                        {isWrongKeyPressed ? (
                                            <Iconify
                                                icon={'ant-design:close-circle-outlined'}
                                                width={52}
                                                height={52}
                                                sx={{ color: '#FF0000', margin: '16px 0px !important' }}
                                                className={[isWrongKeyPressed === true && classes.keyWrong]}
                                            />
                                        ) : null}
                                        {isRightKeyPressed ? (
                                            <Iconify
                                                icon={'bi:check-circle'}
                                                width={52}
                                                height={52}
                                                sx={{ color: '#00B673', margin: '16px 0px !important' }}
                                                className={[isRightKeyPressed === true && classes.keyCorrect]}
                                            />
                                        ) : null}
                                        {isWrongKeyPressed || isRightKeyPressed ? (
                                            <div className={classes.keyName}>
                                                {keyType === 'INDIVIDUAL_HOT_KEYS' &&
                                                    keys &&
                                                    keys[keyBoard] && // Ensure the platform (mac/windows) exists in keys
                                                    keys[keyBoard].keyCode.map((keyCode, index) => (
                                                        <Typography
                                                            key={index}
                                                            className={classes.activeKey}
                                                            style={{ marginRight: 10 }}
                                                        >
                                                            {/* Display the arrow icon or the key name */}
                                                            {customeKeys.arrowIcons[keyCode.toString()]
                                                                ? customeKeys.arrowIcons[keyCode.toString()]
                                                                : keys[keyBoard].keyName[index]} {/* Get keyName based on index */}
                                                        </Typography>
                                                    ))
                                                }

                                                {keyType === 'COMBINED_HOT_KEYS' &&
                                                    keys &&
                                                    keys[keyBoard] &&
                                                    keys[keyBoard].keyName.map((item, index, oriArray) => (
                                                        <React.Fragment key={index}>
                                                            <Typography
                                                                className={classes.activeKey}
                                                                style={{ marginRight: 10 }}
                                                            >
                                                                {customeKeys.arrowIcons[keys[keyBoard].keyCode[index].toString()]
                                                                    ? customeKeys.arrowIcons[keys[keyBoard].keyCode[index].toString()]
                                                                    : item}
                                                            </Typography>
                                                            {index < oriArray.length - 1 && (
                                                                <Typography style={{ fontSize: '1.5rem', color: '#5d5d5d' }}>+</Typography>
                                                            )}
                                                        </React.Fragment>
                                                    ))
                                                }
                                            </div>
                                        ) : null}
                                    </div>
                                    {started && (
                                        <Box p={10} m={2}>
                                            <BorderLinearProgress
                                                color="primary"
                                                variant="determinate"
                                                value={isFinished ? 100 : (100 / screens.length) * activeStep}
                                            />
                                        </Box>
                                    )}
                                    <Grid container spacing={3}>
                                        <Grid item xs={2} >
                                            {screenInfo?.question_type === "MCQ" && showSubmitButton && (
                                                <button
                                                    onClick={() => {
                                                        handleMCQSubmit();

                                                    }}
                                                    onMouseEnter={() => setIsHovered(true)}
                                                    onMouseLeave={() => setIsHovered(false)}
                                                    type="submit"
                                                    style={{
                                                        width: '69px',
                                                        height: '29px',
                                                        border: 'none',
                                                        outline: 'none',
                                                        color: isHovered ? 'white' : '#000000',
                                                        borderRadius: '5px',
                                                        textAlign: 'center',
                                                        boxShadow: '0 6px 20px -5px rgba(0,0,0,0.4)',
                                                        position: 'relative',
                                                        overflow: 'hidden',
                                                        cursor: isTimeUp ? 'not-allowed' : 'pointer',
                                                        fontSize: '16px',
                                                        fontFamily: 'Poppins',
                                                        marginRight: '30px',
                                                        backgroundColor: isHovered ? 'rgb(236,121,48)' : 'initial',
                                                        transition: 'background-color 0.3s, color 0.3s',
                                                    }}
                                                    disabled={isTimeUp}
                                                >
                                                    Submit
                                                </button>
                                            )}
                                        </Grid>

                                        {/* Feedback Messages */}
                                        <Grid item xs={8}>
                                            {answerMessage && answerMessage.startsWith('Correct') && (
                                                <span style={{ color: '#00B818', fontWeight: '700' }}>
                                                    {answerMessage.split(': ')[0]}
                                                </span>
                                            )}
                                            {answerMessage && answerMessage.startsWith('Wrong') && (
                                                <span style={{ color: '#EE3138', fontWeight: '700' }}>
                                                    {answerMessage.split('! ')[0]}!<br />
                                                    <span style={{ color: '#00B818', fontWeight: '400' }}>
                                                        {answerMessage.split('! ')[1]} {/* This part will show the correct answers */}
                                                    </span>
                                                </span>
                                            )}
                                        </Grid>

                                        {/* Next Button */}
                                        <Grid item xs={2}>
                                            {screenInfo?.question_type === "MCQ" && showNextButton && !isTimeUp && (
                                                <button
                                                    onClick={() => handleMCQNext(screenInfo)}
                                                    onMouseEnter={() => setIsHovered(true)}
                                                    onMouseLeave={() => setIsHovered(false)}
                                                    type="submit"
                                                    style={{
                                                        width: '69px',
                                                        height: '29px',
                                                        border: 'none',
                                                        outline: 'none',
                                                        backgroundColor: isHovered ? 'rgb(236,121,48)' : 'initial',
                                                        transition: 'background-color 0.3s, color 0.3s',
                                                        color: isHovered ? 'white' : '#000000',
                                                        borderRadius: '5px',
                                                        textAlign: 'center',
                                                        boxShadow: '0 6px 20px -5px rgba(0,0,0,0.4)',
                                                        position: 'relative',
                                                        overflow: 'hidden',
                                                        cursor: isTimeUp ? 'not-allowed' : 'pointer',
                                                        fontSize: '16px',
                                                        fontFamily: 'Poppins',
                                                    }}
                                                    disabled={isTimeUp}
                                                >
                                                    Next
                                                </button>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Box>
                            )}
                        </Box>
                    )}

                    {isFinished && (
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column"
                            }}
                        >
                            <Typography variant="h4" gutterBottom sx={{ lineHeight: 2, marginBottom: 2 }}>
                                Congratulations!. <br />
                                Your skillSet IQ Challenge is completed
                            </Typography>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    if (userRole === 'AUTH_USER') {
                                        handleBackButtonClick();
                                    } else if (userRole === 'SUPER_ADMIN' || userRole === 'CONTENT_WRITER') {
                                        handleBackButtonClick();
                                    } else {
                                        handleBackButtonClick();
                                    }
                                }}
                            >
                                Back To Course
                            </Button>
                        </Box>
                    )}
                </Box>
            </Box>
        );
    };
    return (
        <Page title={'Begin Course'} style={{ padding: '0px', paddingTop: '70px !important' }}>
        {!questionModule ? (
            <Grid container>
                <Grid item xs={12} sm={9}>
                    <div style={{ position: 'relative' }} id="simulationSec">
                        {isLoading ? (
                            <div
                                style={{
                                    position: 'absolute',
                                    width: '100%',
                                    height: `calc(100vh - 70px)`,
                                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                                    display: 'flex',
                                    flexFlow: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    zIndex: 999,
                                }}
                            >
                                <LottieLoading loading={isLoading} />
                            </div>
                        ) : (
                            <>
                                {!questionModule && screens?.length === 0 && (
                                    <>
                                        <div className={classes.header}>
                                            <Typography variant="subtitle1" display={'flex'} alignItems="center">
                                                <IconButton
                                                    size="small"
                                                    onClick={() => {
                                                        if (Object.keys(location.state).length > 1) {
                                                            const route = userRole === 'AUTH_USER' ? '/app/course-details' : '/auth/course-details';
                                                            navigate(route, { state: location.state });
                                                        } else {
                                                            navigate(-1);
                                                        }
                                                    }}
                                                    sx={{ marginLeft: '-8px !important' }}
                                                >
                                                    <ArrowBackIcon color="#fff" fontSize="small" />
                                                </IconButton>
                                                {iqData}
                                            </Typography>
                                        </div>
                                        <Typography variant="h6" align="center" sx={{ paddingTop: '2rem' }}>
                                            No Data
                                        </Typography>
                                    </>
                                )}
    
                                {!questionModule && !isLoading && screens.length > 0 && screens[activeStep]?.keyType !== 'CODE' && <ActionScreen />}
    
                                {!questionModule && isFinished && (
                                    <Box p={10} m={2}>
                                        <BorderLinearProgress
                                            color="primary"
                                            variant="determinate"
                                            value={isFinished ? 100 : (100 / screens.length) * activeStep}
                                        />
                                    </Box>
                                )}
                            </>
                        )}
                    </div>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Box
                        className={classes.courseItem}
                        sx={{ height: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                    >
                        {screens[activeStep]?.keyType !== 'CODE' && !screens[activeStep]?.isMutipleChoice && (
                            <Box sx={{ width: '100%', paddingTop: '1rem !important', textAlign: 'center' }}>
                                <ThemeProvider theme={customTheme}>
                                    <ToggleButtonGroup
                                        value={selectedKeyBoard}
                                        exclusive
                                        onChange={(event, selected) => {
                                            if (selected) setSelectedKeyBoard(selected);
                                        }}
                                        aria-label="text alignment"
                                    >
                                        <ToggleButton value="windows" aria-label="left aligned">
                                            <Iconify
                                                icon="mdi:microsoft-windows"
                                                width={24}
                                                height={26}
                                                color={selectedKeyBoard === 'windows' ? palette.primary.main : '#00000029'}
                                            />
                                        </ToggleButton>
                                        <ToggleButton value="mac" aria-label="centered">
                                            <Iconify
                                                icon="ant-design:apple-filled"
                                                width={24}
                                                height={26}
                                                color={selectedKeyBoard === 'mac' ? palette.primary.main : '#00000029'}
                                            />
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                </ThemeProvider>
                                <Typography sx={{ fontSize: '0.65rem' }}>Switch keyboard</Typography>
                            </Box>
                        )}
    
                        {screens[activeStep]?.keyType !== 'CODE' && screens[activeStep]?.isMutipleChoice && (
                            <Box sx={{ width: '100%', paddingTop: '1rem !important', textAlign: 'center' }}>
                                <ThemeProvider theme={customTheme}>
                                    <ToggleButtonGroup
                                        value={selectedOption}
                                        exclusive
                                        onChange={(event, selected) => {
                                            if (selected) setSelectedOption(selected);
                                        }}
                                        aria-label="text alignment"
                                    >
                                        {/* Uncomment if you have options to toggle */}
                                        {/* <ToggleButton value="option1" aria-label="option 1">Option 1</ToggleButton> */}
                                        {/* <ToggleButton value="option2" aria-label="option 2">Option 2</ToggleButton> */}
                                    </ToggleButtonGroup>
                                </ThemeProvider>
                                <Typography sx={{ fontSize: '0.65rem' }}>Switch keyboard</Typography>
                            </Box>
                        )}
    
                        <CircularProgressbar
                            value={screens.length > 0 ? (100 / screens.length) * answerCount : 0}
                            text={screens.length > 0 ? `${((100 / screens.length) * answerCount).toFixed(2)}%` : 0}
                            strokeWidth={12}
                            className={classes.progress}
                            styles={buildStyles({
                                textColor: '#1F78B4',
                                textSize: '16px',
                                pathColor: '#A6CEE3',
                                trailColor: '#1F78B4',
                            })}
                        />
                        <Box m={2} textAlign="center">
                            <Typography gutterBottom>Correct Answers in percentage</Typography>
                            <Typography variant="subtitle1" gutterBottom>
                                {answerCount} Correct Answers
                            </Typography>
                            <Typography variant="subtitle1" gutterBottom>
                                {wrongCount} Incorrect Answers
                            </Typography>
                        </Box>
                        <Box className={classes.totalIqtext}>
                            <Typography align="right">{`Total IQ Question  ${isFinished ? screens.length : activeStep}/${screens.length}`}</Typography>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        ) : (
            // Corrected loading section for the question module
            <Grid container>
                {isLoading ? (
                    <div
                        style={{
                            position: 'absolute',
                            width: '100%',
                            height: `calc(100vh - 70px)`,
                            backgroundColor: 'rgba(255, 255, 255, 0.8)',
                            display: 'flex',
                            flexFlow: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            zIndex: 999,
                        }}
                    >
                        <LottieLoading loading={isLoading} />
                    </div>
                ) : (
                    <Grid container spacing={2}>
                        {questioLiest && questioLiest.map((data, index) => {
                            const totalQuestions = data.questionsDetails.length;
                            const completedQuestions = data.questionsDetails.filter(q => q.completed).length; // Example condition
                            const progress = totalQuestions > 0 ? (completedQuestions / totalQuestions) * 100 : 50;
    
                            return (
                                <Grid item xs={12} sm={6} md={4} key={index}>
                                    <Card
                                        variant="outlined"
                                        style={{ width: '100%', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', transition: 'transform 0.2s' }}
                                        onClick={() => handleQuestionClick(data, index)}
                                        onMouseEnter={(e) => { e.currentTarget.style.transform = 'scale(1.05)'; }}
                                        onMouseLeave={(e) => { e.currentTarget.style.transform = 'scale(1)'; }}
                                    >
                                        <CardContent style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                                            <div style={{ flexGrow: 1, textAlign: 'left' }}>
                                                <Typography variant="h5">{data.name}</Typography>
                                                <Typography variant="body2" color="textSecondary">
                                                    Number of Questions: {totalQuestions}
                                                </Typography>
                                                <Typography variant="body2" color="textSecondary">
                                                    Completed: {completedQuestions}
                                                </Typography>
                                            </div>
                                            <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                                                <CircularProgress
                                                    variant="determinate"
                                                    value={100}
                                                    size={50}
                                                    style={{ position: 'relative', color: 'orange', zIndex: 1 }}
                                                />
                                                <CircularProgress
                                                    variant="determinate"
                                                    value={progress}
                                                    size={50}
                                                    style={{ position: 'absolute', color: 'green', zIndex: 2 }}
                                                />
                                                <Typography variant="body2" style={{
                                                    position: 'absolute',
                                                    left: '50%',
                                                    top: '50%',
                                                    transform: 'translate(-50%, -50%)',
                                                    fontWeight: 'bold',
                                                    color: 'black'
                                                }}>
                                                    {Math.round(progress)}%
                                                </Typography>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            );
                        })}
                    </Grid>
                )}
            </Grid>
        )}
    </Page>
    
    );

};

const useStyles = makeStyles((theme) => ({
    gridContainer: {
        // backgroundImage: `url(${NewXlFile})`,
        // minHeight: `calc(100vh - ${70 + 78}px)`,
        height: "auto",
        backgroundSize: '100%',
        backgroundRepeat: 'no-repeat',
        margin: 'auto',
        display: 'flex',
        padding: 10,
    },


    courseItem: {
        background: '#fff',
        minHeight: `calc(100vh - ${84}px)`,
        borderLeft: '1px solid #BCBCBC',
    },
    progressBox: {
        background: '#FAFAFA',
        borderLeft: 'none',
        border: '1px solid #BCBCBC',
        padding: 10,
    },
    btnKey: {
        background: '#FEFEFF 0% 0% no-repeat padding-box',
        textAlign: 'center',
        borderRadius: '6px',
        boxShadow: '1px 1px 3px #a4a4a4',
        width: 'max-content',
        margin: '1rem auto',
        padding: '8px 16px',
        fontWeight: '600',
    },
    activeKey: {
        boxSizing: 'border-box;',
        // lineHeight: '60px;',
        fontSize: '1.2rem',
        textAlign: 'center;',
        width: '60px',
        minWidth: 'max-content',
        padding: '12px 16px',
        cursor: 'pointer',
        background: '#FEFEFF 0% 0% no-repeat padding-box',
        margin: '0 18px;',
        // height: '40px',
        color: '#090909',
        borderColor: '#f2f2f2;',
        borderStyle: 'solid;',
        textShadow: '0 0.5px 1px #777, 0 2px 6px #f2f2f2;',
        borderWidth: '1px;',
        borderRadius: '6px',
        // background: '-webkit-linear-gradient(top, #f9f9f9 0%, #D2D2D2 80%, #c0c0c0 100%);',
        fontFamily: 'sans-serif;',
        display: 'inline-block;',
        transition: 'box-shadow 0.3s ease, transform 0.15s ease;',
        // boxShadow: '0 0 15px #888, 0 1px 0 #fff, 0 5px 0 #c0c0c063',
        boxShadow: '1px 1px 3px #a4a4a4',
    },

    buttoneight: {
        animation: 'zoominout 1s infinite;',
    },

    keyWrong: {
        // boxShadow: '0 0 12px #e30e0e, 0 1px 0 #fff, 0 5px 0 #c0c0c063',
        animation: 'shake .8s linear',
    },
    keyCorrect: {
        // boxShadow: `0 0 12px ${palette.secondary.main}, 0 1px 0 #fff, 0 5px 0 #c0c0c063`,
        animation: 'zoominout .8s infinite;',
    },
    introContainer: {
        minWidth: '250px',
        maxWidth: '375px',
        padding: 16,
        paddingBottom: 20,
        borderRadius: 8,
        backgroundColor: '#ffffffc4',
        marginRight: '2rem',
        // boxShadow: `4px 4px 8px 2px #a7a7a7`,
        boxShadow: '0px 2px 6px #00000029',
        border: '2px solid #ddd',
    },
    introItem: {
        textAlign: 'center',
    },
    introImg: {
        height: 50,
        textAlign: 'center',
    },
    pythonEditor: {
        position: 'absolute',
        top: '80px',
        width: '80%',
        left: '178px',
    },
    sequelEditor: {
        position: 'absolute',
        top: '58px',
        width: '100%',
    },
    keyName: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    hideLinearProgress: {
        // display: 'none',
    },
    actionDescription: {
        color: palette.common.black,
        fontSize: '1.2rem',
        fontWeight: '500',
    },

    header: {
        position: 'absolute',
        top: '1rem',
        left: '1.5rem',
    },



    boxDesc: {
        borderTop: '1px solid #c3fee95e',
        paddingTop: '0.8rem',
        marginTop: '0.8rem',
        fontSize: '0.83rem',
    },

    iqStartModel: {
        margin: 'auto',
        marginTop: '4rem',
        maxWidth: '380px',
        textAlign: 'center',
        padding: '1rem',
        background: '#fcfcfc',
        borderRadius: '6px',
        border: '1px solid #00B673',
        boxShadow: ' 0px 0px 14px #00000026',
    },
    boxContainer: {
        width: '100%',
        backgroundRepeat: 'no-repeat',
        backgroundPositionX: 'right',
        backgroundPositionY: 'bottom',
        height: 'auto',
    },
    progress: {
        width: '42%',
        marginRight: 'auto',
        marginLeft: 'auto',
        marginTop: '1rem',
    },
    codeContainer: {
        padding: '16px 28px',
    },
    iqModel: {
        textAlign: 'center',
        marginTop: '50px',
    },
    iqStart: {
        position: 'absolute',
        right: '2rem',
        top: '40%',
    },
    totalIqtext: {
        background: "#FE780F",
        width: '201px',
        height: "36px",
        borderRadius: '5px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '30px',
        color: 'white',
    },
    radioButtonStyle: {
        appearance: 'none',
        width: '21px',
        height: '21px',
        borderRadius: '50%',
        border: '2px solid black',
        position: 'relative',
        display: 'inline-block',
        marginRight: '8px',
        backgroundColor: 'white',
    },
    radioLabelStyle: {
        position: 'absolute',
        top: '43%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        fontSize: '15px',
        // fontWeight: 'bold',
        pointerEvents: 'none',
    },
    checkBocLabelStyle: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        fontSize: '15px',
        // fontWeight: 'bold',
        pointerEvents: 'none',
    },
    '@global': {
        '@keyframes zoominout': {
            '0%': {
                transform: 'scale(1)',
            },
            '70%': {
                transform: 'scale(0.9)',
            },
            '100%': {
                transform: 'scale(1)',
            },
        },
        '@keyframes shake': {
            '8%, 41%': {
                transform: 'translateX(-10px)',
            },
            '25%, 58%': {
                transform: 'translateX(10px)',
            },
            '75%': {
                transform: 'translateX(-5px)',
            },
            '92%': {
                transform: 'translateX(5px)',
            },
            '0%, 100%': {
                transform: 'translateX(0)',
            },
        },
    },
}));

const customTheme = createTheme({
    components: {
        MuiToggleButtonGroup: {
            styleOverrides: {
                grouped: {
                    backgroundColor: '#FE700000',
                    color: 'white',
                    fontWeight: 'bold',
                },
            },
        },

        MuiToggleButton: {
            styleOverrides: {
                root: {
                    padding: '0px 6px',
                    '&.Mui-selected': {
                        backgroundColor: '#FFF0E5',
                        color: '#ffffff',
                        '&:hover': {
                            backgroundColor: '#FFF0E5',
                        },
                    },
                    '&:hover': {
                        backgroundColor: '#D1FFEE',
                    },
                },
            },
        },

        MuiButtonBase: {
            styleOverrides: {
                root: {
                    borderRight: '1px solid #FFFFFF',
                },
            },
        },
    },
});

export default GeneralAssessment