import React, { useEffect, useState } from 'react';
import {
    Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, FormControlLabel, Switch, CircularProgress, Grid, FormControl, InputLabel, Select, MenuItem, Typography,
    FormHelperText, Avatar, Badge
} from '@mui/material';
 
import { DropzoneArea } from 'material-ui-dropzone';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
 
import adminServices from '../../../services/adminServices';
 
 
const CustomDialog = ({ open, onClose, refresh, initialData = {}, isEdit = false }) => {
    const [value, setValue] = useState('');
    const [jobTitle, setJobTitle] = useState('');
    const [region, setRegion] = useState('');
    const [offerId, setOfferId] = useState('');
    const [isActive, setIsActive] = useState(false);
    const [couponCode, setCouponCode] = useState('');
    const [paymentLink, setPaymentLink] = useState('');
    const [validationDate, setValidationDate] = useState('');
    const [offersBanner, setOffersBanner] = useState('');
    const [description, setDescription] = useState('');
    const [image, setImage] = useState('');
    const [id, setId] = useState('');
    
    const classes = useStyles();
    const [thumbImage, setThumbImage] = useState(null); 
    const [coverImage, setCoverImage] = useState(null);
    const [thumbPreview, setThumbPreview] = useState(null);
    const [coverImgPreview, setCoverImgPreview] = useState(null);
    const [buttonLoading, setButtonLoading] = useState(false)
    const [courseList, setCourseList] = useState([])
    const [selectedCourse, setSelectedCourse] = useState('');
    const [offer, setOffer] = useState('');

 
    
    const [time, setTime] = useState({
        hours: 0,
        minutes: 0,
        seconds: 0,
    });
 

    const getCourserList = async () => {
        const result = await adminServices.getAllCourseList();
        if (result.ok) {
            setCourseList(result.data)
        }
    };

    useEffect(() => {
        getCourserList();
    }, [])


    useEffect(() => {
        console.log(initialData, "initialData");
 
        if (isEdit) {
            setValue(initialData.value || '');
            setJobTitle(initialData.jobTitle || '');
            setRegion(initialData.region || '');
            setOfferId(initialData.offer_id || '');
            setIsActive(initialData.isActive || false);
            setCouponCode(initialData.coupon_code || '');
            setPaymentLink(initialData.payment_link || '');
            setValidationDate(initialData.validity_date || '');
            setOffersBanner(initialData.value || '');
            setId(initialData.id || '');
            setTime({
                hours: initialData.hour || 0,
                minutes: initialData.minute || 0,
                seconds: initialData.second || 0,
            });
 
        }
    }, [initialData, isEdit]);
 
    const totalSeconds = (time.hours * 3600) + (time.minutes * 60) + time.seconds;
 
    const handleChange = (event) => {
        const { name, value } = event.target;
        setTime((prevTime) => ({
            ...prevTime,
            [name]: Number(value),
        }));
    };
 
    const getButtonText = () => {
        if (buttonLoading) {
            return <CircularProgress size={24} />;
        }
        return isEdit ? 'Update' : 'Create';
    };
    const handleSubmit = async () => {
        setButtonLoading(true);
    
        const { hours, minutes, seconds } = time;
        
        const baseData = {
            id, value, couponCode, paymentLink, validationDate, jobTitle, region, offerId, isActive, description,
            totalSeconds,selectedCourse,offer
        };
    
       
        const submissionMap = {
            hear_about_us: async () => {
                const formData = new FormData();
                formData.append('value', value);
                formData.append('refresh', refresh);
                if (isEdit) {
                    formData.append('id', id);
                    await adminServices.putdropDownValue(formData);
                } else {
                    await adminServices.postDropDownValue(formData);
                }
            },
            offer_announcement: async () => {
                const formData = new FormData();
                formData.append('value', baseData);
                formData.append('couponCode', couponCode);
                formData.append('paymentLink', paymentLink);
                formData.append('refresh', refresh);        
                if (isEdit) {
                    formData.append('id', id);
                    await adminServices.putdropDownValue(formData);
                } else {
                    await adminServices.postDropDownValue(formData);
                }
            },
            user_greeting: async () => {
                const formData = new FormData();
                formData.append('value', value);
                formData.append('refresh', refresh);        
                console.log(...formData, 'formData for user_greeting');
                if (isEdit) {
                    formData.append('id', id);
                    await adminServices.putdropDownValue(formData);
                } else {
                    await adminServices.postDropDownValue(formData);
                }
            },
            career_goal: async () => {
                const formData = new FormData();
                formData.append('value', value);
                formData.append('refresh', refresh);        
                console.log(...formData, 'formData for career_goal');
                if (isEdit) {
                    formData.append('id', id);
                    await adminServices.putdropDownValue(formData);
                } else {
                    await adminServices.postDropDownValue(formData);
                }
            },
            job: async () => {
                const formData = new FormData();
                formData.append('value', value);
                formData.append('refresh', refresh);        
                console.log(...formData, 'formData for job');
                if (isEdit) {
                    formData.append('id', id);
                    await adminServices.putdropDownValue(formData);
                } else {
                    await adminServices.postDropDownValue(formData);
                }
            },
            education: async () => {
                const formData = new FormData();
                formData.append('value', value);
                formData.append('refresh', refresh);        
                console.log(...formData, 'formData for education');
                if (isEdit) {
                    formData.append('id', id);
                    await adminServices.putdropDownValue(formData);
                } else {
                    await adminServices.postDropDownValue(formData);
                }
            },
            work_experience: async () => {
                const formData = new FormData();
                formData.append('value', value);
                formData.append('refresh', refresh);        
                console.log(...formData, 'formData for work_experience');
                if (isEdit) {
                    formData.append('id', id);
                    await adminServices.putdropDownValue(formData);
                } else {
                    await adminServices.postDropDownValue(formData);
                }
            },
            current_role: async () => {
                const formData = new FormData();
                formData.append('value', value);
                formData.append('refresh', refresh);        
                console.log(...formData, 'formData for current_role');
                if (isEdit) {
                    formData.append('id', id);
                    await adminServices.putdropDownValue(formData);
                } else {
                    await adminServices.putdropDownValue(formData);
                }
            },
            static_banner_message: async () => {
                const formData = new FormData();
                formData.append('value', value);
                formData.append('refresh', refresh);        
                console.log(...formData, 'formData for static_banner_message');
                if (isEdit) {
                    formData.append('id', id);
                    await adminServices.putdropDownValue(formData);
                } else {
                    await adminServices.postDropDownValue(formData);
                }
            },
            offer_page: async () => {
                const formData = new FormData();
                formData.append('value', value);
                formData.append('offerPrize', offer);
                formData.append('description', description);
                formData.append('selectedCourse', selectedCourse);
                formData.append('time', totalSeconds);
                formData.append('title', refresh);        
                if (thumbImage) {
                    formData.append('thumbImage', thumbImage);
                }
                if (coverImage) {
                    formData.append('coverImage', coverImage);
                }
        
                if (isEdit) {
                    formData.append('id', id);
                    await adminServices.putdropDownValue(formData);
                } else {
                    await adminServices.postDropDownValue(formData);
                }
            },
        };
        


        const submitAction = submissionMap[refresh];
        if (submitAction) {
            await submitAction();
        } else {
            console.log('Unknown refresh type');
        }
    
        setButtonLoading(false);
        resetFields();
        onClose();
    };
    
 
 
    const resetFields = () => {
        setValue('');
        setJobTitle('');
        setRegion('');
        setOfferId('');
        setIsActive(false);
        setCouponCode('');
        setPaymentLink('');
        setValidationDate('');
    };
 
 
    // image
    const imageWidthAndHeight = (provideFile) => {
        if (provideFile) {
            return new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(provideFile);
                reader.onload = () => {
                    const img = new Image();
                    img.src = reader.result;
                    img.onload = () => resolve({ width: img.width, height: img.height });
                };
            });
        }
    };
 
    const handleCourseChange = (e) => {
        setSelectedCourse(e.target.value);
      };
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{isEdit ? 'Edit' : 'Create'} {refresh}</DialogTitle>
            <DialogContent>
                {['hear_about_us', 'career_goal', 'job', 'education', 'work_experience', 'current_role', 'static_banner_message', 'offer_page']?.includes(refresh) && (
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Value"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                    />
                )}
                {refresh === 'offer_announcement' && (
                    <>
                        <TextField
                            autoFocus
                            margin="dense"
                            label="Job Title"
                            type="text"
                            fullWidth
                            variant="outlined"
                            value={jobTitle}
                            onChange={(e) => setJobTitle(e.target.value)}
                        />
                        <TextField
                            margin="dense"
                            label="Region"
                            type="text"
                            fullWidth
                            variant="outlined"
                            value={region}
                            onChange={(e) => setRegion(e.target.value)}
                        />
                        <TextField
                            margin="dense"
                            label="Offer ID"
                            type="text"
                            fullWidth
                            variant="outlined"
                            value={offerId}
                            onChange={(e) => setOfferId(e.target.value)}
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={isActive}
                                    onChange={() => setIsActive(!isActive)}
                                    color="primary"
                                />
                            }
                            label="Is Active"
                        />
                        <TextField
                            margin="dense"
                            label="Coupon Code"
                            type="text"
                            fullWidth
                            variant="outlined"
                            value={couponCode}
                            onChange={(e) => setCouponCode(e.target.value)}
                        />
                        <TextField
                            margin="dense"
                            label="Payment Link"
                            type="text"
                            fullWidth
                            variant="outlined"
                            value={paymentLink}
                            onChange={(e) => setPaymentLink(e.target.value)}
                        />
                        <TextField
                            margin="dense"
                            label="Validation Date"
                            type="date"
                            fullWidth
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            value={validationDate}
                            onChange={(e) => setValidationDate(e.target.value)}
                        />
                    </>
                )}
                {refresh === 'user_greeting' && (
                    <>
                        <TextField
                            autoFocus
                            margin="dense"
                            label="Value"
                            type="text"
                            fullWidth
                            variant="outlined"
                            value={value}
                            onChange={(e) => setValue(e.target.value)}
                        />
                        <TextField
                            margin="dense"
                            label="Coupon Code"
                            type="text"
                            fullWidth
                            variant="outlined"
                            value={couponCode}
                            onChange={(e) => setCouponCode(e.target.value)}
                        />
                        <TextField
                            margin="dense"
                            label="Payment Link"
                            type="text"
                            fullWidth
                            variant="outlined"
                            value={paymentLink}
                            onChange={(e) => setPaymentLink(e.target.value)}
                        />
                    </>
                )}
                {refresh === 'offer_page' && (
                    <>
                        <Grid item xs={12}>
                            <Typography gutterBottom>Description</Typography>
                            <CKEditor
                                editor={ClassicEditor}
                                data={description}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setDescription(data);
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} style={{ marginBottom: '8px' }}>
                            <FormControl required fullWidth>
                                <Typography variant="subtitle1">Thumb Image (360X200)</Typography>
                                <div style={{ height: '200px', position: 'relative' }}>
                                    {!thumbPreview ? (
                                        <DropzoneArea
                                            acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
                                            dropzoneText="Drag and Drop Image or Browse File"
                                            maxFileSize={2097152}
                                            filesLimit={1}
                                            onChange={(files) => {
                                                const file = files[0];
                                                if (file) {
                                                    setThumbImage(file);
                                                    const url = URL.createObjectURL(file);
                                                    setThumbPreview(url);
                                                }
                                            }}
                                            showFileNamesInPreview={false}
                                            useChipsForPreview={false}
                                            style={{ border: 'none', height: 'auto' }}
                                        />
                                    ) : (
                                        <img src={thumbPreview} alt="Thumb Preview" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                                    )}
                                    {thumbPreview && (
                                        <CloseIcon
                                            onClick={() => { setThumbPreview(null); setThumbImage(null); }}
                                            style={{ position: 'absolute', top: '10px', right: '10px', cursor: 'pointer' }}
                                        />
                                    )}
                                </div>
                            </FormControl>
                        </Grid>
 
                        <Grid item xs={12} sm={6} style={{ marginBottom: '8px' }}>
                            <FormControl required fullWidth>
                                <Typography variant="subtitle1">Cover Image (1200X400)</Typography>
                                <div style={{ height: '300px', position: 'relative' }}>
                                    {!coverImgPreview ? (
                                        <DropzoneArea
                                            acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
                                            dropzoneText="Drag and Drop Image or Browse File"
                                            maxFileSize={1048576}
                                            filesLimit={1}
                                            onChange={(files) => {
                                                const file = files[0];
                                                if (file) {
                                                    setCoverImage(file);
                                                    const url = URL.createObjectURL(file);
                                                    setCoverImgPreview(url);  
                                                }
                                            }}
                                            showFileNamesInPreview={false}
                                            useChipsForPreview={false}
                                            style={{ border: 'none', height: 'auto' }}
                                        />
                                    ) : (
                                        <img src={coverImgPreview} alt="Cover Preview" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                                    )}
                                    {coverImgPreview && (
                                        <CloseIcon
                                            onClick={() => { setCoverImgPreview(null); setCoverImage(null); }}
                                            style={{ position: 'absolute', top: '10px', right: '10px', cursor: 'pointer' }}
                                        />
                                    )}
                                </div>
                            </FormControl>
                        </Grid>
 
                        <Grid container spacing={1} style={{ marginTop: '8px' }}>
                            {/* Hours */}
                            <Grid item xs={4}>
                                <FormControl fullWidth>
                                    <InputLabel>Hours</InputLabel>
                                    <Select
                                        name="hours"
                                        value={time.hours}
                                        onChange={handleChange}
                                        label="Hours"
                                    >
                                        {[...Array(24).keys()].map((hour) => (
                                            <MenuItem key={hour} value={hour}>
                                                {hour}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
 
                            {/* Minutes */}
                            <Grid item xs={4}>
                                <FormControl fullWidth>
                                    <InputLabel>Minutes</InputLabel>
                                    <Select
                                        name="minutes"
                                        value={time.minutes}
                                        onChange={handleChange}
                                        label="Minutes"
                                    >
                                        {[...Array(60).keys()].map((minute) => (
                                            <MenuItem key={minute} value={minute}>
                                                {minute}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
 
                            {/* Seconds */}
                            <Grid item xs={4}>
                                <FormControl fullWidth>
                                    <InputLabel>Seconds</InputLabel>
                                    <Select
                                        name="seconds"
                                        value={time.seconds}
                                        onChange={handleChange}
                                        label="Seconds"
                                    >
                                        {[...Array(60).keys()].map((second) => (
                                            <MenuItem key={second} value={second}>
                                                {second}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <TextField
                        autoFocus
                        margin="dense"
                        label="Offer Price"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={offer}
                        onChange={(e) => setOffer(e.target.value)}
                    />
                        <Grid item xs={12} sm={6} style={{ marginTop: '16px' }}>
                            <FormControl fullWidth required>
                                <InputLabel>Course</InputLabel>
                                <Select
                                    value={selectedCourse}
                                    onChange={handleCourseChange}
                                    label="Course"
                                >
                                    {courseList.map((course) => (
                                        <MenuItem key={course.id} value={course.id}>
                                            {course.title}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                    </>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleSubmit} color="primary" disabled={buttonLoading}>
                    {getButtonText()}
                </Button>
            </DialogActions>
 
        </Dialog>
    );
};
 
const useStyles = makeStyles((theme) => ({
    imgPreviewRoot: {
        borderRadius: '10px',
        padding: '0.8rem',
    },
    fileImgSize: {
        width: '100%',
        height: '120px',
        objectFit: 'cover',
        objectPosition: 'center',
        border: '1px solid #fff',
        borderRadius: '5px',
        boxShadow: '0 3px 10px rgba(0, 0, 0, 0.2)',
    },
    badgeAlign: {
        boxShadow: '0 2px 8px -5px #ff0000',
        color: '#FF0000',
        fontSize: '1.2rem',
        backgroundColor: '#fff',
        padding: '2px',
        borderRadius: '10px',
        cursor: 'pointer',
    },
}));
export default CustomDialog;
 
 