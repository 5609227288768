import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, Card, CardContent, Dialog, DialogActions, DialogContent,IconButton, DialogTitle } from '@mui/material';
import { useLocation, NavLink, useNavigate } from 'react-router-dom';
import { Close } from '@mui/icons-material';
import DiamondImage from '../../../assets/Images/Start.png';
 
const IQCard = ({openmodel,userRole,subscription,courseDetails,courseModules,progress,handlCloseBack}) => {
  const [open, setOpen] = useState(false);
console.log(openmodel,"openmodel");


  const navigate = useNavigate();
  // const handleClickOpen = () => {
  //   setOpen(true);
  // };
 
  const handleClose = () => {
    handlCloseBack()
  };
 
  React.useMemo(() => {
    let openview;
    if (progress === 25) {
      openview = true;
    } else if (progress === 50) {
      openview = true;
    } else if (progress === 75) {
      openview = true;
    } else if (progress === 100) {
      openview = true;
    }
    else{
      openview = false;
    }
 

  }, [progress])
 
 
  const styles = {
    card: {
      width: '300px',
      borderRadius: '16px 16px 0 0',
      backgroundColor: '#fff',
      textAlign: 'center',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      position: 'relative',
      border: '4px solid #002B50',
      // paddingBottom: '20px',
      overflow: 'visible',
      minHeight:'380px',
      maxHeight:'380px'
    },
    closeButton: {
      position: 'absolute',
      top: '5px',
      right: '10px',
      color: '#fff', // Change the color of the close icon
    },
    medalContainer: {
      position: 'absolute',
      top:  '22%',
      left: '50%',
      transform: 'translateX(-50%)',
      width: '100px',
      height: '100px',
      borderRadius: '50%',
      backgroundColor: '#fff',
      // border: '5px solid #fff',
      border: '4px solid #002B50',
      
      boxShadow: '0 4px 10px rgba(0, 0, 0, 0.15)',
      background: 'linear-gradient(to bottom, #fff, #fff)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    medalImage: {
      width: '90px',
      height: '90px',
      marginRight: '6px',
      marginTop:'2px'
    },
    topText: {
      marginTop: '38px',
      color: '#FF7F00',
      fontWeight: 'bold',
      fontSize: '20px',
    },
    percentageText: {
      marginTop: '10px',
      fontWeight: 'bold',
      fontSize: '32px',
      color: '#000',
    },
    // subText: {
    //   color: '#000',
    //   fontSize: '18px',
    //   marginBottom: '20px',
    // },
 
    subText: {
        color: '#000',
        fontSize: '13px',
        marginBottom: '20px',
        maxWidth: '300px',
        lineHeight: 1.5,
        textAlign: 'center',
      },
    bottomSection: {
      background: 'linear-gradient(to bottom, #0A4DFF, #0030C0)',
      padding: '20px 0',
      color: 'white',
      fontSize: '20px',
      fontWeight: 'bold',
      borderRadius: '0 0 16px 16px',
    },
    rewardButton: {
      backgroundColor: '#212652',
      color: 'white',
      borderRadius: '8px',
      padding: '10px 20px',
    //   marginTop: '20px',
      fontWeight: 'bold',
      textTransform: 'none',
    },

  };
  const handleIQstart = (type) => {
    if( subscription?.isSubscribed && ['active', 'expired', 'cancel'].includes(subscription?.planStatus?.toLowerCase()) ){
        const keyType =  courseModules[0]?.subModule[0]?.subModuleTopics[0]?.actionType     
        if (type === 'COURSE') {
          if (userRole === 'USER_DTC') {
            navigate(`/auth/skilliq-test?courseId=${courseDetails?.id}`, {
              replace: true,
              // state: { ...courseDetails, ...keyType,subModule },
              state: { ...courseDetails, keyType },
    
            });
          } else {
            navigate(`/app/skilliq-test?courseId=${courseDetails?.id}`, {
              replace: true,
              state: { ...courseDetails, keyType },
    
              // state: { ...courseDetails, ...keyType ,subModule},
            });
          }
        }
    }
    else{
      alert("Please subscribe to unlock the IQ Test")
    }
  };

  const handleIQstartVideo = (type) => {
  if( subscription?.isSubscribed && ['active', 'expired', 'cancel'].includes(subscription?.planStatus?.toLowerCase()) ){
    const keyType = 'VIDEO';
    if (type === 'COURSE') {
      if (userRole === 'USER_DTC') {
        navigate(`/auth/skilliq-test?courseId=${courseDetails.id}`, {
          replace: true,
          state: { ...courseDetails, keyType },
        });
      } else {
        navigate(`/app/skilliq-test?courseId=${courseDetails.id}`, {
          replace: true,
          state: { ...courseDetails, keyType },
        });
      }
    }
  }
  else{
    alert("Please subscribe to unlock the IQ Test")
  }
  };

  return (
    <>
     
      <Dialog open={openmodel}
      onClose={handleClose}
      sx={{
        '& .MuiDialog-paper': {
          backgroundColor: 'transparent',
          border: 'none',
          boxShadow: 'none',
          margin: 0,
          // height: `${dialogHeight}px`, // Set the dialog height
          overflow: 'visible',
        }
      }}>
        <DialogContent>
         
        <Card style={styles.card}>
        <IconButton style={styles.closeButton} onClick={handleClose}>
            <Close />
          </IconButton>
          {/* linear-gradient(rgb(33, 38, 82), rgb(30 51 233)); */}
        <Box style={{minHeight:140,maxHeight:140, background: 'linear-gradient(to bottom, #0f51ba, #095ee6)',}}>
        <Box style={styles.medalContainer}>
        <img
          src={DiamondImage}
          alt="Medal"
          style={styles.medalImage}
        />
      </Box>
        </Box>
     
 
      <CardContent>
        <Typography style={styles.topText}>Take SkillSet IQ</Typography>
        <Typography  style={styles.subText}>Complete the IQ Test now to unlock the reward and know how much you know about course name
        </Typography>
        {/* <Button style={styles.rewardButton}>Take IQ Test</Button> */}
 
        {(userRole === 'AUTH_USER' || userRole === 'USER_DTC') &&
            subscription?.isSubscribed &&
            ['active', 'expired', 'cancel'].includes(subscription?.planStatus?.toLowerCase()) &&
            courseDetails?.type !== 'video' ? (
                <Button onClick={() => handleIQstart('COURSE')} style={styles.rewardButton}>Take IQ Test</Button>
              )
              :
              (
                <Button onClick={() => handleIQstartVideo('COURSE')} style={styles.rewardButton}>Take IQ Test</Button>
 
              //   <Button variant="contained"  
              //   className={classes.iqbutton}
              //   onClick={() => handleIQstartVideo('COURSE')}
              // >
              //     Take IQ Assessment
              //   </Button>
              )
            }
      </CardContent>
    </Card>
        </DialogContent>
       
      </Dialog>
    </>
  );
};
 
export default IQCard;