import React, { useEffect, useRef, useState } from 'react';
import { Grid, Box, Typography, Card, Button } from '@mui/material';
import { Container } from '@mui/system';
import { makeStyles } from '@mui/styles';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { Helmet } from 'react-helmet-async';
import lottie from 'lottie-web';

import CheckMark from '../../assets/lottie/checkMark.json';
import CancelIcon from '../../assets/lottie/cancel-animation.json';
import ReactImage from '../images/ReactImage.png';
import subscriptionServices from '../../services/subscriptionServices';
import trialAndStripeSubscriptionService from '../../services/trialAndStripeSubscriptionService';
import courseApi from '../../services/users/courseApi';
import loginServices from '../../services/loginServices';
import { setSubscribedCourses, loginSuccess } from '../../store/reducer';

export const SuccessPage = () => {
  const classes = useStyles();
  const lottoLoad = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [courseDetails, setCourseDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const frequency = query.get('frequency');
  const planId = query.get('planId');
  const canceled = query.get('canceled');
  const success = query.get('success');
  const currencyType = query.get('currencyType');
  const userRole = useSelector((state) => state.userInfo && state.userInfo.role);
  const { userInfo } = useSelector((state) => state);

  const getCourserList = async () => {
    try {
      const response = await courseApi.getCourseList();
      if (response.ok) {
        dispatch(setSubscribedCourses(response.data));
      } else {
        console.log('failed');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getCourseDetails = async () => {
    try {
      const res = await subscriptionServices.getSubscriptionDetailsByPlanId(planId);
      if (res.ok) {
        console.log(res.data);
        setCourseDetails(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const res = await trialAndStripeSubscriptionService.createStripeSubscription(
        JSON.stringify({ planId: Number(planId),frequency:Number(frequency), currencyType })
      );
      if (res.status === 303) {
        setLoading(false);
        window.location = res.data.paymentUrl;
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getLoginUserDetails = async () => {
    const res = await loginServices.getUserInfo();
    if (res.ok) {
      dispatch(loginSuccess(res.data));
    }
  };
  useEffect(() => {
    if (success === 'true') {
      getCourserList();
      if (userInfo?.currencyType === null) {
        getLoginUserDetails();
      }
    }
  }, [success]);

  useEffect(() => {
    getCourseDetails();
    if (canceled === 'true') {
      lottie.loadAnimation({
        container: lottoLoad.current,
        renderer: 'svg',
        loop: false,
        autoplay: true,
        animationData: CancelIcon,
      });
    } else {
      lottie.loadAnimation({
        container: lottoLoad.current,
        renderer: 'svg',
        loop: false,
        autoplay: true,
        animationData: CheckMark,
      });
    }
  }, [planId]);

  const handleCardClickItem = () => {
    if (userRole === 'USER_DTC') {
      navigate('/auth/my-courses');
    } else {
      navigate('/auth/subscribe');
    }
  };
  return (
    <>
      {!canceled && (
        <Helmet>
          <script>
            {`
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '228066771624385');
            fbq('track', 'PageView');
              fbq('track', 'Purchase', {
          value: '19.99',
          currency: 'USD',
          content_ids: 'PythonBeginnerMonthlyPlan',
          content_type: 'Subscription',
        });
            `}
          </script>
        </Helmet>
      )}
      <div>
        {/* <WebPage title="successPage"> */}
        <div className={classes.outlook}>
          <Container sx={{ maxWidth: '1440px ! important' }}>
            <Grid container sx={{ maxWidth: '1440px ! important' }}>
              <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                <Card className={classes.card}>
                  <div>
                    <Box
                      sx={{
                        margin: '0rem',
                        backgroundImage: `url(${
                          courseDetails?.coverageImage ? courseDetails?.coverageImage : ReactImage
                        })`,
                        borderRadius: '8px',
                        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: '100% 100%',
                        width: '100%',
                        height: '180px',
                      }}
                    />
                  </div>
                  <div className={classes.design}>
                    <Box>
                      <div style={{ width: 100, margin: 'auto' }} ref={lottoLoad} />
                    </Box>
                    <div className={classes.list} style={{ display: 'inline' }}>
                      <Typography className={classes.listItem} gutterBottom>
                        {canceled === 'true' ? (
                          <>Failed to Subscribe </>
                        ) : (
                          <>
                            You have successfully subscribed{' '}
                            {courseDetails?.title ? <>for {courseDetails?.title}.</> : '.'}
                          </>
                        )}
                      </Typography>
                    </div>
                    <Box sx={{ margin: ' 1rem 0px 0px 0rem' }}>
                      {canceled === 'true' ? (
                        <div>
                          <LoadingButton
                            variant="contained"
                            color="primary"
                            disabled={loading}
                            onClick={() => {
                              navigate('/auth/subscribe');
                            }}
                          >
                            Back
                          </LoadingButton>
                          <LoadingButton
                            variant="contained"
                            color="primary"
                            sx={{ marginLeft: 16 }}
                            disabled={loading}
                            loading={loading}
                            onClick={() => {
                              handleSubmit();
                            }}
                          >
                            Try again
                          </LoadingButton>
                        </div>
                      ) : (
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => handleCardClickItem()}
                          title="Start Learning"
                        >
                          Start Learning
                        </Button>
                      )}
                    </Box>
                  </div>
                </Card>
              </Grid>
            </Grid>
          </Container>
        </div>
        {/* </WebPage> */}
      </div>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  listItem: {
    // color: '#27292E',
    fontSize: '1.2rem',
    fontWeight: '400',
    marginLeft: '12px',
    textAlign: 'center',
    margin: '0rem ',
  },
  list: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    margin: '1.6rem 0rem 0rem 0rem',
  },
  design: {
    marginTop: '1rem',
    // display: 'flex',
    textAlign: 'center',
  },
  card: {
    width: '40%',
    padding: '20px',
    borderRadius: '20px',
  },
  name: {
    color: '#00B673',
    fontWeight: '500',
    marginBottom: '10px',
  },
  font: {
    color: '#101828',
    fontSize: '1.4rem',
    fontWeight: '600',
    lineHeight: '30px',
    marginBottom: '10px',
    '@media (max-width: 640px)': {
      fontSize: '1.2rem',
    },
  },
  title: {
    textAlign: 'center',
    margin: '1rem',
  },
  titleName: {
    textAlign: 'center',
    margin: '1rem',
    maxWidth: '100%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
  outlook: {
    padding: '32px 23px 0px 23px',
    marginTop: ' 5rem',
    '@media (max-width: 780px)': {
      padding: '32px 0px 16px 0px',
    },
  },
}));
