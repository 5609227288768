import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import { Box, Button, CardMedia } from '@mui/material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import PersonIcon from '@mui/icons-material/Person';
import clsx from 'clsx';
import CardContent from '@mui/material/CardContent';
import moment from 'moment';
import Background from '../../assets/logo/background.svg';
import Iconify from '../Iconify';

export default function FreeCard(props) {
  // console.log(props,"props");

  const classes = useStyles();
  const [status, setStatus] = useState(null);



  useEffect(() => {
    setStatus('');
    if (props?.trial && props?.planStatus?.toLowerCase() === 'expired') {
      setStatus('Trial Ended');
    } else if (props?.trial) {
      setStatus('Trial');
    } else if (props?.subscribed && props?.planStatus?.toLowerCase() === 'expired') {
      setStatus('Subscription Ended');
    } else if (props?.subscribed) {
      setStatus('Subscribed');
    }
  }, [props]);

  const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
    ({ theme }) => ({
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[2],
        fontSize: 11,
      },
    })
  );

  const splitText = (text, maxWords) => {
    const words = text.split(' ');
    if (words.length > maxWords) {
      const firstLine = words.slice(0, maxWords).join(' ');
      const secondLine = words.slice(maxWords).join(' ');
      return [firstLine, secondLine];
    }
    return [text];
  };

  const lines = splitText(props.title, 30);

  return (
    <div>
      <Card
        className="cardhover"
        sx={{
          cursor: 'pointer',
          minHeight: '380px',
          maxHeight: '380px',
          display: 'flex',
          flexDirection: 'column',

          transition: 'transform 0.3s ease-in-out, max-height 0.3s ease-in-out', // Add transition for max-height
          '&:hover': {
            transform: 'scale(1.05)',
            boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
          },
        }}
        onClick={() => props.handleCardClick()}
      >
        <Box
          sx={{
            position: 'relative',
            backgroundColor: '#00B673',
            color: 'white',
            textAlign: 'center',
            paddingTop: '8px',
            paddingBottom: '8px',
          }}
        >
          <Typography variant="h6" component="div">
            {props?.type && props?.type.charAt(0).toUpperCase() + props?.type.slice(1).toLowerCase()}
          </Typography>
        </Box>
        {/* <CardMedia
          component="img"
          height="160"
          image={props.image}
          sx={{
            objectFit: 'cover',
            width: '100%',
          }}
          alt="course image"
          className="card-image"
        /> */}
        <CardMedia
          component="img"
          image={props.image}
          sx={{
            height: '160px',
            width: '100%',
            objectFit: 'cover',
            minHeight: '160px',
            maxHeight: '160px',
          }}
          alt="course image"
          className="card-image"
        />
        <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', padding: '12px' }}>
          {/* {status && props.data && (
            <LightTooltip
              title={
                <Box>
                  <Typography variant="body2">
                    Valid from: {moment(props.data.validFrom).format('MM-DD-YYYY')}
                  </Typography>
                  <Typography variant="body2">
                    Valid to: {moment(props.data.validTo).format('MM-DD-YYYY')}
                  </Typography>
                  <Typography variant="body2">
                    Days left: {props.data.remainingDays} Days
                  </Typography>
                </Box>
              }
              placement="top-start"
            >
              <Typography
                variant="body2"
                color="secondary"
                width="max-content"
                sx={{ alignItems: 'center', display: 'flex' }}
              >
                {status} &nbsp;
                <Iconify icon="bi:info-circle" sx={{ color: '#5f5b5b' }} />
              </Typography>
            </LightTooltip>
          )} */}

          <Box sx={{ minHeight: '30px', display: 'flex', alignItems: 'center' }}>
            {status && props.data ? (
              <LightTooltip
                title={
                  <Box>
                    <Typography variant="body2">
                      Valid from: {moment(props.data.validFrom).format('MM-DD-YYYY')}
                    </Typography>
                    <Typography variant="body2">
                      Valid to: {moment(props.data.validTo).format('MM-DD-YYYY')}
                    </Typography>
                    <Typography variant="body2">
                      Days left: {props.data.remainingDays} Days
                    </Typography>
                  </Box>
                }
                placement="top-start"
              >
                <Typography
                  variant="body2"
                  color="secondary"
                  width="max-content"
                  sx={{ alignItems: 'center', display: 'flex' }}
                >
                  {status} &nbsp;
                  <Iconify icon="bi:info-circle" sx={{ color: '#5f5b5b' }} />
                </Typography>
              </LightTooltip>
            ) : (
              <Box sx={{ height: '18px' }} />
            )}
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', pr: 5 }}>
                        <Typography
                            variant="body2"
                            color="primary"
                            style={{
                                fontFamily: 'Poppins',
                                fontSize: '12px',
                                height: '32px',
                            }}
                            className={classes.coursetitle}
                            gutterBottom
                        >
                            {props?.title}
                        </Typography>
                    </Box>
          {/* <Typography variant="body2" color="primary" component="div">
            {lines.map((line, index) => (
              <div key={index}>{line}</div>
            ))}
          </Typography> */}

          {props.author && (
            <Typography sx={{ color: '#323030', fontSize: '0.65rem', fontWeight: '600' }}>
              Author: {props.author.name}
            </Typography>
          )}

          {props.description && (
            <Typography
              variant="body2"
              color="text.secondary"
              dangerouslySetInnerHTML={{ __html: props.description }}
            />
          )}
          {props.progress && <Box>{props.progress}</Box>}

          {props.date && (
            <Typography gutterBottom color="secondary" variant="body2">
              {props.date}
            </Typography>
          )}

          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box sx={{ display: 'flex' }}>
              {props.userLicense && (
                <Typography variant="body2">
                  User License <span className={classes.enrolledUser}> {props.userLicense}</span>{' '}
                </Typography>
              )}
              {props.userCount && (
                <Typography variant="body2" sx={{ marginLeft: '12px', width: 'max-content' }}>
                  {props.userCount}
                </Typography>
              )}
            </Box>

            {props.status && (
              <div className={clsx({
                [classes.active]: props.status === 'Live' || props.status === 'LIVE',
                [classes.inActive]: props.status === 'ACTIVE',
                [classes.Expired]: props.status === 'INACTIVE',
                [classes.inActive]: props.status === 'Draft' || props.status === 'DRAFT',
              })}>
                <Typography variant="body2" sx={{ fontSize: '1rem', fontWeight: '400' }}>
                  {props.status}
                </Typography>
              </div>
            )}
          </Box>

          {props.Enrolled && (
            <Box sx={{ marginTop: '1rem' }}>
              <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center' }}>
                <PersonIcon /> &nbsp; Enrolled Users &nbsp; {props.Enrolled}
              </Typography>
            </Box>
          )}

          {props.enrollDate && (
            <Typography color="secondary" variant="body2" sx={{ fontSize: '10px' }}>
              Enrolled on {moment(props.enrollDate).format('LL')}
            </Typography>
          )}

          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            {/* <Typography className={classes.button} color="secondary">
              {props.category && props.category}
            </Typography> */}
            {props.category && props.category?.length > 18 ?
              <Tooltip title={props.category} arrow>
                <Typography className={classes.button} color="secondary">
                  {props.category && props.category}
                </Typography>
              </Tooltip>
              :
              <Typography className={classes.button} color="secondary">
                {props.category && props.category}
              </Typography>}
            <Box style={{ marginLeft: 1, marginTop: -12 }} className={classes.back}>
              <img className={classes.logo} src={props.logo} alt="logo" width={35} height={30} />
            </Box>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
}
const useStyles = makeStyles((theme) => ({
  trial: {
    color: 'white',
    padding: '0px 6px 0px 6px ',
    background: 'grey',
  },
  card: {
    border: '0.5px solid #DFDFDF',
    boxShadow: '0px 3px 6px  #0000001A',
    borderRadius: '6px',
    backgroundImage: `url(${Background})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'bottom right',
    cursor: 'pointer',
    marginTop: '16px',
    marginLeft: '10px',
    width: '95%',
    // width: '85%', // Reduced from 90% to 85%
    // height: 'auto', // You can add this if needed
    // maxHeight: '300px', // Add maxHeight to limit overall card height
  },
  date: {
    marginTop: '15px',
  },
  button: {
    backgroundColor: '#F8F5F4',
    padding: '4px 6px',
    borderRadius: '6px',
    width: 'max-content',
    maxWidth: '165px',
    overflow: 'hidden',
    display: 'block',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  title: {
    marginTop: '10px',
    fontWeight: 'bold',
    fontSize: '15px',
  },
  cardTitle: {
    fontWeight: 'bold',
    fontSize: '15px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '-webkit-line-clamp': 1,
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
  },
  cardTitlenew: {
    fontWeight: 'bold',
    fontSize: '15px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '-webkit-line-clamp': 2,
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    minHeight: '50px',
    maxHeight: '50px',
  },
  logo: {
    boxShadow: '0px 3px 22px #00000029',
    padding: '4px',
    backgroundColor: '#fff',
    borderRadius: '6px',
    marginTop: '10px',
  },
  description: {
    minHeight: '38px',
    fontSize: '0.8rem !important',
    overflow: 'hidden !important',
    fontFamily: 'Inter',
    textOverflow: 'ellipsis',
    '-o-text-overflow': 'ellipsis',
    '-ms-text-overflow': 'ellipsis',
    '-moz-binding': "url('ellipsis.xml#ellipsis')",
    '-ms-webkit-line-clamp': 2,
    '-webkit-line-clamp': 2,
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '& span': {
      fontSize: '0.8rem !important',
      color: 'black !important',
      backgroundColor: 'unset !important',
      fontFamily: 'Inter !important',
    },
    '& p': {
      '&:nth-child(1)': {
        display: 'block !important',
      },
      '&:nth-child(even)': {
        display: 'none ',
      },
      '&:nth-child(odd)': {
        display: 'none ',
      },
    },
  },
  enrolledUser: {
    backgroundColor: '#EBFFF8',
    borderRadius: '6px',
    padding: '4px 12px',
    fontSize: '12px',
  },
  active: {
    backgroundColor: 'green',
    borderRadius: '6px',
    padding: '4px 12px',
  },
  inActive: {
    backgroundColor: 'yellow',
    borderRadius: '6px',
    padding: '4px 12px',
  },
  Expired: {
    backgroundColor: '#FF8282',
    borderRadius: '6px',
    padding: '4px 12px',
  },
  coursetitle: {
    overflow: 'hidden',
    // color: 'black',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    [theme.breakpoints.down('md')]: {
        overflow: 'auto',
        WebkitLineClamp: 'initial',
        WebkitBoxOrient: 'initial',
    },


},
}));