import * as Actions from './Constant'
import courseApi from '../services/users/courseApi'
import iqServices from '../services/iqTest/index';
import adminServices from '../services/adminServices';


export const getExcelDetails = (cardViewSubMenu) => async(dispatch, getState) => {
    dispatch({type:Actions.GetExcelDetailPending})
    await courseApi.getExcelDetails(cardViewSubMenu)
    .then((response)=>{        
    dispatch({ type: Actions.GetExcelDetailSuccess, payload: response.data })

    })
    .catch((error) => {
        dispatch({ type: Actions.GetExcelDetailFailure, payload: error })
    })
}



export const getOfficeDetails = (cardViewSubMenu) => async(dispatch, getState) => {
    dispatch({type:Actions.GetWordDetailsPending})
    await courseApi.getOfficeDetails(cardViewSubMenu)
    .then((response)=>{
    dispatch({ type: Actions.GetWordDetailsSuccess, payload: response.data })

    })
    .catch((error) => {
        dispatch({ type: Actions.GetWordDetailsFailure, payload: error })
    })
}

export const getAccFinDetails = (cardViewSubMenu) => async(dispatch, getState) => {
    dispatch({type:Actions.GetAccDetailsPending})
    await courseApi.getAccFinDetails(cardViewSubMenu)
    .then((response)=>{
    dispatch({ type: Actions.GetAccDetailsSuccess, payload: response.data })

    })
    .catch((error) => {
        dispatch({ type: Actions.GetAccDetailsFailure, payload: error })
    })
}

export const getCodingDetails = (cardViewSubMenu) => async(dispatch, getState) => {
    dispatch({type:Actions.GetCodeDetailsPending})
    await courseApi.getCodingDetails(cardViewSubMenu)
    .then((response)=>{
    dispatch({ type: Actions.GetCodeDetailsSuccess, payload: response.data })

    })
    .catch((error) => {
        dispatch({ type: Actions.GetCodeDetailsFailure, payload: error })
    })
}


export const getPowerPointDetails = (cardViewSubMenu) => async(dispatch, getState) => {
    dispatch({type:Actions.GetPowerPointPending})
    await courseApi.gePowerPointDetails(cardViewSubMenu)
    .then((response)=>{
    dispatch({ type: Actions.GetPowerPointSuccess, payload: response.data })

    })
    .catch((error) => {
        dispatch({ type: Actions.GetPowerPointFailure, payload: error })
    })
}


export const getErgDetails = () => async(dispatch, getState) => {
    dispatch({type:Actions.GetERGPending})
    await courseApi.getErgCourseDetails()
    .then((response)=>{
    dispatch({ type: Actions.GetERGSuccess, payload: response.data })

    })
    .catch((error) => {
        dispatch({ type: Actions.GetERGFailure, payload: error })
    })
}


export const getRecomdedCourseList = (id) => async(dispatch, getState) => {
    dispatch({type:Actions.GetRecomendedPending})
    await courseApi.getRecomdedCourseList(id)
    .then((response)=>{
    dispatch({ type: Actions.GetRecomendedSuccess, payload: response.data })

    })
    .catch((error) => {
        dispatch({ type: Actions.GetRecomendedFailure, payload: error })
    })
}

export const getCertificateList = () => async(dispatch, getState) => {
    dispatch({type:Actions.GetCertificatePending})
    await courseApi.getCertificateList()
    .then((response)=>{
    dispatch({ type: Actions.GetCertificateSuccess, payload: response.data })

    })
    .catch((error) => {
        dispatch({ type: Actions.GetCertificateFailure, payload: error })
    })
}

export const getAllFreeCourses = () => async(dispatch, getState) => {
    dispatch({type:Actions.GetFreeCoursePending})
    await courseApi.getFreeCourseList()
    .then((response)=>{
    dispatch({ type: Actions.GetFreeCourseSuccess, payload: response.data })

    })
    .catch((error) => {
        dispatch({ type: Actions.GetFreeCourseFailure, payload: error })
    })
}


export const getAllcourseDetails = () => async(dispatch, getState) => {
    dispatch({type:Actions.GetAllCoursePending})
    await courseApi.getCourseList()
    .then((response)=>{
    dispatch({ type: Actions.GetAllCourseSuccess, payload: response.data })

    })
    .catch((error) => {
        dispatch({ type: Actions.GetAllCourseFailure, payload: error })
    })
}

export const getCourseProgress = (id) => async(dispatch, getState) => {
    dispatch({type:Actions.GetProgressPending})
    await iqServices.getIqAndCourseProgress(id)
    .then((response)=>{
    dispatch({ type: Actions.GetProgressSuccess, payload: response.data })

    })
    .catch((error) => {
        dispatch({ type: Actions.GetProgressFailure, payload: error })
    })
}

export const getCourseDetails1 = (id,subscriptionPlanId) => async(dispatch, getState) => {
    dispatch({type:Actions.GetCoursePending})
    await courseApi.getCourseModulesAndSubmodules(id,subscriptionPlanId)
    .then((response)=>{
        
    dispatch({ type: Actions.GetCourseSuccess, payload: response.data })

    })
    .catch((error) => {
        dispatch({ type: Actions.GetCourseFailure, payload: error })
    })
}

export const getSearchedCourse = (searchQuery) => async(dispatch, getState) => {
    dispatch({type:Actions.GetSearchCoursePending})
    await adminServices.getSerchedCourse(searchQuery)
    .then((response)=>{
    dispatch({ type: Actions.GetSearchCourseSuccess, payload: response.data })

    })
    .catch((error) => {
        dispatch({ type: Actions.GetSearchCourseFailure, payload: error })
    })
}


export const getCategoryDetails = () => async (dispatch, getState) => {
    dispatch({ type: Actions.GetCategoryPending })
    await adminServices.getBasicDetails()
        .then((response) => {
            dispatch({ type: Actions.GetCategorySuccess, payload: response.data })
 
        })
        .catch((error) => {
            dispatch({ type: Actions.GetCategoryFailure, payload: error })
        })
}


export const getWordCourse = (data) => async(dispatch, getState) => {
    dispatch({type:Actions.GetWordPending})
    await courseApi.getWordCours(data)
    .then((response)=>{
    dispatch({ type: Actions.GetWordSucces, payload: response.data })

    })
    .catch((error) => {
        dispatch({ type: Actions.GetWordFailure, payload: error })
    })
}