// import React, { useState, useEffect } from 'react';
// import { useTranslation } from 'react-i18next';
// import {
//   Grid,
//   Typography,
//   Box,
//   // FormControl,
//   // FormGroup,
//   // FormLabel,
//   // RadioGroup,
//   // FormHelperText,
//   // Radio,
//   // FormControlLabel,
//   TextField,
//   Stack,
//   Container,
//   // Divider,
//   Button
// } from '@mui/material';
// import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
// import Tooltip from '@mui/material/Tooltip';
// import { makeStyles } from '@mui/styles';
// import VisibilityIcon from '@mui/icons-material/Visibility';
// import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
// import InputAdornment from '@material-ui/core/InputAdornment';
// import { Form, Formik } from 'formik';
// import * as Yup from 'yup';
// import { Link as RouterLink, useNavigate, useLocation, useSearchParams } from 'react-router-dom';
// import { LoadingButton } from '@mui/lab';
// import { useDispatch } from 'react-redux';
// import Cookies from 'js-cookie';
// import jwtdecode from 'jwt-decode';
// import { Carousel } from 'react-responsive-carousel';
// import LanguageSwitcher from './LanguageSwitcher';
// import "react-responsive-carousel/lib/styles/carousel.min.css";
// import ErrorFocus from '../../components/ErrorFocus/ErrorFocus';
// import SnackBar from '../../components/snackbar/snackbar';
// import WebPage from '../components/WebPage';
// import Check from '../images/CheckIcon.png';
// import loginServices from '../../services/loginServices';
// import RegisterImage from '../../assets/logo/images/Register.png'
// // import userServices from '../../services/loginServices';
// import trialAndStripeSubscriptionService from '../../services/trialAndStripeSubscriptionService';
// // import RightIcon from '../images/RightIcon2.png';
// // import { Course } from '../components/Course';
// import PhoneNumber from '../../components/PhoneNumber/Index';
// import apiClient from '../../services/apiClient';
// import courseApi from '../../services/users/courseApi';
// import BackgroundImg from '../../assets/logo/images/bg.jpg';
// import BackgroundImges from '../../assets/logo/images/BackgroundImgs.png';
// import BackgroundImges1 from '../../assets/logo/images/backgroundImage1.png';
// import BackgroundImges2 from '../../assets/logo/images/backgroundImage2.png';
// import BackgroundImges3 from '../../assets/logo/images/backgroundImage3.png';
// import BackgroundImges4 from '../../assets/logo/images/backgroundImage4.png';
// import BackgroundImges5 from '../../assets/logo/images/backgroundImage5.png';
// import {
//   loginSuccess,
//   openSubscriptionModal,
//   setSubscribedCourses,
//   setOpenSubscriptionModalDetails,
//   openSnackbar,
// } from '../../store/reducer';
// // import './index.css';
// import Register from './Signup'

// export const GetStarted = () => {
//   const classes = useStyles();
//   const navigate = useNavigate();
//   const dispatch = useDispatch();
//   const location = useLocation();
//   const { t } = useTranslation('translation'); 
//   console.log('t is ',t);
//   const [loading, setLoading] = useState(false);
//   const [visible, setVisible] = React.useState(false);
//   const [snackbar, setSnackbar] = useState(false);
//   const [snackbarTitle, setSnackbarTitle] = useState('');
//   const [searchParams] = useSearchParams();

//   // const [formValues, setFormValues] = React.useState({
//   const [formValues] = React.useState({
//     firstName: '',
//     lastName: '',
//     email: '',
//     phone: '',
//     // password: '',
//     // confirmPassword: '',
//   });

//   console.log(searchParams.get('isSubscribe'));

//   const getLoginUserDetails = async () => {
//     try {
//       const response3 = await loginServices.getUserInfo();
//       if (response3.ok) {
//         dispatch(loginSuccess(response3.data));
//         await getCourserList();
//         if (response3.data.role === 'CONTENT_WRITER' || response3.data.role === 'AUTH_USER') {
//           navigate('/app/course');
//         } else if (response3.data.role === 'USER_DTC') {
//           if (location?.state?.from === 'trialButton') {
//             await postTrialCourseDetails(location?.state?.planId);
//           } else if (location?.state?.from === 'subscribeButton') {
//             await postSubscribeDetails(location?.state?.planId);
//           } else {
//             navigate('/auth/subscribe');
//           }
//         } else {
//           navigate('/app/dashboard');
//         }
//       }
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   const getCourserList = async () => {
//     try {
//       const response = await courseApi.getCourseList();
//       if (response.ok) {
//         dispatch(setSubscribedCourses(response.data));
//       }
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   const postTrialCourseDetails = async (planId) => {
//     try {
//       const res = await trialAndStripeSubscriptionService.postTrialCourseEnrollmentDetails(JSON.stringify({ planId }));
//       if (res.ok) {
//         await getCourserList();

//         dispatch(openSnackbar('Trial Course Subscribed Successfully.'));
//         navigate('/auth/my-courses');
//         // window.location.href = '/auth/my-courses';
//       } else {
//         if (res?.data?.message) {
//           dispatch(openSnackbar(res.data.message));
//         } else {
//           dispatch(openSnackbar('Failed to Subscribe Trial Course, Please try again.'));
//         }
//         navigate('/auth/my-courses');
//         // window.location.href = '/auth/my-courses';
//       }
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   const postSubscribeDetails = async (planId) => {
//     dispatch(setOpenSubscriptionModalDetails(location?.state?.openSubscriptionModalDetails));
//     dispatch(openSubscriptionModal(planId));
//     navigate('/auth/my-courses');
//     // window.location.href = '/auth/my-courses';
//   };

//   const websiteUserLogin = async (values) => {
//     console.log(values);
//     setLoading(true);

//     const resetData = {
//       firstName: values.firstName,
//       lastName: values.lastName,
//       email: values.email,
//       phone: values.phone,
//       // password: values.password,
//       // confirmPassword: values.password,
//     };

//     try {
//       const response = await loginServices.postUserLogin(resetData);

//       if (response.ok) {
//         apiClient.setHeader('Authorization', `BEARER ${response.data.token}`);
//         Cookies.set('token', response.data.token);
//         Cookies.set('refreshToken', response.data.refreshToken);
//         Cookies.set('isLogIn', true);
//         const decoded = await jwtdecode(response.data.token);

//         Cookies.set('tokenExpireTime', decoded.exp);
//         // await getLoginUserDetails();     
//         setSnackbarTitle("Account Created!");
//         setSnackbar(true);
//       }
//       else {
//         console.log('response...', response.data.message)
//         setSnackbarTitle(response.data.message);
//         setSnackbar(true);
//       }

//       setLoading(false);

//     } catch (error) {
//       console.log(error);
//     }
//   };

//   const handleClickShowPassword = () => {
//     setVisible(!visible);
//   };
//   const switchLanguage = (language) => {
//     console.log('Switching language to:', language);
//     // Your language switching logic here
//   };

//   const images = [BackgroundImges1, BackgroundImges2,BackgroundImges3,BackgroundImges4,BackgroundImges5];
//   const [index, setIndex] = useState(0);

//   useEffect(() => {
//     const interval = setInterval(() => {
//       setIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
//     }, 2000); // Change image every 2 seconds
//     return () => clearInterval(interval);
//   }, []);

//   return (
//     // <WebPage title="Sign Up">
//     //   <div className={classes.container}>
//     //     <Grid container spacing={2}>
//     //       <Grid item sx={11} sm={11} md={6}>
//     //         <div className={classes.form}>
//     //           <Typography variant="h3" className={classes.lineHeight1}>
//     //             {location?.state?.from === 'subscribeButton' ? (
//     //               <Typography variant="h1" className={classes.lineHeight1}>
//     //                 Sign Up
//     //               </Typography>
//     //             ) : (
//     //               <Typography variant="h3" className={classes.lineHeight1}>
//     //                 {location?.state ? 'Start your 3-day free trial' : 'Sign Up'}
//     //               </Typography>
//     //             )}
//     //           </Typography>
//     //           <Typography gutterBottom className={classes.content}>
//     //           Embark on a transformative learning journey with keySkillset with our state-of-the-art Learning Management System
//     //           </Typography>
//     //           <Formik
//     //             enableReinitialize
//     //             initialValues={formValues}
//     //             validationSchema={Yup.object().shape({
//     //               firstName: Yup.string()
//     //                 .trim()
//     //                 // .min(3, 'First name must be at least 3 characters')
//     //                 .matches(/^[A-Za-z ]+$/, 'Only alphabets are allowed for this field')
//     //                 .required('First name is required '),
//     //               lastName: Yup.string()
//     //                 .trim()
//     //                 .matches(/^[A-Za-z ]+$/, 'Only alphabets are allowed for this field')
//     //                 .required('Last name is required '),
//     //               email: Yup.string().required('Email is required').email('Email must be valid email'),
//     //               phone: Yup.string().trim().nullable(),
//     //               // .min(11, 'Contact phone must be at least 10 characters')
//     //               // .max(15, 'Contact phone must be with in 14 characters'),
//     //               // .matches('^([0|[0-9]{1,5})?([2-9][0-9]{9})$', 'Invalid Phone number')

//     //               /* password: Yup.string()
//     //                 .trim()
//     //                 .min(8)
//     //                 .nullable()
//     //                 .matches(
//     //                   /^.*(?=.{6,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
//     //                   'The password must contain 8 characters, one upper case(A-Z), one number(0-9) and one special case character (eg:@#!)'
//     //                 )
//     //                 .required('Password is Required.'), */
//     //             })}
//     //             onSubmit={(values) => {
//     //               websiteUserLogin(values);
//     //             }}
//     //           >
//     //             {({ errors, handleBlur, handleChange, setFieldValue, touched, values }) => (
//     //               <Form>

//     //                 <Grid container spacing={2}>
//     //                   <Grid item xs={12} sm={6}>
//     //                     <Typography variant="subtitle1" gutterBottom>
//     //                       First Name*
//     //                     </Typography>
//     //                     <TextField
//     //                      fullWidth
//     //                       id="outlined-basic"
//     //                       name="firstName"
//     //                       placeholder="First Name"
//     //                       onBlur={handleBlur}
//     //                       onChange={handleChange}
//     //                       type="text"
//     //                       value={values.firstName}
//     //                       variant="outlined"
//     //                       error={Boolean(touched.firstName && errors.firstName)}
//     //                       helperText={touched.firstName && errors.firstName}
//     //                       inputProps={{ maxLength: 180 }}
//     //                     />
//     //                     <ErrorFocus />
//     //                   </Grid>

//     //                   <Grid item xs={12} sm={6}>

//     //                     <Typography variant="subtitle1" gutterBottom>
//     //                       Last Name*
//     //                     </Typography>
//     //                     <TextField
//     //                       fullWidth
//     //                       id="outlined-basic"
//     //                       name="lastName"
//     //                       placeholder="Last Name"
//     //                       onBlur={handleBlur}
//     //                       onChange={handleChange}
//     //                       type="text"
//     //                       value={values.lastName}
//     //                       variant="outlined"
//     //                       error={Boolean(touched.lastName && errors.lastName)}
//     //                       helperText={touched.lastName && errors.lastName}
//     //                       inputProps={{ maxLength: 180 }}
//     //                     />
//     //                     <ErrorFocus />
//     //                   </Grid>
//     //                   <Grid item xs={12} sm={6}>
//     //                     <Typography variant="subtitle1" gutterBottom>
//     //                       Email*
//     //                     </Typography>
//     //                     <TextField
//     //                       fullWidth
//     //                       id="outlined-basic"
//     //                       name="email"
//     //                       placeholder="you@company.com"
//     //                       onBlur={handleBlur}
//     //                       onChange={handleChange}
//     //                       // disabled={props.mode === 'edit'}
//     //                       type="text"
//     //                       value={values.email}
//     //                       variant="outlined"
//     //                       error={Boolean(touched.email && errors.email)}
//     //                       helperText={touched.email && errors.email}
//     //                     />
//     //                     <ErrorFocus />
//     //                   </Grid>
//     //                   <Grid item xs={12} sm={6}>
//     //                     <Typography variant="subtitle1" className={classes.formName} gutterBottom>
//     //                       Phone
//     //                     </Typography>
//     //                     <PhoneNumber
//     //                       sx={{ '.form-control': { background: 'transparent ! important' } }}
//     //                       fullWidth
//     //                       country={'us'}
//     //                       specialLabel={''}
//     //                     />
//     //                   </Grid>
//     //                   {/* <Grid item xs="12">
//     //                     <Typography variant="subtitle1" gutterBottom sx={{ display: 'flex' }}>
//     //                       Create Password* &nbsp;
//     //                       <Tooltip title="Password should contains at least one uppercase (A-Z), lowercase(a-z), special character (eg:!@#) and number (0-9).">
//     //                         <InfoOutlinedIcon color="inherit" fontSize="small" />
//     //                       </Tooltip>
//     //                     </Typography>

//     //                     <TextField
//     //                       fullWidth
//     //                       name="password"
//     //                       variant="outlined"
//     //                       placeholder="Password"
//     //                       type={visible ? 'text' : 'password'}
//     //                       onBlur={handleBlur}
//     //                       onChange={(e) => {
//     //                         setFieldValue('password', e.target.value.trim());
//     //                       }}
//     //                       error={Boolean(touched.password && errors.password)}
//     //                       helperText={touched.password && errors.password}
//     //                       value={values.password}
//     //                       InputProps={{
//     //                         endAdornment: (
//     //                           <InputAdornment position="end">
//     //                             {visible ? (
//     //                               <VisibilityIcon className={classes.visibleIcon} onClick={handleClickShowPassword} />
//     //                             ) : (
//     //                               <VisibilityOffIcon
//     //                                 className={classes.visibleIcon}
//     //                                 onClick={handleClickShowPassword}
//     //                               />
//     //                             )}
//     //                           </InputAdornment>
//     //                         ),
//     //                       }}
//     //                     />
//     //                   </Grid> */}

//     //                   {/* <Grid item xs="6" sx={{ paddingTop: '4px !important' }}>
//     //                     <FormControl>
//     //                       <FormLabel>
//     //                         <Typography
//     //                           sx={{ fontWeight: '500', color: '#000', marginTop: '1rem' }}
//     //                           variant="subtitle1"
//     //                         >
//     //                           Are you a student?
//     //                         </Typography>
//     //                       </FormLabel>
//     //                       <RadioGroup
//     //                         row
//     //                         aria-labelledby="demo-radio-buttons-group-label"
//     //                         defaultValue="female"
//     //                         name="isStudent"
//     //                         onChange={(event) => {
//     //                           setFieldValue('isStudent', event.target.value);
//     //                         }}
//     //                         value={values.isStudent}
//     //                       >
//     //                         <FormControlLabel value={'YES'} control={<Radio />} label={'yes'} />
//     //                         <FormControlLabel value={'NO'} control={<Radio />} label={'no'} />
//     //                       </RadioGroup>
//     //                       <FormHelperText style={{ color: '#F44336' }}>
//     //                         {touched.isStudent && errors.isStudent}
//     //                       </FormHelperText>
//     //                     </FormControl>
//     //                   </Grid> */}
//     //                   {/* 
//     //                   {values.isStudent === 'YES' && (
//     //                     <>
//     //                       <Grid item xs={12} sm={12} md={12}>
//     //                         <Typography variant="subtitle1" gutterBottom>
//     //                           Student Id*
//     //                         </Typography>
//     //                         <TextField
//     //                           fullWidth
//     //                           id="outlined-basic"
//     //                           name="studentId"
//     //                           placeholder="Student Id"
//     //                           onBlur={handleBlur}
//     //                           onChange={handleChange}
//     //                           type="text"
//     //                           value={values.studentId}
//     //                           variant="outlined"
//     //                           error={Boolean(touched.studentId && errors.studentId)}
//     //                           helperText={touched.studentId && errors.studentId}
//     //                           inputProps={{ maxLength: 180 }}
//     //                         />
//     //                         <ErrorFocus />
//     //                       </Grid>

//     //                       <Grid item xs={12} sm={12} md={12}>
//     //                         <Typography variant="subtitle1" gutterBottom>
//     //                           Institution Name*
//     //                         </Typography>
//     //                         <TextField
//     //                           fullWidth
//     //                           id="outlined-basic"
//     //                           name="institutionName"
//     //                           placeholder="Institution Name"
//     //                           onBlur={handleBlur}
//     //                           onChange={handleChange}
//     //                           type="text"
//     //                           value={values.institutionName}
//     //                           variant="outlined"
//     //                           error={Boolean(touched.institutionName && errors.institutionName)}
//     //                           helperText={touched.institutionName && errors.institutionName}
//     //                           inputProps={{ maxLength: 180 }}
//     //                         />
//     //                         <ErrorFocus />
//     //                       </Grid>
//     //                     </>
//     //                   )} */}

//     //                   <Grid item xs={12}>
//     //                     <LoadingButton
//     //                       fullWidth
//     //                       size="medium"
//     //                       type="submit"
//     //                       variant="contained"
//     //                       loading={loading}
//     //                       style={{borderRadius:'3px',marginTop:'10px'}}
//     //                     >
//     //                       Create An Account
//     //                     </LoadingButton>
//     //                   </Grid>
//     //                   <Grid align="center" item xs="12">
//     //                     <Typography variant="body2" sx={{ mt: 1, display: 'flex', justifyContent: 'center' }}>
//     //                       Already have an account ? &nbsp;
//     //                       {searchParams.get('isSubscribe') ? (
//     //                         <Typography
//     //                           color="primary"
//     //                           component={RouterLink}
//     //                           className={classes.create}
//     //                           // to={'/login'}
//     //                           to={{
//     //                             pathname: '/login',
//     //                             search: '?isSubscribe=true',
//     //                           }}
//     //                         >
//     //                           Login
//     //                         </Typography>
//     //                       ) : (
//     //                         <Typography color="primary" component={RouterLink} className={classes.create} to={'/login'}>
//     //                           Login
//     //                         </Typography>
//     //                       )}
//     //                     </Typography>
//     //                   </Grid>
//     //                 </Grid>
//     //               </Form>
//     //             )}
//     //           </Formik>
//     //           <SnackBar open={snackbar} snackbarTitle={snackbarTitle} close={() => setSnackbar(false)} />
//     //         </div>
//     //       </Grid>
//     //       <Grid item xs={12} md={5} style={{padding:'0px'}}>
//     //       <img src={RegisterImage}  className={classes.image} alt="Register" />
//     //       </Grid>
//     //       </Grid>
//     //   </div>
//     // </WebPage>

//     // new Design for sign up page 
//     <Box className={classes.mainContainer}>
//       <Container maxWidth="lg">
//         <Grid container spacing={2} alignItems="stretch">
//           {/* <Grid item xs={12} md={6.5} className={classes.boxContainer} style={{ backgroundImage: `url(${images[index]})` }}> </Grid> */}
//           <Grid item xs={12} md={7} >
//             <Carousel autoPlay
//               interval={7000}
//               showArrows={false}
//               showStatus={false}
//               axis='horizontal'
//               infiniteLoop
//               showThumbs={false}
//               showIndicators={false}
//               className={classes.carouselContainer}
//             >
//               {images.map((image, idx) => (
//                 <div key={idx}>
//                   <img alt={` ${idx + 1}`} src={image} style={{ width: '100%', height: '540px' }} />
//                 </div>
//               ))}
//             </Carousel>
//           </Grid>
//           <Grid item xs={12} md={5}>
//             <Box className={classes.formcontainer}>
//               <Formik
//                 enableReinitialize
//                 initialValues={formValues}
//                 validationSchema={Yup.object().shape({
//                   firstName: Yup.string()
//                     .trim()
//                     // .min(3, 'First name must be at least 3 characters')
//                     .matches(/^[A-Za-z ]+$/, 'Only alphabets are allowed for this field')
//                     .required(t('first_name_required')),
//                   lastName: Yup.string()
//                     .trim()
//                     .matches(/^[A-Za-z ]+$/, 'Only alphabets are allowed for this field')
//                     .required(t('last_name_required')),
//                   email: Yup.string().required(t('email_required')).email('Email must be valid email'),
//                   phone: Yup.string().trim().nullable(),
//                   // .min(11, 'Contact phone must be at least 10 characters')
//                   // .max(15, 'Contact phone must be with in 14 characters'),
//                   // .matches('^([0|[0-9]{1,5})?([2-9][0-9]{9})$', 'Invalid Phone number')

//                   /* password: Yup.string()
//                     .trim()
//                     .min(8)
//                     .nullable()
//                     .matches(
//                       /^.*(?=.{6,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
//                       'The password must contain 8 characters, one upper case(A-Z), one number(0-9) and one special case character (eg:@#!)'
//                     )
//                     .required('Password is Required.'), */
//                 })}
//                 onSubmit={(values) => {
//                   websiteUserLogin(values);
//                 }}
//               >
//                 {({ errors, handleBlur, handleChange, setFieldValue, touched, values }) => (
//                   <Form>
//                     {process.env.REACT_APP_ENV === 'dev' &&  <LanguageSwitcher />}
//                     <Grid container spacing={2}>
//                       <Grid item xs="12">
//                         <Typography color="#000000" className={classes.login} align="left" variant="h4">
//                         {t('signUp')}
//                         </Typography>
//                       </Grid>
//                       <Grid item xs="12" style={{marginBottom:"20px", marginTop:"-10px"}}>
//                         <Typography  style={{fontSize:"14px",color:"#494949",fontWeight:"400"}} >
//                         {t('signUpHeader')}
//                         </Typography>
//                       </Grid>
//                       <Grid item xs={12} sm={6}>
//                         {/* <Typography variant="subtitle1" gutterBottom>
//                           First Name*
//                         </Typography> */}
//                         <TextField
//                           fullWidth
//                           name="firstName"
//                           placeholder={t('firstName')}
//                           variant="outlined"
//                           color="success"
//                           onBlur={handleBlur}
//                           onChange={handleChange}
//                           type="text"
//                           value={values.firstName}
//                           // variant="outlined"
//                           error={Boolean(touched.firstName && errors.firstName)}
//                           helperText={touched.firstName && errors.firstName}
//                           inputProps={{ maxLength: 180 }}
//                         />
//                         <ErrorFocus />
//                       </Grid>

//                       <Grid item xs={12} sm={6}>

//                         {/* <Typography variant="subtitle1" gutterBottom>
//                           Last Name*
//                         </Typography> */}
//                         <TextField
//                           fullWidth
//                           id="outlined-basic"
//                           name="lastName"
//                           placeholder={t('lastName')}
//                           color="success"
//                           onBlur={handleBlur}
//                           onChange={handleChange}
//                           type="text"
//                           value={values.lastName}
//                           variant="outlined"
//                           error={Boolean(touched.lastName && errors.lastName)}
//                           helperText={touched.lastName && errors.lastName}
//                           inputProps={{ maxLength: 180 }}
//                         />
//                         <ErrorFocus />
//                       </Grid>
//                       <Grid item xs={12} sm={12}>
//                         {/* <Typography variant="subtitle1" gutterBottom>
//                           Email*
//                         </Typography> */}
//                         <TextField
//                           fullWidth
//                           id="outlined-basic"
//                           name="email"
//                           color="success"
//                           placeholder={t('email')}
//                           onBlur={handleBlur}
//                           onChange={handleChange}
//                           // disabled={props.mode === 'edit'}
//                           type="text"
//                           value={values.email}
//                           variant="outlined"
//                           error={Boolean(touched.email && errors.email)}
//                           helperText={touched.email && errors.email}
//                         />
//                         <ErrorFocus />
//                       </Grid>
//                       {/* <Grid item xs={12} sm={6}> */}
//                         {/* <Typography variant="subtitle1" className={classes.formName} gutterBottom>
//                           Phone
//                         </Typography> */}
//                         {/* <PhoneNumber
//                           sx={{ '.form-control': { background: 'red ! important'}}}
//                           fullWidth
//                           country={'us'}
//                           specialLabel={''}
//                           // placeholder="Phone"
//                         />
//                       </Grid> */}
//                       {/* <Grid item xs="12">
//                         <Typography variant="subtitle1" gutterBottom sx={{ display: 'flex' }}>
//                           Create Password* &nbsp;
//                           <Tooltip title="Password should contains at least one uppercase (A-Z), lowercase(a-z), special character (eg:!@#) and number (0-9).">
//                             <InfoOutlinedIcon color="inherit" fontSize="small" />
//                           </Tooltip>
//                         </Typography>

//                         <TextField
//                           fullWidth
//                           name="password"
//                           variant="outlined"
//                           placeholder="Password"
//                           type={visible ? 'text' : 'password'}
//                           onBlur={handleBlur}
//                           onChange={(e) => {
//                             setFieldValue('password', e.target.value.trim());
//                           }}
//                           error={Boolean(touched.password && errors.password)}
//                           helperText={touched.password && errors.password}
//                           value={values.password}
//                           InputProps={{
//                             endAdornment: (
//                               <InputAdornment position="end">
//                                 {visible ? (
//                                   <VisibilityIcon className={classes.visibleIcon} onClick={handleClickShowPassword} />
//                                 ) : (
//                                   <VisibilityOffIcon
//                                     className={classes.visibleIcon}
//                                     onClick={handleClickShowPassword}
//                                   />
//                                 )}
//                               </InputAdornment>
//                             ),
//                           }}
//                         />
//                       </Grid> */}

//                       {/* <Grid item xs="6" sx={{ paddingTop: '4px !important' }}>
//                         <FormControl>
//                           <FormLabel>
//                             <Typography
//                               sx={{ fontWeight: '500', color: '#000', marginTop: '1rem' }}
//                               variant="subtitle1"
//                             >
//                               Are you a student?
//                             </Typography>
//                           </FormLabel>
//                           <RadioGroup
//                             row
//                             aria-labelledby="demo-radio-buttons-group-label"
//                             defaultValue="female"
//                             name="isStudent"
//                             onChange={(event) => {
//                               setFieldValue('isStudent', event.target.value);
//                             }}
//                             value={values.isStudent}
//                           >
//                             <FormControlLabel value={'YES'} control={<Radio />} label={'yes'} />
//                             <FormControlLabel value={'NO'} control={<Radio />} label={'no'} />
//                           </RadioGroup>
//                           <FormHelperText style={{ color: '#F44336' }}>
//                             {touched.isStudent && errors.isStudent}
//                           </FormHelperText>
//                         </FormControl>
//                       </Grid> */}
//                       {/* 
//                       {values.isStudent === 'YES' && (
//                         <>
//                           <Grid item xs={12} sm={12} md={12}>
//                             <Typography variant="subtitle1" gutterBottom>
//                               Student Id*
//                             </Typography>
//                             <TextField
//                               fullWidth
//                               id="outlined-basic"
//                               name="studentId"
//                               placeholder="Student Id"
//                               onBlur={handleBlur}
//                               onChange={handleChange}
//                               type="text"
//                               value={values.studentId}
//                               variant="outlined"
//                               error={Boolean(touched.studentId && errors.studentId)}
//                               helperText={touched.studentId && errors.studentId}
//                               inputProps={{ maxLength: 180 }}
//                             />
//                             <ErrorFocus />
//                           </Grid>

//                           <Grid item xs={12} sm={12} md={12}>
//                             <Typography variant="subtitle1" gutterBottom>
//                               Institution Name*
//                             </Typography>
//                             <TextField
//                               fullWidth
//                               id="outlined-basic"
//                               name="institutionName"
//                               placeholder="Institution Name"
//                               onBlur={handleBlur}
//                               onChange={handleChange}
//                               type="text"
//                               value={values.institutionName}
//                               variant="outlined"
//                               error={Boolean(touched.institutionName && errors.institutionName)}
//                               helperText={touched.institutionName && errors.institutionName}
//                               inputProps={{ maxLength: 180 }}
//                             />
//                             <ErrorFocus />
//                           </Grid>
//                         </>
//                       )} */}

//                       <Grid item xs={12}>
//                         <LoadingButton
//                           fullWidth
//                           size="medium"
//                           type="submit"
//                           variant="contained"
//                           loading={loading}
//                           className={classes.createButton}
//                         >
//                           {t('createAccount')}
//                         </LoadingButton>
//                       </Grid>
//                       <Grid xs={12} className={classes.whiteBorder}>
//                         <span>  </span>
//                       </Grid>
//                       {/* <Grid item xs={12}>
//                         <Typography
//                         className={classes.text_one}
//                         >
//                         By signing up, you agree to our Terms of Service and Privacy Policy
//                         </Typography>
//                       </Grid> */}
//                       <Grid align="center" item xs="12">
//                         <Typography variant="body2" sx={{ mt: 1, display: 'flex', justifyContent: "flex-start", marginTop:"35px",fontSize:"13px" }}>
//                         {t('alreadyHaveAccount')} &nbsp;
//                           {searchParams.get('isSubscribe') ? (
//                             <Typography
//                               color="primary"
//                               component={RouterLink}
//                               className={classes.create}
//                               // to={'/login'}
//                               to={{
//                                 pathname: '/login',
//                                 search: '?isSubscribe=true',
//                               }}
//                             >
//                               {t('login')}
//                             </Typography>
//                           ) : (
//                             <Typography color="primary" component={RouterLink} className={classes.create} to={'/login'}>
//                               {t('login')}
//                             </Typography>
//                           )}
//                         </Typography>
//                       </Grid>
//                       {/* <Grid item xs={12}>
//                       <Typography className={classes.email}>
//                           For login related issue, &nbsp;
//                           <RouterLink
//                             to={{
//                               // pathname: '/sign-up',
//                               // search: '?isSubscribe=true',
//                             }}
//                             // state={location.state}
//                             className={classes.signup}
//                           >
//                             Get Help
//                           </RouterLink>
//                         </Typography>
//                       </Grid> */}
//                     </Grid>
//                   </Form>
//                 )}
//               </Formik>
//             </Box>
//           </Grid>
//         </Grid>
//       </Container>
//       {/* <SnackBar open={false} snackbarTitle={''} close={() => {}} />  */}
//         <SnackBar open={snackbar} snackbarTitle={snackbarTitle} close={() => setSnackbar(false)} />
//     </Box>
   
//   );
// };

// const useStyles = makeStyles((theme) => ({
//   image: {
//     height: 'auto',
//     width: '100%',
//     marginTop: '155px',
//     '@media (max-width: 600px)': {
//       marginTop: '20px'
//     }
//   },


//   container: {
//     maxWidth: '1440px',
//     margin: 'auto',
//   },
//   lineHeight1: {
//     marginTop: '70px',
//     marginBottom: '20px',
//     fontSize: '22px',
//     color: '#101828',
//   },
//   about: {
//     color: '#000000',
//     fontSize: '0.95rem',
//     fontWeight: '450',
//     textAlign: 'center',
//   },


//   contentalignment: {
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center',
//     margin: '2.5rem 0rem 4rem 0rem',
//     flexWrap: 'wrap',
//   },
//   create: {
//     color: '#D52F37',
//     fontSize: '13px',
//     cursor: 'pointer',
//     fontWeight: '450',
//     margin: '0px 10px 0px 0px',
//     textDecoration: 'none',
//   },

//   cardscontainer: {
//     height: 'auto',
//     backgroundColor: '#E7F7EF',
//     padding: '4rem 6.5rem',
//     '@media (max-width: 640px)': {
//       padding: '2rem 1.5rem',
//     },
//   },
//   headingHands: {
//     color: '#FFFFFF',
//     fontSize: '1.2rem',
//     marginLeft: '14px',
//   },
//   hands: {
//     maxWidth: '84%',
//     color: '#FFFFFF',
//     fontSize: '1rem',
//     fontWeight: 200,
//     [theme.breakpoints.down('md')]: {
//       maxWidth: '100%',
//     },
//   },
//   form: {
//     paddingRight: '0px',
//     paddingLeft: '120px',
//     paddingTop: '64px',
//     paddingBottom: '64px',


//     '@media (max-width: 1199px)': {
//       padding: '2rem 4rem 4rem 5rem',
//     },
//     [theme.breakpoints.down('md')]: {
//       padding: '2rem 5rem 4rem 5rem',
//     },
//     [theme.breakpoints.down('sm')]: {
//       padding: '2rem 2rem 0rem 2rem',
//     },
//     '@media (max-width: 400px)': {
//       padding: '2rem 1rem 3rem 1rem',
//     },
//   },
//   content: {
//     fontSize: '1.1rem',
//     color: '#667085',
//     marginBottom: '2rem',
//   },
//   started: {
//     backgroundColor: '#EC7930',
//     borderRadius: '100px',
//     color: '#fff',
//     padding: '8px',
//     fontWeight: '400',
//     textTransform: 'capitalize ! important',
//     marginTop: '1rem',
//     '&:hover': {
//       backgroundColor: '#EC7930',
//       boxShadow: '0 0 1px 6px rgba(236, 121, 48, 40%),0 0 1px 0px rgba(236,121,48, 20%)',
//     },
//   },
//   icon: {
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'flex-start',
//   },



//   mainContainer: {
//     padding: "30px 0",
//     background: "#F0F0F0",
//     height: '90vh',
//     marginTop: "4rem",
//     '@media (max-width: 767px)': {
//       padding: "20px 0",
//     },
//   },
//   boxContainer: {
//     backgroundImage: `url(${BackgroundImg})`,
//     backgroundSize: 'cover',
//     backgroundRepeat: 'no-repeat',
//     // height: '100vh',
//     width: "500px",
//     minHeight: "532px",
//     marginTop: "12px",
//     borderRadius: "20px",
//     // animationName: "changeBackground",
//     // animationDuration: "12s",
//     // animationIterationCount: "infinite",
//     // display: 'flex',
//     // flexDirection: 'column',
//     // justifyContent: 'center',
//     '@media (max-width: 767px)': {
//       height: '240px',
//       marginTop: '70px'
//     },

//   },
//   formcontainer: {
//     background: "#ffffff",
//     padding: "36px",
//     borderRadius: "20px",
//     minHeight: "532px",
//     marginTop:"4px",

//   },
//   login: {
//     marginBottom: theme.spacing(2),
//     marginTop:"10px",
//   },
//   createButton:{
//     borderRadius: '5px',
//     marginTop: '20px',
//     background:"#D52F37",
//     fontSize:"12px",
//   },
//   email: {
//     padding: '2px',
//     fontSize:"13px",
//     fontStyle:"normal",
//     fontWeight:"400"
//   },
//   signup: {
//     color: "#D52F37"
//   },
//   text_one: {
//     fontSize: "12px",
//     alignItems: "center",
//     justifyContent: "center",
//     display: "flex",
//     // marginTop: "10px",
//   },
//   carouselContainer: {
//     backgroundColor: 'transparent', 
//     maxWidth: '100%', 
//     margin: 'auto', 
//   },
//   whiteBorder:{
//     background:"whitesmoke",
//     marginTop:'41px',
//     height:"1px",
//     padding:'1px',
//     borderRadius:'5px',
//     marginLeft:'15px'
//   }
// }));
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  Typography,
  Box, Button,
  // FormControl,
  // FormGroup,
  // FormLabel,
  // RadioGroup,
  // FormHelperText,
  // Radio,
  // FormControlLabel,
  TextField,
  Stack,
  Container
  // Divider,
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Link as RouterLink, useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { useDispatch } from 'react-redux';
import Cookies from 'js-cookie';
import jwtdecode from 'jwt-decode';
import { Carousel } from 'react-responsive-carousel';
import LanguageSwitcher from './LanguageSwitcher';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ErrorFocus from '../../components/ErrorFocus/ErrorFocus';
import SnackBar from '../../components/snackbar/snackbar';
import WebPage from '../components/WebPage';
import Check from '../images/CheckIcon.png';
import loginServices from '../../services/loginServices';
import RegisterImage from '../../assets/logo/images/Register.png'
// import userServices from '../../services/loginServices';
import trialAndStripeSubscriptionService from '../../services/trialAndStripeSubscriptionService';
// import RightIcon from '../images/RightIcon2.png';
// import { Course } from '../components/Course';
import PhoneNumber from '../../components/PhoneNumber/Index';
import apiClient from '../../services/apiClient';
import courseApi from '../../services/users/courseApi';
import BackgroundImg from '../../assets/logo/images/bg.jpg';
import BackgroundImges from '../../assets/logo/images/BackgroundImgs.png';
import BackgroundImges1 from '../../assets/logo/images/backgroundImage1.png';
import BackgroundImges2 from '../../assets/logo/images/backgroundImage2.png';
import BackgroundImges3 from '../../assets/logo/images/backgroundImage3.png';
import BackgroundImges4 from '../../assets/logo/images/backgroundImage4.png';
import BackgroundImges5 from '../../assets/logo/images/backgroundImage5.png';
import {
  loginSuccess,
  openSubscriptionModal,
  setSubscribedCourses,
  setOpenSubscriptionModalDetails,
  openSnackbar,
} from '../../store/reducer';
// import './index.css';
import Register from "./Signup"

export const GetStarted = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation('translation');
  // console.log('t is ', t);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = React.useState(false);
  const [snackbar, setSnackbar] = useState(false);
  const [snackbarTitle, setSnackbarTitle] = useState('');
  const [searchParams] = useSearchParams();
  const [initialcomponent, setInitialComponent] = useState(true);
  const [userDetails, setUserDetails] = useState('');
  


  // const [formValues, setFormValues] = React.useState({
  const [formValues] = React.useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    // password: '',
    // confirmPassword: '',
  });

  // console.log(searchParams.get('isSubscribe'));



  const getLoginUserDetails = async () => {
    try {
      const response3 = await loginServices.getUserInfo();
      if (response3.ok) {
        dispatch(loginSuccess(response3.data));
        await getCourserList();
        if (response3.data.role === 'CONTENT_WRITER' || response3.data.role === 'AUTH_USER') {
          navigate('/app/course');
        } else if (response3.data.role === 'USER_DTC') {
          if (location?.state?.from === 'trialButton') {
            await postTrialCourseDetails(location?.state?.planId);
          } else if (location?.state?.from === 'subscribeButton') {
            await postSubscribeDetails(location?.state?.planId);
          } else {
            navigate('/auth/subscribe');
          }
        } else {
          navigate('/app/dashboard');
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getCourserList = async () => {
    try {
      const response = await courseApi.getCourseList();
      if (response.ok) {
        dispatch(setSubscribedCourses(response.data));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const postTrialCourseDetails = async (planId) => {
    try {
      const res = await trialAndStripeSubscriptionService.postTrialCourseEnrollmentDetails(JSON.stringify({ planId }));
      if (res.ok) {
        await getCourserList();

        dispatch(openSnackbar('Trial Course Subscribed Successfully.'));
        navigate('/auth/my-courses');
        // window.location.href = '/auth/my-courses';
      } else {
        if (res?.data?.message) {
          dispatch(openSnackbar(res.data.message));
        } else {
          dispatch(openSnackbar('Failed to Subscribe Trial Course, Please try again.'));
        }
        navigate('/auth/my-courses');
        // window.location.href = '/auth/my-courses';
      }
    } catch (error) {
      console.log(error);
    }
  };

  const postSubscribeDetails = async (planId) => {
    dispatch(setOpenSubscriptionModalDetails(location?.state?.openSubscriptionModalDetails));
    dispatch(openSubscriptionModal(planId));
    navigate('/auth/my-courses');
    // window.location.href = '/auth/my-courses';
  };

  const websiteUserLogin = async (values) => {
    console.log(values);
    setLoading(true);

    const resetData = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      phone: values.phone,
      // password: values.password,
      // confirmPassword: values.password,
    };

    try {
      const response = await loginServices.postUserLogin(resetData);
      console.log(response.data,"rrrrrrrrrrrrrr");
      

      if (response.ok) {
        CreateButton(values)
        setUserDetails(response.data.userId)
        apiClient.setHeader('Authorization', `BEARER ${response.data.token}`);
        Cookies.set('token', response.data.token);
        Cookies.set('refreshToken', response.data.refreshToken);
        Cookies.set('isLogIn', true);
        const decoded = await jwtdecode(response.data.token);

        Cookies.set('tokenExpireTime', decoded.exp);
        // await getLoginUserDetails();     
        setSnackbarTitle("Account Created!");
        setSnackbar(true);
      }
      else {
        console.log('response...', response.data.message)
        setSnackbarTitle(response.data.message);
        setSnackbar(true);
      }

      setLoading(false);

    } catch (error) {
      console.log(error);
    }
  };

  const handleClickShowPassword = () => {
    setVisible(!visible);
  };
  const switchLanguage = (language) => {
    console.log('Switching language to:', language);
    // Your language switching logic here
  };

  const images = [BackgroundImges1, BackgroundImges2, BackgroundImges3, BackgroundImges4, BackgroundImges5];
  const [index, setIndex] = useState(0);
  const [details, setDetails] = useState("");


  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
    }, 2000); 
    return () => clearInterval(interval);
  }, []);


  const CreateButton = (data) => {
    console.log(data,"data");
      setInitialComponent(false) 
      setDetails(data)
  }


  

 

  return (

    <>
      {initialcomponent === true ?
        <Box className={classes.mainContainer}>
          <Container maxWidth="lg">
            <Grid container spacing={2} alignItems="stretch">
              <Grid item xs={12} md={7} >
                <Carousel autoPlay
                  interval={7000}
                  showArrows={false}
                  showStatus={false}
                  axis='horizontal'
                  infiniteLoop
                  showThumbs={false}
                  showIndicators={false}
                  className={classes.carouselContainer}
                >
                  {images.map((image, idx) => (
                    <div key={idx}>
                      <img alt={` ${idx + 1}`} src={image} style={{ width: '100%', height: '540px' }} />
                    </div>
                  ))}
                </Carousel>
              </Grid>
              <Grid item xs={12} md={5}>
                <Box className={classes.formcontainer}>
                  <Formik
                    enableReinitialize
                    initialValues={formValues}
                    validationSchema={Yup.object().shape({
                      firstName: Yup.string()
                        .trim()
                        // .min(3, 'First name must be at least 3 characters')
                        .matches(/^[A-Za-z ]+$/, 'Only alphabets are allowed for this field')
                        .required(t('first_name_required')),
                      lastName: Yup.string()
                        .trim()
                        .matches(/^[A-Za-z ]+$/, 'Only alphabets are allowed for this field')
                        .required(t('last_name_required')),
                      email: Yup.string().required(t('email_required')).email('Email must be valid email'),
                      phone: Yup.string().trim().nullable(),
                      // .min(11, 'Contact phone must be at least 10 characters')
                      // .max(15, 'Contact phone must be with in 14 characters'),
                      // .matches('^([0|[0-9]{1,5})?([2-9][0-9]{9})$', 'Invalid Phone number')

                      /* password: Yup.string()
                        .trim()
                        .min(8)
                        .nullable()
                        .matches(
                          /^.*(?=.{6,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
                          'The password must contain 8 characters, one upper case(A-Z), one number(0-9) and one special case character (eg:@#!)'
                        )
                        .required('Password is Required.'), */
                    })}
                    onSubmit={(values) => {
                     
                      websiteUserLogin(values);
                    }}
                  >
                    {({ errors, handleBlur, handleChange, setFieldValue, touched, values }) => (
                      <Form>
                        {process.env.REACT_APP_ENV === 'dev' && <LanguageSwitcher />}
                        <Grid container spacing={2}>
                          <Grid item xs="12">
                            <Typography color="#000000" className={classes.login} align="left" variant="h4">
                              {t('signUp')}
                            </Typography>
                          </Grid>
                          <Grid item xs="12" style={{ marginBottom: "20px", marginTop: "-10px" }}>
                            <Typography style={{ fontSize: "14px", color: "#494949", fontWeight: "400" }} >
                              {t('signUpHeader')}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            {/* <Typography variant="subtitle1" gutterBottom>
                          First Name*
                        </Typography> */}
                            <TextField
                              fullWidth
                              name="firstName"
                              placeholder={t('firstName')}
                              variant="outlined"
                              color="success"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              type="text"
                              value={values.firstName}
                              // variant="outlined"
                              error={Boolean(touched.firstName && errors.firstName)}
                              helperText={touched.firstName && errors.firstName}
                              inputProps={{ maxLength: 180 }}
                            />
                            <ErrorFocus />
                          </Grid>

                          <Grid item xs={12} sm={6}>

                            {/* <Typography variant="subtitle1" gutterBottom>
                          Last Name*
                        </Typography> */}
                            <TextField
                              fullWidth
                              id="outlined-basic"
                              name="lastName"
                              placeholder={t('lastName')}
                              color="success"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              type="text"
                              value={values.lastName}
                              variant="outlined"
                              error={Boolean(touched.lastName && errors.lastName)}
                              helperText={touched.lastName && errors.lastName}
                              inputProps={{ maxLength: 180 }}
                            />
                            <ErrorFocus />
                          </Grid>
                          <Grid item xs={12} sm={12}>
                            {/* <Typography variant="subtitle1" gutterBottom>
                          Email*
                        </Typography> */}
                            <TextField
                              fullWidth
                              id="outlined-basic"
                              name="email"
                              color="success"
                              placeholder={t('email')}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              // disabled={props.mode === 'edit'}
                              type="text"
                              value={values.email}
                              variant="outlined"
                              error={Boolean(touched.email && errors.email)}
                              helperText={touched.email && errors.email}
                            />
                            <ErrorFocus />
                          </Grid>


                          <Grid item xs={12}>
                            <LoadingButton
                          fullWidth
                          size="medium"
                          type="submit"
                          variant="contained"
                          loading={loading}
                          className={classes.createButton}
                        >
                          {t('createAccount')}
                        </LoadingButton>
                            {/* <Button onClick={() => CreateButton(values)}>Create an Account</Button> */}
                          </Grid>
                          <Grid xs={12} className={classes.whiteBorder}>
                            <span>  </span>
                          </Grid>
                          {/* <Grid item xs={12}>
                        <Typography
                        className={classes.text_one}
                        >
                        By signing up, you agree to our Terms of Service and Privacy Policy
                        </Typography>
                      </Grid> */}
                          <Grid align="center" item xs="12">
                            <Typography variant="body2" sx={{ mt: 1, display: 'flex', justifyContent: "flex-start", marginTop: "35px", fontSize: "13px" }}>
                              {t('alreadyHaveAccount')} &nbsp;
                              {searchParams.get('isSubscribe') ? (
                                <Typography
                                  color="primary"
                                  component={RouterLink}
                                  className={classes.create}
                                  // to={'/login'}
                                  to={{
                                    pathname: '/login',
                                    search: '?isSubscribe=true',
                                  }}
                                >
                                  {t('login')}
                                </Typography>
                              ) : (
                                <Typography color="primary" component={RouterLink} className={classes.create} to={'/login'}>
                                  {t('login')}
                                </Typography>
                              )}
                            </Typography>
                          </Grid>
                          {/* <Grid item xs={12}>
                      <Typography className={classes.email}>
                          For login related issue, &nbsp;
                          <RouterLink
                            to={{
                              // pathname: '/sign-up',
                              // search: '?isSubscribe=true',
                            }}
                            // state={location.state}
                            className={classes.signup}
                          >
                            Get Help
                          </RouterLink>
                        </Typography>
                      </Grid> */}
                        </Grid>
                      </Form>
                    )}
                  </Formik>
                </Box>
              </Grid>
            </Grid>
          </Container>
          {/* <SnackBar open={false} snackbarTitle={''} close={() => {}} />  */}
          <SnackBar open={snackbar} snackbarTitle={snackbarTitle} close={() => setSnackbar(false)} />
        </Box>

        :
        <Register details={details} userDetails={userDetails}/>
      }
    </>

  );
};

const useStyles = makeStyles((theme) => ({
  image: {
    height: 'auto',
    width: '100%',
    marginTop: '155px',
    '@media (max-width: 600px)': {
      marginTop: '20px'
    }
  },


  container: {
    maxWidth: '1440px',
    margin: 'auto',
  },
  lineHeight1: {
    marginTop: '70px',
    marginBottom: '20px',
    fontSize: '22px',
    color: '#101828',
  },
  about: {
    color: '#000000',
    fontSize: '0.95rem',
    fontWeight: '450',
    textAlign: 'center',
  },


  contentalignment: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '2.5rem 0rem 4rem 0rem',
    flexWrap: 'wrap',
  },
  create: {
    color: '#D52F37',
    fontSize: '13px',
    cursor: 'pointer',
    fontWeight: '450',
    margin: '0px 10px 0px 0px',
    textDecoration: 'none',
  },

  cardscontainer: {
    height: 'auto',
    backgroundColor: '#E7F7EF',
    padding: '4rem 6.5rem',
    '@media (max-width: 640px)': {
      padding: '2rem 1.5rem',
    },
  },
  headingHands: {
    color: '#FFFFFF',
    fontSize: '1.2rem',
    marginLeft: '14px',
  },
  hands: {
    maxWidth: '84%',
    color: '#FFFFFF',
    fontSize: '1rem',
    fontWeight: 200,
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
    },
  },
  form: {
    paddingRight: '0px',
    paddingLeft: '120px',
    paddingTop: '64px',
    paddingBottom: '64px',


    '@media (max-width: 1199px)': {
      padding: '2rem 4rem 4rem 5rem',
    },
    [theme.breakpoints.down('md')]: {
      padding: '2rem 5rem 4rem 5rem',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '2rem 2rem 0rem 2rem',
    },
    '@media (max-width: 400px)': {
      padding: '2rem 1rem 3rem 1rem',
    },
  },
  content: {
    fontSize: '1.1rem',
    color: '#667085',
    marginBottom: '2rem',
  },
  started: {
    backgroundColor: '#EC7930',
    borderRadius: '100px',
    color: '#fff',
    padding: '8px',
    fontWeight: '400',
    textTransform: 'capitalize ! important',
    marginTop: '1rem',
    '&:hover': {
      backgroundColor: '#EC7930',
      boxShadow: '0 0 1px 6px rgba(236, 121, 48, 40%),0 0 1px 0px rgba(236,121,48, 20%)',
    },
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },



  mainContainer: {
    padding: "30px 0",
    background: "#F0F0F0",
    height: '90vh',
    marginTop: "4rem",
    '@media (max-width: 767px)': {
      padding: "20px 0",
    },
  },
  boxContainer: {
    backgroundImage: `url(${BackgroundImg})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    // height: '100vh',
    width: "500px",
    minHeight: "532px",
    marginTop: "12px",
    borderRadius: "20px",
    // animationName: "changeBackground",
    // animationDuration: "12s",
    // animationIterationCount: "infinite",
    // display: 'flex',
    // flexDirection: 'column',
    // justifyContent: 'center',
    '@media (max-width: 767px)': {
      height: '240px',
      marginTop: '70px'
    },

  },
  formcontainer: {
    background: "#ffffff",
    padding: "36px",
    borderRadius: "20px",
    minHeight: "532px",
    marginTop: "4px",

  },
  login: {
    marginBottom: theme.spacing(2),
    marginTop: "10px",
  },
  createButton: {
    borderRadius: '5px',
    marginTop: '20px',
    background: "#D52F37",
    fontSize: "12px",
  },
  email: {
    padding: '2px',
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: "400"
  },
  signup: {
    color: "#D52F37"
  },
  text_one: {
    fontSize: "12px",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    // marginTop: "10px",
  },
  carouselContainer: {
    backgroundColor: 'transparent',
    maxWidth: '100%',
    margin: 'auto',
  },
  whiteBorder: {
    background: "whitesmoke",
    marginTop: '41px',
    height: "1px",
    padding: '1px',
    borderRadius: '5px',
    marginLeft: '15px'
  }
}));
