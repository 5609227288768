import client from '../apiClient';

const getScreensForCourse = (courseId, actionType) =>
  client.get(`/user/iq/course?courseId=${courseId}&&actionType=${actionType}`);

const getScreensForSubmodule = (subModuleId, actionType) =>
  client.get(`/user/iq/submodule?courseSubModuleId=${subModuleId}&&actionType=${actionType}&&Is_free=${true}`);

const startIQ = (payLoad) => client.post(`/user/iq/test/start`, JSON.stringify(payLoad));

const postCourseAnswer = (payLoad) => client.post(`/user/iq/result/course`, JSON.stringify(payLoad));

const postSubModuleAnswer = (payLoad) => client.post(`/user/iq/result/submodule`, JSON.stringify(payLoad));

const getIqAnalytics = () => client.get(`/user/iq/first/assessment`);

const getIqAndCourseProgress = (id) => client.get(`/user/course/iq/progress?courseId=${id}`);

export default {
  getScreensForCourse,
  getScreensForSubmodule,
  startIQ,
  postCourseAnswer,
  postSubModuleAnswer,
  getIqAnalytics,
  getIqAndCourseProgress,
};
